export const validateEmail = (email: string): string | boolean => {
  const atIndex = email.indexOf("@");
  const domainDotIndex = email.indexOf(".", atIndex);
  const lastChar = email[email.length - 1];
  if (
    atIndex > -1 &&
    domainDotIndex > -1 &&
    !email.includes("@.") &&
    !email.includes(".@") &&
    lastChar !== "."
  ) {
    return email;
  }
  return false;
};
