import React, { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import { PollMultiFreeTextInput } from "../../../Shared/FreeTextInput/PollMultiFreeTextInput";
import { useBestWorstInitQuestion } from "../Hooks/useBestWorstInitQuestion";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { Flex } from "@chakra-ui/react";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { performHapticImpact } from "../../../../Api/Utils";

export const ParticipantBestWorstStepOne = () => {
  const { setPollInstanceResultToServer, currentTeamLessonUserInstanceId } =
    useContext<IGlobalProvider>(GlobalContext);

  const [currentTextAnswer, setCurrentTextAnswer] = useState<
    string | undefined
  >(undefined);
  const [textAnswersToSubmit, setTextAnswersToSubmit] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [finish, setFinish] = useState<boolean>(false);
  const [bestIsDone, setBestIsDone] = useState<boolean>(false);

  const { bestPollQuestion, worstPollQuestion } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_2_PQ",
    worstPollQuestionInternalTitle: "CH_3_PQ",
  });

  const {
    currentUserPollQuestionResults: bestResults,
    currentPollInstanceId: bestPollInstanceId,
  } = usePollQuestionInformation({
    pollQuestion: bestPollQuestion,
    byGroup: false,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });
  const {
    currentUserPollQuestionResults: worstResults,
    currentPollInstanceId: worstPollInstanceId,
  } = usePollQuestionInformation({
    pollQuestion: worstPollQuestion,
    byGroup: false,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  useEffect(() => {
    performHapticImpact();
  }, []);

  useEffect(() => {
    if (bestResults.length > 0 && !bestIsDone) {
      setTextAnswersToSubmit([]);
      setCurrentTextAnswer("");
      setBestIsDone(true);
    }
  }, [bestResults]);

  useEffect(() => {
    if (bestIsDone && loading) {
      setLoading(false);
    }
  }, [bestResults, bestIsDone]);

  useEffect(() => {
    if (bestResults.length > 0 && worstResults.length > 0) {
      setFinish(true);
    }
  }, [bestResults, worstResults]);

  if (!worstPollInstanceId && !bestPollInstanceId) {
    return <></>;
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={false} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {worstResults.length < 1 ? (
        <Flex alignSelf={"start"}>
          <BlockSectionTitle
            title={`${HVLocalizeStrings.STEP} ${
              bestResults.length < 1 ? 1 : 2
            } / 2`}
            primaryTextColor={Black}
            borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          />
        </Flex>
      ) : (
        <></>
      )}
      {bestResults.length < 1 || worstResults.length < 1 || !finish ? (
        <>
          {bestPollQuestion && worstPollQuestion && (
            <PollMultiFreeTextInput
              disableAddAnother={textAnswersToSubmit.length >= 3}
              disableIamDone={textAnswersToSubmit.length < 1}
              questionText={
                (bestResults.length < 1
                  ? HVSportLocalizeStrings.CHARTER_BEST_QUESTION
                  : HVSportLocalizeStrings.CHARTER_WORST_QUESTION) as any
              }
              doneButtonMessage={
                bestResults.length < 1
                  ? HVLocalizeStrings.CONTINUE
                  : HVLocalizeStrings.DONE
              }
              currentTextAnswer={currentTextAnswer as any}
              setCurrentTextAnswer={setCurrentTextAnswer}
              textAnswersToSubmit={textAnswersToSubmit}
              setTextAnswersToSubmit={setTextAnswersToSubmit}
              loading={loading}
              iamDoneCallBack={() => {
                let pollInstanceId = -1;
                let allowSetFinish = false;
                if (bestResults.length < 1 && bestPollInstanceId) {
                  pollInstanceId = bestPollInstanceId;
                } else if (worstResults.length < 1 && worstPollInstanceId) {
                  pollInstanceId = worstPollInstanceId;
                  allowSetFinish = true;
                } else {
                  return;
                }
                console.log(worstResults.length);
                setLoading(true);
                setPollInstanceResultToServer(pollInstanceId, {
                  questionAlternativeIds: [],
                  feedback: "",
                  questionUserResponseIds: [],
                  freeTexts: textAnswersToSubmit,
                }).then(() => {
                  if (allowSetFinish) {
                    setFinish(true);
                  }
                });
              }}
            />
          )}{" "}
        </>
      ) : (
        <ParticipantWaitingScreen />
      )}
    </ParticipantContainer>
  );
};
