import { Box, Flex, IconButton, Stack, Text } from "@chakra-ui/react";
import {
  Black,
  DESTRUCTIVE_TEXT_COLOR,
  Evening_Blue,
  Light_Blue,
  Mid_Blue,
} from "../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../Common/HvTextInput";
import {
  PollResponseList,
  PollResponseListItem,
} from "../../Team/Polls/PollResponseList";
import { MdCancel } from "react-icons/md";
import { Loading } from "../../Common/Loading";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";

interface PollMultiFreeTextInputProps {
  currentTextAnswer: string;
  setCurrentTextAnswer: (value: string) => void;
  textAnswersToSubmit: string[];
  setTextAnswersToSubmit: (value: string[]) => void;
  loading: boolean;
  iamDoneCallBack: () => void;
  disableIamDone: boolean;
  disableAddAnother: boolean;
  instruction?: string;
  questionText?: string;
  doneButtonMessage?: string;
  maxLength?: number;
  questionTextAlignment?: string;
  questionTextFontWeight?: string;
  questionTextColor?: string;
  submitButtonColorSet?: AnimatedButtonColorSet;
  width?: string;
}
export const PollMultiFreeTextInput = (props: PollMultiFreeTextInputProps) => {
  const {
    currentTextAnswer,
    setCurrentTextAnswer,
    textAnswersToSubmit,
    questionText,
    setTextAnswersToSubmit,
    loading,
    disableAddAnother,
    disableIamDone,
    instruction,
    iamDoneCallBack,
    doneButtonMessage,
    maxLength,
    questionTextAlignment,
    questionTextFontWeight,
    questionTextColor,
    submitButtonColorSet,
    width,
  } = props;

  return (
    <>
      <Text
        alignSelf={questionTextAlignment ?? "flex-start"}
        color={questionTextColor ?? Black}
        dangerouslySetInnerHTML={{
          __html: questionText as string,
        }}
        fontWeight={questionTextFontWeight}
      />
      {instruction && (
        <Text alignSelf={"flex-start"} color={Black} fontSize={"sm"}>
          {instruction}
        </Text>
      )}
      <HvTextInput
        w={width ?? "100%"}
        disabled={disableAddAnother}
        bg={"white"}
        color={Black}
        value={currentTextAnswer}
        placeholder={HVLocalizeStrings.ENTER_YOUR_RESPONSE_ONE_AT_A_TIME}
        onEnterKeyDown={() => {
          if (currentTextAnswer) {
            textAnswersToSubmit.push(currentTextAnswer);
            setTextAnswersToSubmit([...textAnswersToSubmit]);
            setCurrentTextAnswer("");
          }
        }}
        onChange={(e) => {
          setCurrentTextAnswer(e.currentTarget.value);
        }}
        maxLength={maxLength}
      />
      <Box h={"auto"} w={"100%"}>
        {textAnswersToSubmit.length > 0 ? (
          <PollResponseList>
            {textAnswersToSubmit.map((answer, index) => (
              <PollResponseListItem key={`${answer}-${index}`}>
                <Text color={Black} w={"100%"} fontSize={"16px"}>
                  {answer}
                </Text>
                <IconButton
                  disabled={loading}
                  aria-label="Delete poll response"
                  bg="none"
                  sx={{ _hover: {} }}
                  icon={<MdCancel />}
                  color={DESTRUCTIVE_TEXT_COLOR}
                  onClick={() => {
                    textAnswersToSubmit.splice(index, 1);
                    setTextAnswersToSubmit([...textAnswersToSubmit]);
                  }}
                />
              </PollResponseListItem>
            ))}
          </PollResponseList>
        ) : (
          <Flex
            minH={"150px"}
            flexDir={"column"}
            align={"center"}
            justify={"center"}
            gap={"5"}
          >
            <Loading
              colorOne={Evening_Blue}
              colorTwo={Mid_Blue}
              colorThree={Light_Blue}
            />
          </Flex>
        )}
      </Box>
      <Stack spacing={2}>
        {" "}
        <AnimatedButton
          disabled={
            !currentTextAnswer ||
            currentTextAnswer.trim().length === 0 ||
            disableAddAnother ||
            loading
          }
          w={300}
          text={HVLocalizeStrings.POLL_MULTI_INPUT_SUBMIT_AND_ADD_ANOTHER}
          onClick={() => {
            if (currentTextAnswer) {
              textAnswersToSubmit.push(currentTextAnswer);
              setTextAnswersToSubmit([...textAnswersToSubmit]);
              setCurrentTextAnswer("");
            }
          }}
          colorSet={submitButtonColorSet ?? AnimatedButtonColorSet.Primary}
        />
        <AnimatedButton
          disabled={disableIamDone || loading}
          w={300}
          text={doneButtonMessage ?? HVLocalizeStrings.DONE}
          onClick={() => {
            iamDoneCallBack();
          }}
          colorSet={submitButtonColorSet ?? AnimatedButtonColorSet.Third}
        />
      </Stack>
    </>
  );
};
