import { ModalOverlay, Spinner } from "@chakra-ui/react";
import { HV_SPINNER_COLOR } from "../../Styles/HeadversityStyle";

interface HvSpinnerProps {
  pos?: any;
  withOverlay?: boolean;
}

export const HvSpinner = ({ pos, withOverlay }: HvSpinnerProps) => {
  return (
    <>
      {withOverlay && <ModalOverlay />}
      <Spinner position={pos} color={HV_SPINNER_COLOR} />
    </>
  );
};
