import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { GlobalContext } from "../../State/GlobalContext";
import {
  BG_TEAM_BASE,
  CERT_HOME_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HeaderWelcomeText } from "../Common/HeaderWelcomeText";
import { SportHome } from "./SportHome";
import {
  SPORT_ONBOARDING,
  SportOnboarding,
} from "./Onboarding/SportOnboarding";
import { useSportTeam } from "../../Hooks/Team/useSportTeam";

export const SportPage = () => {
  const { self, sportEnabled, currentTeam } = useContext(GlobalContext);
  const { isLeader } = useSportTeam();

  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    if (self && sportEnabled && currentTeam) {
      // leaders are shown onboarding if the team is not initialized
      // players if they do not have a key in localstorage
      const showOnboarding =
        (isLeader && !currentTeam.isInitialized) ||
        (!isLeader && !localStorage.getItem(SPORT_ONBOARDING));
      setShowOnboarding(showOnboarding);
    }
  }, [self, currentTeam, sportEnabled, isLeader]);

  useEffect(() => {
    if (self && sportEnabled && currentTeam) {
      // determine if the user has completed the onboarding process
      const alreadyShown = localStorage.getItem(SPORT_ONBOARDING) !== null;
      setShowOnboarding(!alreadyShown || !currentTeam.isInitialized);
    }
  }, [self, currentTeam, sportEnabled]);

  return (
    <>
      <Helmet>
        <title>{`Respect in Sport+ - ${HVLocalizeStrings.HEADVERSITY}`}</title>
      </Helmet>
      <HeaderSetter
        title={<HeaderWelcomeText />}
        description={HVLocalizeStrings.SPORT_TAGLINE}
        backgroundImage={CERT_HOME_BACKGROUND}
        backgroundImageBase={BG_TEAM_BASE}
        showLogo={true}
      />
      <FadeAfterDelay>
        <SportHome />
      </FadeAfterDelay>

      {/* // sport entry flow */}
      {showOnboarding && (
        <SportOnboarding
          open={showOnboarding}
          setOpen={(open: boolean) => {
            setShowOnboarding(open);
          }}
        />
      )}
    </>
  );
};
