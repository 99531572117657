import { Flex } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { useContext, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { CharterDisplay } from "../CharterEditor/CharterDisplay";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { PresentationContent } from "../Shared/PresentationContent";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { CharterActivity } from "@headversity/contract";

interface PresentationCharterEditorProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}

export const PresentationCharterEditor = (
  props: PresentationCharterEditorProps
) => {
  const { setActivity } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const {
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const handleDoneCharterCustomize = () => {
    if (currentTeamLessonUserInstanceId) {
      setLoading(true);
      setTeamLessonInProgressStepToServer(currentTeamLessonUserInstanceId, "");
      setActivity(undefined);
    }
  };

  return (
    <PresentationContainer containerPt={"0"}>
      <PresentationContent
        title={HVSportLocalizeStrings.CHARTER_EDIT_TITLE}
        description={HVSportLocalizeStrings.CHARTER_EDIT_DESCRIPTION}
        hr={false}
        pt={5}
        w={"950px"}
        descriptionMb={"0"}
        descriptionMt={"0"}
        titleMl={"5"}
      >
        <Flex w="100%" alignItems={"center"} justifyContent={"center"} pt={"5"}>
          {currentTeamLessonUserInstanceId && (
            <CharterDisplay
              isEditor={true}
              teamLessonUserInstanceId={currentTeamLessonUserInstanceId}
              loading={loading}
              setLoading={setLoading}
              willBroadcast={true}
            />
          )}
        </Flex>
      </PresentationContent>
      <AnimatedButton
        text={HVLocalizeStrings.DONE}
        colorSet={AnimatedButtonColorSet.Primary}
        w={219}
        onClick={() => {
          handleDoneCharterCustomize();
        }}
        disabled={loading}
      ></AnimatedButton>
    </PresentationContainer>
  );
};
