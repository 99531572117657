import { useContext, useEffect, useState } from "react";
import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { LargeModal } from "../Common/LargeModal";

import {
  MODAL_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
} from "../../Styles/HeadversityStyle";
import { FiDownload, FiMoreHorizontal } from "react-icons/fi";
import { GrLinkedin } from "react-icons/gr";
import { SiYammer } from "react-icons/si";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { savePromoMaterialDownloaded } from "../../Api/Reach/ReachApi";
import { getKey } from "../../Utils/Helpers";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  getAssetByLanguage,
  getAssetLanguages,
  getAssetTypeIcon,
  getAssetTypeName,
  getFileName,
} from "../../Utils/ReachUtils";
import { downloadFile, getBlobUrlAndDownload } from "../../Api/Utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { REACH_EVENTS, track } from "../../Utils/Analytics";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { AiOutlineCopy } from "react-icons/ai";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { isWebPlatform, isAndroid } from "../../Utils/mobileUtils";
import {
  LanguageSetting,
  PromotionalMaterialAssetDto,
  PromotionalMaterialDto,
  ReachAssetType,
} from "@headversity/contract";
import { LanguageSelector } from "../Common/LanguageSelector";
import { HVTestId } from "../../Testing/dataTestIds";

interface ReachPromoModalProps {
  promo: PromotionalMaterialDto;
  open: boolean;
  setOpen: (open: boolean) => void;
  assets: PromotionalMaterialAssetDto[];
}

interface SortedPromoMaterialAsset extends PromotionalMaterialAssetDto {
  languages: string;
}

const LINKEDIN_BASE_URL =
  "https://www.linkedin.com/sharing/share-offsite/?url=";
const YAMMER_BASE_URL = "https://yammer.com/messages/new?status=";

export const ReachPromoMaterialModal = (props: ReachPromoModalProps) => {
  const {
    selectedUserLanguage,
    promoMaterials,
    setSelectedPromoMaterial,
    getPromoMaterialAssetsFromServer,
    setPromoMaterialAssets,
    promoMaterialModalChangeUrl,
    isPromoMaterialNotFound,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { promo, open, setOpen, assets } = props;
  const [sortedAssets, setSortedAssets] = useState<SortedPromoMaterialAsset[]>(
    []
  );
  const [selectedAssetLanguage, setSelectedAssetLanguage] =
    useState<string>(selectedUserLanguage);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    if (selectedUserLanguage) {
      setSelectedAssetLanguage(selectedUserLanguage);
    }
  }, [selectedUserLanguage]);

  useEffect(() => {
    if (open && promoMaterialModalChangeUrl) {
      const url = new URL(window.location.href);
      if (
        url.searchParams.has("promo") &&
        url.searchParams.get("promo") === promo?.id.toString()
      ) {
        return;
      }
      navigate(window.location.pathname + `?promo=${promo?.id}`, {
        replace: true,
      });
    }
  }, [promo, open]);

  useEffect(() => {
    if (
      open ||
      !searchParams.get("promo") ||
      !promoMaterials ||
      promoMaterials.length === 0
    ) {
      return;
    }

    const promo = promoMaterials.find(
      (item) => item.id.toString() === searchParams.get("promo")
    );

    if (promo) {
      setSelectedPromoMaterial(promo);
      getPromoMaterialAssetsFromServer(promo.id);
    }
  }, [searchParams, promoMaterials]);

  useEffect(() => {
    if (isPromoMaterialNotFound !== false) {
      return;
    }

    const promo = promoMaterials.find(
      (item) => item.id.toString() === searchParams.get("promo")
    );

    if (promo) {
      setOpen(true);
    }
  }, [isPromoMaterialNotFound]);

  useEffect(() => {
    if (assets.length > 0) {
      const sortedAssets: SortedPromoMaterialAsset[] = [];
      assets.forEach((asset) => {
        const languages = getAssetLanguages(
          asset.languageSetting as LanguageSetting
        );
        sortedAssets.push({ ...asset, languages });
      });
      sortedAssets.sort((a, b) => a.languages.localeCompare(b.languages));
      setSortedAssets(sortedAssets);
    }
  }, [assets]);

  const handleSavePromoMaterialDownloaded = (
    pmAsset: PromotionalMaterialAssetDto
  ) => {
    trackOnCopyShareOrDownload(pmAsset, REACH_EVENTS.DownloadedAsset);
    savePromoMaterialDownloaded(getKey(), pmAsset.id);
  };

  const handleGenerateEmlFile = (pmAsset: PromotionalMaterialAssetDto) => {
    const emlTemplate = `
Subject: ${pmAsset.subject}
X-Unsent: 1
Content-Type: text/html; charset="UTF-8"

<html>
<body>
${pmAsset.content}
</body>
</html>`.trim();
    getBlobUrlAndDownload(emlTemplate, "message.eml");
    handleSavePromoMaterialDownloaded(pmAsset);
  };

  const handleDownloadMedia = (pmAsset: PromotionalMaterialAssetDto) => {
    const fileName = getFileName("media", pmAsset.media);
    if (isWebPlatform()) {
      downloadFile(pmAsset.media, fileName);
      handleSavePromoMaterialDownloaded(pmAsset);
    } else {
      let target: string;
      if (isAndroid() && pmAsset.media.includes(".pdf")) {
        target = "_system";
      } else {
        target = "_self";
      }
      const browser = InAppBrowser.create(pmAsset.media, target, {});
      browser.show();
    }
  };

  const trackOnCopyShareOrDownload = (
    pmAsset: PromotionalMaterialAssetDto,
    event: REACH_EVENTS,
    socialMedia = ""
  ) => {
    track(event, {
      HV_Reach_PromoMaterialId: promo.id,
      HV_Reach_PromoMaterialTitle: promo.name,
      HV_Reach_AssetId: pmAsset.id,
      HV_Reach_AssetType: promo.assetType,
      HV_Reach_AssetLanguages: pmAsset.languageSetting,
      HV_Reach_SocialMedia: socialMedia,
    });
  };

  const handleCopyToClipboard = () => {
    const asset = getAssetByLanguage(assets, selectedAssetLanguage);
    navigator.clipboard.writeText(asset.content).then(() => {
      trackOnCopyShareOrDownload(asset, REACH_EVENTS.CopyToClipboard);
    });
  };

  const getIframeSrc = () => {
    const content = getAssetByLanguage(assets, selectedAssetLanguage).content;
    const bodyContent = `<div style='overflow-x:auto; background-color:white; border-radius: 5px; padding: 5px 15px; border: 1px solid lightgray'>${content}</div>`;
    return `<html><body>${bodyContent}</body></html>`;
  };

  const HVReachIframe = styled.iframe`
    height: 500px;
  `;

  const hasMedia = sortedAssets.some((a) => a.media);

  return (
    <LargeModal
      title={promo && (promo.name as string)}
      open={open}
      setOpen={(val: boolean) => {
        if (!val) {
          if (promoMaterialModalChangeUrl) {
            navigate(window.location.pathname, { replace: true });
          }
        }
        setOpen(val);
        return {};
      }}
      onCloseTasks={() => {
        setSelectedPromoMaterial(null);
        setPromoMaterialAssets([]);
        setSelectedAssetLanguage(selectedUserLanguage);
      }}
      dataTestId={HVTestId.ReachPromoMaterialModal.modal}
    >
      <Box px={1} color={MODAL_PRIMARY_TEXT_COLOR}>
        <Flex mb={6} justify={"left"} alignItems={"center"}>
          <Text mr={3} data-testid={HVTestId.ReachPromoMaterialModal.assetType}>
            {promo && assets.length > 0
              ? getAssetTypeName(promo.assetType)
              : HVLocalizeStrings.REACH_NO_ASSETS}
          </Text>

          <Box fontSize={18}>
            {promo && assets.length > 0 ? (
              getAssetTypeIcon(promo.assetType)
            ) : (
              <FiMoreHorizontal />
            )}
          </Box>
          {promo?.name_loc && (
            <Box ml="15px" minW="110px">
              <LanguageSelector
                onLanguageChanged={(selectedLanguage: string) => {
                  setSelectedAssetLanguage(selectedLanguage);
                }}
                languageList={
                  promo?.languageSetting
                    ? Object.keys(promo.languageSetting).filter(
                        (key) =>
                          (promo.languageSetting as { [key: string]: any })[
                            key
                          ] === true
                      )
                    : undefined
                }
              />
            </Box>
          )}
        </Flex>
        {promo && assets.length > 0 && (
          <>
            {promo.description_loc && (
              <VStack spacing={5} mb={5}>
                <Text textAlign={"left"} mr={"auto"}>
                  {
                    (promo.description_loc as { [key: string]: string })[
                      selectedAssetLanguage
                    ]
                  }
                </Text>
              </VStack>
            )}
            {promo.assetType === ReachAssetType.Email ? (
              <>
                <Flex gap={10} direction="column" pb={8}>
                  <Flex alignItems={"end"} gap={3}>
                    <Text fontSize={18} fontWeight={"bold"}>
                      {HVLocalizeStrings.REACH_SUBJECT_LINE}
                    </Text>
                    <Text>
                      {
                        getAssetByLanguage(assets, selectedAssetLanguage)
                          .subject
                      }
                    </Text>
                  </Flex>
                  <HVReachIframe srcDoc={getIframeSrc()} />
                </Flex>
              </>
            ) : (
              <Box>
                <>
                  {promo.assetType === ReachAssetType.SocialMedia && (
                    <VStack spacing={5} mb={5}>
                      <Text
                        textAlign={"left"}
                        mr={"auto"}
                        whiteSpace={"break-spaces"}
                        data-testid={
                          HVTestId.ReachPromoMaterialModal.description
                        }
                      >
                        {
                          getAssetByLanguage(assets, selectedAssetLanguage)
                            .content
                        }
                      </Text>
                      <Box
                        alignSelf={!isMobile ? "flex-start" : undefined}
                        pb={2}
                      >
                        <AnimatedButton
                          text={
                            <Flex gap={2}>
                              <AiOutlineCopy />
                              {HVLocalizeStrings.REACH_COPY_TEXT}
                            </Flex>
                          }
                          onClick={handleCopyToClipboard}
                          colorSet={AnimatedButtonColorSet.Third}
                          dataTestId={
                            HVTestId.ReachPromoMaterialModal.copyButton
                          }
                        />
                      </Box>
                    </VStack>
                  )}
                  <Box>
                    <Image
                      src={
                        (promo.imageUrl_loc as { [key: string]: string })[
                          selectedAssetLanguage
                        ]
                      }
                      alt={
                        (promo.name_loc as { [key: string]: string })[
                          selectedAssetLanguage
                        ]
                      }
                      maxH={"200px"}
                    />
                  </Box>
                </>
              </Box>
            )}

            {promo.assetType === ReachAssetType.SocialMedia && hasMedia && (
              <>
                <Divider pt={"50px"} />
                <Center pt={"50px"}>
                  <Flex justifyContent={"center"} maxW={"500px"}>
                    <Text
                      textAlign={"center"}
                      mr={"auto"}
                      whiteSpace={"break-spaces"}
                    >
                      {HVLocalizeStrings.REACH_SOCIAL_MEDIA_INSTRUCTION}
                    </Text>
                  </Flex>
                </Center>
              </>
            )}

            {/* Footer buttons */}
            {promo.assetType !== ReachAssetType.Email && hasMedia && (
              <Stack
                justify={"center"}
                pt={"45px"}
                spacing={4}
                direction={isMobile ? "column" : "row"}
                align={"center"}
              >
                <ReachModalFooterButton
                  icon={<GrLinkedin />}
                  buttonText={HVLocalizeStrings.REACH_SHARE_ON_LINKEDIN}
                  dataTestId={HVTestId.ReachPromoMaterialModal.linkedIn}
                >
                  {sortedAssets.map((pmAsset) => {
                    if (pmAsset.media) {
                      return (
                        <ReachSocialMediaMenuItem
                          key={pmAsset.id}
                          pmAsset={pmAsset}
                          socialMedia={"LinkedIn"}
                          trackOnCopyShareOrDownload={
                            trackOnCopyShareOrDownload
                          }
                        />
                      );
                    }
                    return <> </>;
                  })}
                </ReachModalFooterButton>
                <ReachModalFooterButton
                  icon={<SiYammer />}
                  buttonText={HVLocalizeStrings.REACH_SHARE_ON_YAMMER}
                  dataTestId={HVTestId.ReachPromoMaterialModal.yammer}
                >
                  {sortedAssets.map((pmAsset) => {
                    if (pmAsset.media) {
                      return (
                        <ReachSocialMediaMenuItem
                          key={pmAsset.id}
                          pmAsset={pmAsset}
                          socialMedia={"Yammer"}
                          trackOnCopyShareOrDownload={
                            trackOnCopyShareOrDownload
                          }
                        />
                      );
                    }
                    return <> </>;
                  })}
                </ReachModalFooterButton>
                <ReachModalFooterButton
                  icon={<FiDownload />}
                  buttonText={HVLocalizeStrings.REACH_DOWNLOAD}
                  dataTestId={HVTestId.ReachPromoMaterialModal.download}
                >
                  {sortedAssets.map((pmAsset) => {
                    if (pmAsset.media) {
                      return (
                        <MenuItem
                          key={pmAsset.id}
                          onClick={() => {
                            handleDownloadMedia(pmAsset);
                          }}
                        >
                          <Link _hover={{ textDecor: "none" }}>
                            {`${HVLocalizeStrings.REACH_DOWNLOAD} (${pmAsset.languages})`}
                          </Link>
                        </MenuItem>
                      );
                    }
                    return <> </>;
                  })}
                </ReachModalFooterButton>
              </Stack>
            )}

            {promo.assetType === ReachAssetType.Email && (
              <Flex justifyContent={"center"}>
                <ReachModalFooterButton
                  icon={<FiDownload />}
                  buttonText={HVLocalizeStrings.REACH_DOWNLOAD}
                  dataTestId={HVTestId.ReachPromoMaterialModal.download}
                >
                  {sortedAssets.map((pmAsset) => {
                    return (
                      <MenuItem
                        key={pmAsset.id}
                        onClick={() => {
                          handleGenerateEmlFile(pmAsset);
                        }}
                      >
                        {`${HVLocalizeStrings.REACH_DOWNLOAD} (${pmAsset.languages})`}
                      </MenuItem>
                    );
                  })}
                </ReachModalFooterButton>
              </Flex>
            )}
          </>
        )}
      </Box>
    </LargeModal>
  );
};

interface ReachModalFooterButtonProps {
  icon: JSX.Element;
  buttonText: string;
  children: JSX.Element[];
  dataTestId?: string;
}

const ReachModalFooterButton = (props: ReachModalFooterButtonProps) => {
  const { icon, buttonText, children, dataTestId } = props;
  return (
    <Menu>
      <MenuButton
        w={220}
        h={40}
        as={AnimatedButton}
        onClick={() => {}}
        text={
          <Flex
            alignItems={"center"}
            justifyContent="center"
            fontWeight={"normal"}
          >
            <>
              {icon}
              <Text ml={2}>{buttonText}</Text>
            </>
          </Flex>
        }
        colorSet={AnimatedButtonColorSet.Third}
        data-testid={dataTestId}
      />
      <MenuList fontSize={14} color={PRIMARY_TEXT_COLOR} zIndex={2}>
        {children}
      </MenuList>
    </Menu>
  );
};

interface ReachSocialMediaMenuItemProps {
  pmAsset: PromotionalMaterialAssetDto;
  socialMedia: string;
  trackOnCopyShareOrDownload: (
    pmAsset: PromotionalMaterialAssetDto,
    event: REACH_EVENTS,
    socialMedia: string
  ) => void;
}

const ReachSocialMediaMenuItem = (props: ReachSocialMediaMenuItemProps) => {
  const { pmAsset, socialMedia, trackOnCopyShareOrDownload } = props;
  let linkUrl = "";
  let linkText = "";
  switch (socialMedia) {
    case "LinkedIn":
      linkUrl = LINKEDIN_BASE_URL;
      linkText = HVLocalizeStrings.REACH_SHARE_ON_LINKEDIN;
      break;
    case "Yammer":
      linkUrl = YAMMER_BASE_URL;
      linkText = HVLocalizeStrings.REACH_SHARE_ON_YAMMER;
      break;
    default:
      break;
  }

  return (
    <MenuItem>
      <Link
        _hover={{ textDecor: "none" }}
        href={`${linkUrl}${pmAsset.media}`}
        isExternal
        onClick={() => {
          trackOnCopyShareOrDownload(
            pmAsset,
            REACH_EVENTS.SharedAsset,
            socialMedia
          );
        }}
      >
        {`${linkText} (${getAssetLanguages(
          pmAsset.languageSetting as LanguageSetting
        )})`}
      </Link>
    </MenuItem>
  );
};
