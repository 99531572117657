import {
  Box,
  ListItem,
  SimpleGrid,
  Text,
  UnorderedList,
  useBreakpointValue,
} from "@chakra-ui/react";
import { NanoPracticeDto } from "@headversity/contract";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HVSportLocalizeStrings } from "../../Localization/HVSportLocalizeString";
import { GlobalContext } from "../../State/GlobalContext";
import {
  BG_TEAM_BASE,
  Border_Radius,
  CERT_HOME_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { HVTestId } from "../../Testing/dataTestIds";
import { BlockSection } from "../Common/BlockSection";
import BlockSectionTitle from "../Common/BlockSectionTitle";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HeaderWelcomeText } from "../Common/HeaderWelcomeText";
import { LargeModal } from "../Common/LargeModal";
import { NanoPracticesScrollPanel } from "../Solo/Shared/NanoPracticesScrollPanel";

export const PRACTICE_IDS_TO_SHOW = [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 48];

export const SportResources = () => {
  const { nanoPractices } = useContext(GlobalContext);

  const [nanoPracticesToShow, setNanoPracticesToShow] = useState<
    NanoPracticeDto[]
  >([]);

  const [isWellBetterHowOpen, setIsWellBetterHowOpen] = useState(false);

  const isDesktop = useBreakpointValue({ base: false, md: true });

  useEffect(() => {
    const practicesToShow = nanoPractices.filter((practice) =>
      PRACTICE_IDS_TO_SHOW.includes(practice.id)
    );
    setNanoPracticesToShow(practicesToShow);
  }, [nanoPractices]);

  return (
    <>
      <Helmet>
        <title>
          {`${HVSportLocalizeStrings.CHARTER_SPORT_RESOURCES_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`}
        </title>
      </Helmet>
      <HeaderSetter
        title={<HeaderWelcomeText />}
        description={HVLocalizeStrings.SPORT_TAGLINE}
        backgroundImage={CERT_HOME_BACKGROUND}
        backgroundImageBase={BG_TEAM_BASE}
        showLogo={true}
      />
      <FadeAfterDelay>
        <BlockSection
          fadeIn={true}
          includeSpacing={true}
          title={
            <BlockSectionTitle
              title={HVSportLocalizeStrings.CHARTER_TEAM_RESOURCES}
            />
          }
          columns={1}
          rows={1}
          gap={"0"}
          gridClassName={"skills-container-20"}
          dataTestId={HVTestId.ResilienceSkill20Home.container}
        >
          <Box
            bg="white"
            border="solid 1px #ccc"
            p={4}
            borderRadius={Border_Radius}
            maxW={{ base: undefined, lg: "372px" }}
            boxShadow="md"
          >
            <UnorderedList gap="5px">
              <ListItem>
                <a
                  href="https://cdn.headversity.com/app/sport/moderator-guide.png"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>{HVSportLocalizeStrings.MODERATOR_GUIDE}</u>
                </a>
              </ListItem>
              <ListItem>
                <Link
                  to="#"
                  onClick={() => {
                    setIsWellBetterHowOpen(true);
                  }}
                >
                  <u>{HVSportLocalizeStrings.CHARTER_RESOURCE_ONE}</u>
                </Link>
              </ListItem>
            </UnorderedList>
          </Box>
        </BlockSection>
        <BlockSection
          fadeIn={true}
          includeSpacing={true}
          title={
            <BlockSectionTitle title={HVLocalizeStrings.MORE_ACTIVITIES} />
          }
          columns={1}
          rows={1}
          gap={"0"}
          gridClassName={"skills-container-20"}
          dataTestId={HVTestId.ResilienceSkill20Home.container}
        >
          <NanoPracticesScrollPanel
            isDesktop={isDesktop}
            practices={nanoPracticesToShow}
            showDescription={false}
            from={"Sport Resources"}
          />
        </BlockSection>
      </FadeAfterDelay>

      <LargeModal
        open={isWellBetterHowOpen}
        setOpen={setIsWellBetterHowOpen}
        forceLightMode={true}
        title={HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_HEADER}
      >
        <Text mt={4} fontSize="18px">
          <b>{HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_ONE_HEADER}</b>
        </Text>

        <Text>
          {HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_ONE_DESCRIPTION}
        </Text>

        <Text mt={4} fontSize="18px">
          <b>{HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_HEADER}</b>
        </Text>

        <SimpleGrid
          columns={{ base: 1, lg: 3 }}
          gap={5}
          border="solid 1px #ccc"
          borderRadius={Border_Radius}
          bgColor="#efefefaa"
          p={4}
          mt={2}
        >
          <Box>
            <Text>
              <b>
                {
                  HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_ONE
                }
              </b>
            </Text>

            <Text>
              {
                HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_ONE
              }
            </Text>
          </Box>
          <Box>
            <Text>
              <b>
                {
                  HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_TWO
                }
              </b>
            </Text>

            <Text>
              {
                HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_TWO
              }
            </Text>
          </Box>
          <Box>
            <Text>
              <b>
                {
                  HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_SUBHEADER_THREE
                }
              </b>
            </Text>

            <Text>
              {
                HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_TWO_DESCRIPTION_THREE
              }
            </Text>
          </Box>
        </SimpleGrid>

        <Text mt={4} fontSize="18px">
          <b>{HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_THREE_HEADER}</b>
        </Text>

        <Text>
          {HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_THREE_DESCRIPTION}
        </Text>

        <Text mt={4} fontSize="18px">
          <b>{HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_FOUR_HEADER}</b>
        </Text>

        <Text>
          {HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_STEP_FOUR_DESCRIPTION}
        </Text>

        <Text mt={4} fontSize="18px">
          <b>{HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_TIPS_HEADER}</b>
        </Text>

        <Text>
          {HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_TIPS_DESCRIPTION_ONE}
        </Text>

        <Text>
          {HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_DESCRIPTION_TWO}
        </Text>

        <Text>
          {HVSportLocalizeStrings.CHARTER_RESOURCE_ONE_DESCRIPTION_THREE}
        </Text>
      </LargeModal>
    </>
  );
};
