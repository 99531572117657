import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  CharterActivity,
  PollQuestionDto,
  QuestionUserResponseDto,
  ResponseState,
} from "@headversity/contract";
import { Box, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import { QuestionUserResponseEditor } from "../Shared/QuestionUserResponseEditor";
import { getLatestPollInstanceByPollQuestionId } from "../../../../Utils/TeamUtil";
import { PresentationContent } from "../Shared/PresentationContent";
import { BsPeopleFill } from "react-icons/bs";
import { IconType } from "react-icons";
import { CharterTimer } from "../Shared/CharterTimer";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { PresentationContainer } from "../Shared/PresentationContainer";
import _ from "lodash";

interface PresentationWeStatementStepOneProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const PresentationWeStatementStepOne = (
  props: PresentationWeStatementStepOneProps
) => {
  const { setActivity } = props;
  const {
    pollQuestions,
    pollInstances,
    setPollInstanceToServer,
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [currentPollQuestion, setCurrentPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const {
    total,
    pollQuestionResults,
    currentPollInstanceId,
    disableSubmit,
    setDisableSubmit,
  } = usePollQuestionInformation({
    pollQuestion: currentPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      if (pollQuestion) {
        setCurrentPollQuestion(pollQuestion);
      }
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (
      currentPollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const currentPollInstance = getLatestPollInstanceByPollQuestionId(
        currentPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );
      if (currentPollInstance) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.WeStatementStepOne
        );
        return;
      }
      setPollInstanceToServer(
        currentPollQuestion.id,
        currentTeamLessonUserInstanceId
      ).then(() => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.WeStatementStepOne
        );
      });
    }
  }, [
    currentPollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  useEffect(() => {
    if (pollQuestionResults.length > 0) {
      const count = _.uniqBy(
        pollQuestionResults.filter((q) => q.state === ResponseState.active),
        "userId"
      ).length;
      setCount(count);
    }
  }, [pollQuestionResults]);

  if (step === 0) {
    return (
      <PresentationContainer>
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
          description={
            <Flex flexDir={"column"} gap={"5"}>
              <Text>
                {HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_INTRO_ONE}
              </Text>
              <UnorderedList>
                <ListItem>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html:
                        HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_INTRO_TWO,
                    }}
                  />
                </ListItem>
                <ListItem>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html:
                        HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_INTRO_THREE,
                    }}
                  />
                </ListItem>
              </UnorderedList>
            </Flex>
          }
          listItems={[
            {
              icon: BsPeopleFill as IconType,
              text: HVSportLocalizeStrings.CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE,
            },
            {
              icon: 2,
              text: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_INSTRUCTION,
            },
          ]}
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={currentPollQuestion}
            setDisableSubmit={setDisableSubmit}
          />
        </PresentationContent>
        <Flex
          flexDir={"column"}
          align={"center"}
          data-testid={HVTestId.PollResult.totalVotes}
        >
          <Text color={Black} fontWeight={"semibold"}>
            {HVSportLocalizeStrings.CHARTER_STATISTICS}
          </Text>
          <Text color={Black} fontWeight={"semibold"} fontSize={"xl"}>
            {count} / {total}
          </Text>
        </Flex>

        <AnimatedButton
          disabled={disableSubmit || count < 1}
          w={150}
          colorSet={AnimatedButtonColorSet.Primary}
          text={HVLocalizeStrings.CONTINUE}
          onClick={async () => {
            if (currentTeamLessonUserInstanceId) {
              await setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId,
                CharterActivity.WeStatementStepOneBreak
              );
              setStep(1);
            }
          }}
          dataTestId={HVTestId.PollQuestion.submitButton}
        />
      </PresentationContainer>
    );
  } else if (step === 1 && currentPollQuestion && currentPollInstanceId) {
    return (
      <WeStatementEditor
        setActivity={setActivity}
        pollInstanceResult={pollQuestionResults.filter(
          (pq) => pq.state === ResponseState.active
        )}
        pollQuestion={currentPollQuestion}
        setDisableSubmit={setDisableSubmit}
        currentPollInstanceId={currentPollInstanceId}
      />
    );
  } else {
    return <></>;
  }
};

interface WeStatementEditorProps {
  pollInstanceResult: QuestionUserResponseDto[];
  currentPollInstanceId: number;
  pollQuestion: PollQuestionDto;
  setDisableSubmit: (value: boolean) => void;
  setActivity: (activity: CharterActivity | undefined) => void;
}
const WeStatementEditor = (props: WeStatementEditorProps) => {
  const {
    updatePollUserResponsesToServer,
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const {
    pollInstanceResult,
    currentPollInstanceId,
    pollQuestion,
    setDisableSubmit,
    setActivity,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [showEditing, setShowEditing] = useState<boolean>(false);

  const [editedPollResults, setEditedPollResults] = useState<
    { id: number; responseText: string; state: ResponseState }[]
  >([]);

  useEffect(() => {
    if (pollInstanceResult) {
      const result: any[] = [];
      pollInstanceResult.forEach((item) => {
        result.push({
          id: item.id,
          responseText: item.responseText,
          state: item.state,
        });
      });
      setEditedPollResults(result);
    }
  }, [pollInstanceResult]);

  return (
    <PresentationContainer>
      <PresentationContent
        title={
          showEditing
            ? HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_EDITOR_TITLE
            : HVSportLocalizeStrings.CHARTER_BREAK_TIME_AND_WE_STATEMENTS_REVIEW
        }
        description={
          showEditing ? (
            HVSportLocalizeStrings.CHARTER_WE_STATEMENT_EDITOR_DESCRIPTION
          ) : (
            <Text
              w={"100%"}
              dangerouslySetInnerHTML={{
                __html:
                  HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_REVIEW_INSTRUCTION,
              }}
            ></Text>
          )
        }
        hr={false}
      >
        <CharterTimer
          pollQuestion={pollQuestion}
          setDisableSubmit={setDisableSubmit}
        />
        {showEditing ? (
          <Box mt={"10"}>
            <QuestionUserResponseEditor
              editedPollResults={editedPollResults}
              setEditedPollResults={setEditedPollResults}
            ></QuestionUserResponseEditor>
          </Box>
        ) : (
          <></>
        )}
      </PresentationContent>
      <AnimatedButton
        w={150}
        disabled={loading}
        text={HVLocalizeStrings.CONTINUE}
        colorSet={AnimatedButtonColorSet.Primary}
        onClick={async () => {
          if (!showEditing) {
            setShowEditing(true);
          } else if (currentTeamLessonUserInstanceId) {
            if (currentTeamLessonUserInstanceId) {
              await setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId,
                ""
              );
            }
            if (editedPollResults) {
              setLoading(true);
              await updatePollUserResponsesToServer(
                currentPollInstanceId,
                editedPollResults.map((e) => {
                  return {
                    responseText: e.responseText,
                    state: e.state,
                    questionUserResponseId: e.id,
                  };
                })
              );
              setActivity(undefined);
            }
          }
        }}
      />
    </PresentationContainer>
  );
};
