import { Box, Center, Flex, useBreakpointValue } from "@chakra-ui/react";
import { GoalWinThemeDto } from "@headversity/contract";
import { ReachGoalsWinThemeCard } from "./ReachGoalsWinThemeCard";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { useContext } from "react";
import { GlobalContext } from "../../../State/GlobalContext";
import {
  ReachGoalsContext,
  ReachGoalsScreen,
} from "../../../State/Reach/ReachGoalsContext";

const ReachGoalsSelectionScreenContinueButton = () => {
  const goalsContext = useContext(ReachGoalsContext);
  const globalContext = useContext(GlobalContext);

  const { goalWinThemes, setScreen, setIsLoading } = goalsContext;
  const { saveGoalWinThemes } = globalContext;

  return (
    <AnimatedButton
      colorSet={AnimatedButtonColorSet.Fourth}
      text={HVLocalizeStrings.REACH_GOALS_SELECTION_SCREEN_SAVE}
      onClick={async () => {
        setIsLoading(true);
        await saveGoalWinThemes(goalWinThemes);
        if (goalWinThemes.length > 0) {
          setScreen(ReachGoalsScreen.VIEW);
        } else {
          setScreen(ReachGoalsScreen.INITIAL);
        }
        setIsLoading(false);
      }}
      mr="5px"
      boxShadow={"lg"}
      w={"auto"}
    />
  );
};

export const ReachGoalsSelectionScreen = () => {
  const globalContext = useContext(GlobalContext);

  const { goalWinThemes: allGoalWinThemes } = globalContext;

  const flexBasis = useBreakpointValue({ base: "100%", md: "50%" });
  const maxWidth = useBreakpointValue({ base: "100%", md: "50%" });

  return (
    <>
      <Flex wrap="wrap">
        {allGoalWinThemes.map(
          (goalWinTheme: GoalWinThemeDto, index: number) => (
            <Flex
              key={goalWinTheme.id}
              flexBasis={flexBasis}
              maxWidth={maxWidth}
              flexGrow={1}
              flexShrink={0}
            >
              <ReachGoalsWinThemeCard
                viewOnly={false}
                goalWinTheme={goalWinTheme}
                index={index}
                maxWidth={maxWidth}
              />
            </Flex>
          )
        )}
      </Flex>
      <Box mt={4}>
        <Center>
          <ReachGoalsSelectionScreenContinueButton />
        </Center>
      </Box>
    </>
  );
};
