import { Center, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { LikeAndDislikeButton } from "../../Common/LikeAndDislikeButton";
import { removeSearchParam } from "../../../Utils/Helpers";
import { useNavigate } from "react-router";
import { LessonType } from "@headversity/contract";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";

interface PresentationRatingViewProps {
  teamLessonUserInstanceId?: number;
  closeFunction: () => void;
  isPresenter?: boolean;
}

export const PresentationRatingView = (props: PresentationRatingViewProps) => {
  const { closeFunction, teamLessonUserInstanceId, isPresenter } = props;

  const {
    submitTeamLessonUserInstanceRatingToServer,
    setTeamLessonFeedbackObtained,
    participantPathTeamLesson,
    presenterPathTeamLesson,
  } = useContext<IGlobalProvider>(GlobalContext);

  const navigate = useNavigate();

  const [positivityRating, setPositivityRating] = useState<string>("");
  const [relevancyRating, setRelevancyRating] = useState<string>("");
  const [closeDisabled, setCloseDisabled] = useState<boolean>(true);
  const [lessonType, setLessonType] = useState<LessonType>();
  const [copy, setCopy] = useState({
    title: HVLocalizeStrings.HOWD_IT_GO,
    description: HVLocalizeStrings.LET_US_KNOW,
    questionOne: "", // Will be set based on lessonType
    questionTwo: "", // Will be set based on lessonType
  });
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  useEffect(() => {
    setTeamLessonFeedbackObtained(closeDisabled);
  }, [closeDisabled]);

  useEffect(() => {
    const searchParams = removeSearchParam("teamLessonUserInstanceId");
    navigate(
      {
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }, []);

  useEffect(() => {
    let lessonType;
    if (isPresenter) {
      lessonType = presenterPathTeamLesson?.teamLesson?.lessonType;
    } else {
      lessonType = participantPathTeamLesson?.teamLesson?.lessonType;
    }

    if (lessonType) {
      setLessonType(lessonType);
    } else {
      setLessonType(LessonType.Team);
    }
  }, [isPresenter, participantPathTeamLesson, presenterPathTeamLesson]);

  useEffect(() => {
    let lessonSpecificCopy = {
      questionOne: HVLocalizeStrings.TEAM_LESSON_SUCCESS_RELEVANT,
      questionTwo: HVLocalizeStrings.TEAM_LESSON_SUCCESS_POSITIVELY,
    };

    if (lessonType === LessonType.Charter) {
      lessonSpecificCopy = {
        questionOne: HVSportLocalizeStrings.CHARTER_BETTER_TEAM,
        questionTwo: HVSportLocalizeStrings.CHARTER_RECOMMEND,
      };
    }

    setCopy((prevCopy) => ({
      ...prevCopy,
      ...lessonSpecificCopy,
    }));
  }, [lessonType]);

  return (
    <Flex flexDir={"column"} align={"center"} gap={"10"} minH={"500px"}>
      <BlockSectionTitle title={copy?.title} />
      <Text
        color={MODAL_PRIMARY_TEXT_COLOR}
        textAlign={"center"}
        w={isMobile ? "100%" : "60%"}
      >
        {copy?.description}
      </Text>
      <Flex
        width={isMobile ? "100%" : "80%"}
        mx={"auto"}
        maxW={"auto"}
        px={{ base: 4, sm: 2 }}
        flexDir={"column"}
        gap={"5"}
      >
        <LikeAndDislikeButton
          result={relevancyRating}
          setResult={(relevancyRatingResult: string) => {
            track(TEAM_EVENTS.PresentationThumbsRelevant, {
              HV_Response: relevancyRatingResult === "like" ? "up" : "down",
            });

            setRelevancyRating(relevancyRatingResult);
            submitTeamLessonUserInstanceRatingToServer(
              teamLessonUserInstanceId as number,
              positivityRating === "like" ? 100 : 0,
              relevancyRatingResult === "like" ? 100 : 0
            ).then(() => {
              if (positivityRating) {
                setCloseDisabled(false);
              }
            });
          }}
          description={copy?.questionOne}
        />
        <LikeAndDislikeButton
          result={positivityRating}
          setResult={(positivityRatingResult: string) => {
            track(TEAM_EVENTS.PresentationThumbsMotivated, {
              HV_Response: positivityRatingResult === "like" ? "up" : "down",
            });

            setPositivityRating(positivityRatingResult);
            submitTeamLessonUserInstanceRatingToServer(
              teamLessonUserInstanceId as number,
              positivityRatingResult === "like" ? 100 : 0,
              relevancyRating === "like" ? 100 : 0
            ).then(() => {
              if (relevancyRating) {
                setCloseDisabled(false);
              }
            });
          }}
          description={copy?.questionTwo}
        />
        <Center mt={2}>
          <AnimatedButton
            text={HVLocalizeStrings.CLOSE}
            disabled={closeDisabled}
            colorSet={
              lessonType === LessonType.Charter
                ? AnimatedButtonColorSet.Primary
                : undefined
            }
            onClick={() => {
              closeFunction();
            }}
          />
        </Center>
      </Flex>
    </Flex>
  );
};
