import { useCallback, useContext, useEffect } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { PresentationStepProps } from "./PresentationModal";
import { PresentationRatingView } from "../Shared/PresentationRatingView";
import { PathTeamLessonBlock } from "../Path/PathTeamLessonBlock";
import withUpNextPresentation from "../Shared/withUpNextPresentation";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { SlideFade } from "@chakra-ui/react";
import { SlideFade_Offset } from "../../../Styles/HeadversityStyle";
import { TeamLessonUserInstanceStatus } from "../../../Types/HVTypes";
import { PathDto, PathTeamLessonDto } from "@headversity/contract";

interface PresentationFeedbackStepProps extends PresentationStepProps {
  closeFunction: () => void;
  pathId: string | null;
}

export const PresentationFeedbackStep = (
  props: PresentationFeedbackStepProps
) => {
  const {
    resetTeamSession,
    currentTeamLessonUserInstanceId,
    currentTeam,
    paths,
    teamLessonFeedbackObtained,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { closeFunction, pathId } = props;
  const currentPath = currentTeam
    ? paths[currentTeam.id]?.find(
        (path: PathDto) => path.id === parseInt(pathId as any)
      )
    : undefined;
  const upNextPathTeamLesson = currentPath?.pathTeamLessons.find(
    (pathTeamLesson: PathTeamLessonDto) =>
      pathTeamLesson.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.UpNext
  );
  const UpNextTeamLessonBlock = useCallback(
    withUpNextPresentation(PathTeamLessonBlock),
    []
  );
  useEffect(() => {
    resetTeamSession();
  }, []);

  let content = (
    <>
      <PresentationRatingView
        teamLessonUserInstanceId={currentTeamLessonUserInstanceId}
        closeFunction={() => {
          closeFunction();
        }}
        isPresenter={true}
      ></PresentationRatingView>
      {!teamLessonFeedbackObtained && upNextPathTeamLesson ? (
        <SlideFade in={true} offsetY={SlideFade_Offset}>
          <UpNextTeamLessonBlock
            pathTeamLesson={upNextPathTeamLesson}
            selectedTeam={currentTeam}
            titleText={HVLocalizeStrings.TEAM_HOME_UP_NEXT}
            customAsyncCallback={() => closeFunction()}
            trackingOrigin={"Presenter Post Presentation Feedback UpNext"}
          />
        </SlideFade>
      ) : null}
    </>
  );

  return <>{content}</>;
};
