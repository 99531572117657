import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  CharterActivity,
  PollQuestionDto,
  ResponseState,
} from "@headversity/contract";
import _ from "lodash";
import { getLatestPollInstanceByPollQuestionId } from "../../../../Utils/TeamUtil";
import { Flex, Icon, Text } from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  sportAllSetGreen,
  Error_Red,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import {
  QuestionUserResponseEditor,
  QuestionUserResponseEditorIconState,
} from "../Shared/QuestionUserResponseEditor";
import { RiThumbUpFill } from "react-icons/ri";
import { GoCommentDiscussion } from "react-icons/go";
import { PresentationContent } from "../Shared/PresentationContent";
import { IconType } from "react-icons";
import { IoMdPerson } from "react-icons/io";
import { FaAssistiveListeningSystems } from "react-icons/fa";
import { CharterTimer } from "../Shared/CharterTimer";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { PresentationContainer } from "../Shared/PresentationContainer";

interface PresentationWeStatementStepTwoProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const PresentationWeStatementStepTwo = (
  props: PresentationWeStatementStepTwoProps
) => {
  const { setActivity } = props;
  const {
    pollQuestions,
    pollInstances,
    currentTeamLessonUserInstanceId,
    setPollInstanceToServer,
    setTeamLessonInProgressStepToServer,
    updatePollUserResponsesToServer,
  } = useContext<IGlobalProvider>(GlobalContext);
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [weStatementPollQuestion, setWeStatementPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const [discussionPollQuestion, setDiscussionPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const { count, total, pollQuestionResults, disableSubmit, setDisableSubmit } =
    usePollQuestionInformation({
      pollQuestion: discussionPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  const { pollQuestionResults: weStatementPollResults } =
    usePollQuestionInformation({
      pollQuestion: weStatementPollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  const [discussionPollResults, setDiscussionPollResults] = useState<number[]>(
    []
  );

  const [goodPollResults, setGoodPollResults] = useState<
    { id: number; responseText: string; state: ResponseState }[]
  >([]);

  const [needDiscussionPollResults, setNeedDiscussionPollResults] = useState<
    { id: number; responseText: string; state: ResponseState }[]
  >([]);

  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (pollQuestions) {
      const weStatementPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      setWeStatementPollQuestion(weStatementPollQuestion);

      const discussionPollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_8_PQ"
      );
      setDiscussionPollQuestion(discussionPollQuestion);
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (
      discussionPollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const currentPollInstance = getLatestPollInstanceByPollQuestionId(
        discussionPollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );
      if (currentPollInstance) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.WeStatementStepTwo
        );
        return;
      }
      setPollInstanceToServer(
        discussionPollQuestion.id,
        currentTeamLessonUserInstanceId
      ).then(() => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.WeStatementStepTwo
        );
      });
    }
  }, [
    discussionPollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  useEffect(() => {
    if (pollQuestionResults) {
      const finalIdsNeedToDiscuss: number[] = [];
      pollQuestionResults.forEach((item) => {
        if (item.responseText) {
          const idsNeedToDiscuss = item.responseText.split(",");
          idsNeedToDiscuss.forEach((id) => {
            finalIdsNeedToDiscuss.push(parseInt(id));
          });
        }
      });

      setDiscussionPollResults(_.uniq(finalIdsNeedToDiscuss));
    }
  }, [pollQuestionResults]);

  useEffect(() => {
    if (weStatementPollResults && discussionPollResults) {
      const result: any[] = [];
      const discussionResult: any[] = [];
      weStatementPollResults.forEach((item) => {
        if (item.state !== ResponseState.active) {
          return;
        }
        if (discussionPollResults.indexOf(item.id) > -1) {
          discussionResult.push({
            id: item.id,
            responseText: item.responseText,
            state: item.state,
          });
        } else {
          result.push({
            id: item.id,
            responseText: item.responseText,
            state: item.state,
          });
        }
      });
      setGoodPollResults(result);
      setNeedDiscussionPollResults(discussionResult);
    }
  }, [weStatementPollResults, discussionPollResults]);

  return (
    <PresentationContainer>
      {step === 0 ? (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
          description={
            HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_DESCRIPTION
          }
          listItems={[
            {
              icon: IoMdPerson as IconType,
              text: HVSportLocalizeStrings.CHARTER_ONLY_USE_GROUP_REPORTER_DEVICE,
            },
            {
              icon: FaAssistiveListeningSystems as IconType,
              text: HVSportLocalizeStrings.CHARTER_TAKE_SOME_TIME_TO_DISCUSS,
            },
          ]}
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={discussionPollQuestion}
            setDisableSubmit={setDisableSubmit}
          />
        </PresentationContent>
      ) : (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_FINAL_TITLE}
          description={
            <Flex flexDir={"column"} gap={"5"}>
              <Text>
                {
                  HVSportLocalizeStrings.CHARTER_WE_STATEMENT_TWO_INSTRUCTION_ONE
                }
              </Text>
            </Flex>
          }
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={discussionPollQuestion}
            setDisableSubmit={setDisableSubmit}
          />
          <Flex flexDir={"row"} align={"start"} justify={"start"} w={"100%"}>
            {goodPollResults.length > 0 && (
              <Flex
                flexDir={"column"}
                align={"start"}
                justify={"start"}
                w={needDiscussionPollResults.length > 0 ? "50%" : "100%"}
                p={"5"}
                gap={"2"}
              >
                <Flex
                  align={"center"}
                  justify={"center"}
                  flexDir={"column"}
                  color={sportAllSetGreen}
                  w={"100%"}
                >
                  <Icon
                    w={"30px"}
                    h={"30px"}
                    aria-label={"like"}
                    as={RiThumbUpFill}
                    color={sportAllSetGreen}
                  />
                  <Text textAlign={"center"}>All Set</Text>
                </Flex>
                <QuestionUserResponseEditor
                  editedPollResults={goodPollResults}
                  setEditedPollResults={setGoodPollResults}
                  iconState={
                    QuestionUserResponseEditorIconState.alwaysShowRemove
                  }
                />
              </Flex>
            )}
            {needDiscussionPollResults.length > 0 && (
              <Flex
                flexDir={"column"}
                align={"start"}
                justify={"start"}
                w={goodPollResults.length > 0 ? "50%" : "100%"}
                p={"5"}
                gap={"2"}
              >
                <Flex
                  align={"center"}
                  justify={"center"}
                  flexDir={"column"}
                  color={Error_Red}
                  w={"100%"}
                >
                  <Icon
                    w={"30px"}
                    h={"30px"}
                    aria-label={"like"}
                    as={GoCommentDiscussion}
                    color={Error_Red}
                  />
                  <Text textAlign={"center"}>Discuss / Edit</Text>
                </Flex>
                <QuestionUserResponseEditor
                  editedPollResults={needDiscussionPollResults}
                  setEditedPollResults={setNeedDiscussionPollResults}
                  iconState={
                    QuestionUserResponseEditorIconState.alwaysShowRemove
                  }
                />
              </Flex>
            )}
          </Flex>
        </PresentationContent>
      )}
      <Flex
        flexDir={"column"}
        align={"center"}
        data-testid={HVTestId.PollResult.totalVotes}
      >
        <Text color={Black} fontWeight={"semibold"}>
          {HVSportLocalizeStrings.CHARTER_STATISTICS}
        </Text>
        <Text color={Black} fontWeight={"semibold"} fontSize={"xl"}>
          {count} / {total}
        </Text>
      </Flex>

      <AnimatedButton
        disabled={disableSubmit || count < 1 || loading}
        colorSet={AnimatedButtonColorSet.Primary}
        w={150}
        text={HVLocalizeStrings.CONTINUE}
        onClick={async () => {
          if (currentTeamLessonUserInstanceId) {
            if (step === 0) {
              setStep(1);
            } else {
              setLoading(true);
              await setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId,
                ""
              );

              if ((goodPollResults || needDiscussionPollResults) && weStatementPollQuestion) {
                const pollInstance = getLatestPollInstanceByPollQuestionId(
                  weStatementPollQuestion.id,
                  pollInstances,
                  currentTeamLessonUserInstanceId
                );

                if (pollInstance) {
                  await updatePollUserResponsesToServer(
                    pollInstance?.id,
                    goodPollResults.concat(needDiscussionPollResults).map((e) => {
                      return {
                        responseText: e.responseText,
                        state: e.state,
                        questionUserResponseId: e.id,
                      };
                    })
                  );
                }
              }
              setActivity(undefined);
            }
          }
        }}
      />
    </PresentationContainer>
  );
};
