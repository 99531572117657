import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import {
  CertDto,
  CertInstanceDto,
  CertSectionInstanceDto,
  LessonQuestionAnswerDto,
  UserCertificateDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export const getCerts = (token: string): Promise<AxiosResponse<CertDto[]>> => {
  return AxiosClient.get(
    `api/certs?tz=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    getConfig(token)
  );
};

export const getUserCertificate = (
  guid: string
): Promise<AxiosResponse<UserCertificateDto>> => {
  return AxiosClient.get(`api/cert/instance/${guid}`);
};

export const setCertInstance = (
  token: string,
  certId: number
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/certs/${certId}/instance`,
    null,
    getConfig(token)
  );
};

export const setCertSectionInstanceState = (
  token: string,
  certSectionInstanceId: number,
  certSectionInstanceDto: CertSectionInstanceDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.put(
    `api/certSectionInstance/${certSectionInstanceId}`,
    certSectionInstanceDto,
    getConfig(token)
  );
};

export const setCertInstanceState = (
  token: string,
  certInstanceId: number,
  certInstanceDto: CertInstanceDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.put(
    `api/certInstance/${certInstanceId}`,
    certInstanceDto,
    getConfig(token)
  );
};

export const saveCertQuestionAnswers = (
  token: string,
  certSectionId: number,
  lessonQuestionAnswerDto: LessonQuestionAnswerDto
): Promise<AxiosResponse<void>> => {
  return AxiosClient.post(
    `api/certSectionInstance/${certSectionId}/questionsAndAnswers`,
    lessonQuestionAnswerDto,
    getConfig(token)
  );
};
