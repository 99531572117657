import { useContext, useEffect, useState } from "react";
import {
  Center,
  Box,
  useBreakpointValue,
  IconButton,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  BG_REACH_BASE,
  CONTRAST_TEXT_COLOR,
  HEADER_REACH_HOME_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { ReachPromoMaterialCard } from "./ReachPromoMaterialCard";
import { BlockSection } from "../Common/BlockSection";
import BlockSectionTitle from "../Common/BlockSectionTitle";
import { ScrollPanel } from "../Common/ScrollPanel";
import { ScrollPanelBlock } from "../Common/ScrollPanelBlock";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import dayjs from "dayjs";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { HvSpinner } from "../Common/HvSpinner";
import { REACH_EVENTS, track } from "../../Utils/Analytics";
import ReachWelcomeModal from "./ReachWelcomeModal";
import { PromotionalMaterialDto } from "@headversity/contract";
import { MdInfo } from "react-icons/md";
import { ReachQuickStartPanel } from "./Organizations/ReachQuickStartPanel";
import { ReachQuickStartModal } from "./Organizations/ReachQuickStartModal";
import { ReachMyOrganizationContext } from "../../State/Reach/ReachMyOrganizationContext";
import InsightsUsersPanel from "./Insights/InsightsUsersPanel";
import { ReachInsightsContext } from "../../State/Reach/ReachInsightsContext";
import { ScrollPanelViewMoreLinkButton } from "../Common/ScrollPanelViewMoreLinkButton";
import { HeaderWelcomeText } from "../Common/HeaderWelcomeText";

const enum REACH_PAGE_SECTIONS {
  Insights,
  RecommendedPromoMaterials,
}

const enum REACH_GUIDE_SECTIONS {
  Welcome,
  Team,
  Solo,
  Support,
}

export interface UserGuide {
  id: REACH_GUIDE_SECTIONS;
  title: string | JSX.Element;
  description: string;
  thumbnail: string;
  guide: string;
}

export const ReachHomePage = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState<boolean>(false);
  const [orgDataLoaded, setOrgDataLoaded] = useState<boolean>(false);

  const {
    promoMaterials,
    promoMaterialsLoading,
    setIsTourOpen,
    getPageTitle,
    setSelectedPromoMaterial,
    getPromoMaterialAssetsFromServer,
    isReachAdminEnabled,
    adminProductTypes,
    isHierarchyAssociationStepComplete,
    userHierarchyAssociations,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { isQuickStartGuideOpen, fetchData, quickStart } = useContext(
    ReachMyOrganizationContext
  );

  const { usersReport } = useContext(ReachInsightsContext);

  const navigate = useNavigate();

  useEffect(() => {
    setIsWelcomeModalOpen(
      localStorage.getItem("viewed-reach-welcome-modal") === null &&
        isHierarchyAssociationStepComplete
    );
    if (isReachAdminEnabled) {
      // Organization data
      fetchData().then(() => setOrgDataLoaded(true));
      // Users report data
      usersReport.fetchReport(undefined, true);
    }
  }, [isReachAdminEnabled, fetchData, isHierarchyAssociationStepComplete]);

  useEffect(() => {
    const isCompanyReachAdmin = (
      !userHierarchyAssociations?.hierarchyId
        ? true
        : userHierarchyAssociations?.isRoot && isReachAdminEnabled
    ) as boolean;
    setIsCompanyReachAdmin(isCompanyReachAdmin);
  }, [userHierarchyAssociations, isReachAdminEnabled]);

  const [isCompanyReachAdmin, setIsCompanyReachAdmin] =
    useState<boolean>(false);

  const handleClickPromoMaterial = async (
    promoMaterial: PromotionalMaterialDto
  ) => {
    setSelectedPromoMaterial(promoMaterial);
    await getPromoMaterialAssetsFromServer(promoMaterial.id);
    navigate(window.location.pathname + `?promo=${promoMaterial?.id}`, {
      replace: true,
    });
    track(REACH_EVENTS.HomePageOpenItem, {
      HV_Reach_PromoMaterialId: promoMaterial.id,
      HV_Reach_PromoMaterialTitle: promoMaterial.name_loc?.en,
    });
  };

  const productAssociatedPromoMaterials = promoMaterials.filter((pm) => {
    return pm.productTypes?.some((productType) =>
      adminProductTypes.includes(productType)
    );
  });

  const promoMaterialsForCurrentMonth = productAssociatedPromoMaterials.filter(
    (pm) => {
      const pmStartDate = dayjs.utc(`${pm.startDate}`).local().toDate();
      return (
        pmStartDate.getMonth() === new Date().getMonth() &&
        pmStartDate.getFullYear() === new Date().getFullYear()
      );
    }
  );

  const sections = [
    {
      className: "recommended-assets",
      id: REACH_PAGE_SECTIONS.RecommendedPromoMaterials,
      title: HVLocalizeStrings.REACH_RECOMMENDED_ASSETS,
      description: HVLocalizeStrings.REACH_RECOMMENDED_ASSETS_TOUR_DESCRIPTION,
      data:
        promoMaterialsForCurrentMonth.length > 0
          ? promoMaterialsForCurrentMonth
          : productAssociatedPromoMaterials.slice(0, 5),
    },
  ];
  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(
            `${HVLocalizeStrings.REACH} - ${HVLocalizeStrings.HEADVERSITY}`
          )}
        </title>
      </Helmet>
      <HeaderSetter
        title={
          <>
            {"REACH"}
            <IconButton
              bg={"transparent"}
              _hover={{ bg: "transparent" }}
              minW={"auto"}
              mt={"-20px"}
              icon={<Icon as={MdInfo} />}
              w={"18px"}
              color={CONTRAST_TEXT_COLOR}
              onClick={() => setIsWelcomeModalOpen(true)}
              aria-label={"Info"}
            />
          </>
        }
        description={HVLocalizeStrings.REACH_HOME_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
        showLogo={true}
      ></HeaderSetter>

      <FadeAfterDelay>
        <Box
          maxW={isMainContainerXL ? "container.xl" : "container.lg"}
          mx={"auto"}
          mb={"20px"}
        >
          {window.innerWidth < 568 && (
            <BlockSection fadeIn={true} columns={1} noPaddingBottom={true}>
              <HeaderWelcomeText blockSection={true} blockSectionMb={4} />
            </BlockSection>
          )}

          {isReachAdminEnabled && (
            <Flex flexDirection={"column"} mx={5} mb={6} gap={2}>
              {isCompanyReachAdmin && (
                <>
                  {!orgDataLoaded ? (
                    <Center>
                      <HvSpinner />
                    </Center>
                  ) : (
                    <>
                      {quickStart?.progress !== 100 && <ReachQuickStartPanel />}
                    </>
                  )}
                </>
              )}
              <InsightsUsersPanel isFromReachHome={true} />
              <Box mt={3}>
                <ScrollPanelViewMoreLinkButton
                  isDesktop={isDesktop}
                  onClick={() => {
                    navigate("/reach/insights");
                  }}
                >
                  {HVLocalizeStrings.REACH_HOME_SEE_ALL_INSIGHTS}
                </ScrollPanelViewMoreLinkButton>
              </Box>
            </Flex>
          )}

          {promoMaterialsLoading ? (
            <BlockSection fadeIn={true} columns={1}>
              <Center>
                <HvSpinner />
              </Center>
            </BlockSection>
          ) : (
            <>
              {sections.map(
                (section) =>
                  section.data.length > 0 && (
                    <BlockSection
                      gridClassName={section.className}
                      key={section.id}
                      columns={1}
                      fadeIn={true}
                      title={
                        <BlockSectionTitle
                          title={section.title}
                          description={section.description}
                        ></BlockSectionTitle>
                      }
                    >
                      <ScrollPanel
                        scrollOnMobileOnly={false}
                        showChevronContainer={true}
                        spaceBetween={8}
                        slidesPerGroup={isDesktop ? 3 : 1}
                        slidesPerView={"auto"}
                        isPaginationOnContainer={true}
                      >
                        {section.data.map((item) => {
                          return ScrollPanelBlock(
                            <>
                              {section.id ===
                                REACH_PAGE_SECTIONS.RecommendedPromoMaterials && (
                                <Box
                                  key={item.id}
                                  onClick={() =>
                                    handleClickPromoMaterial(
                                      item as PromotionalMaterialDto
                                    )
                                  }
                                  width={"290px"}
                                  position={"relative"}
                                >
                                  <ReachPromoMaterialCard
                                    reachPromoItem={
                                      item as PromotionalMaterialDto
                                    }
                                  />
                                </Box>
                              )}
                            </>,
                            "290px",
                            item.id
                          );
                        })}
                      </ScrollPanel>
                      {section.id ===
                        REACH_PAGE_SECTIONS.RecommendedPromoMaterials && (
                        <ScrollPanelViewMoreLinkButton
                          isDesktop={isDesktop}
                          onClick={() => {
                            track(REACH_EVENTS.HomePageSeeAllAssets);
                            navigate("/reach/promote");
                          }}
                        >
                          {HVLocalizeStrings.REACH_SEE_ALL_ASSETS}
                        </ScrollPanelViewMoreLinkButton>
                      )}
                    </BlockSection>
                  )
              )}
            </>
          )}
        </Box>
      </FadeAfterDelay>

      <ReachWelcomeModal
        open={isWelcomeModalOpen}
        setOpen={(val: boolean) => {
          setIsWelcomeModalOpen(val);
          if (!val) {
            track(REACH_EVENTS.WelcomeShown);
            setIsTourOpen(true);
          }
        }}
      />
      {isQuickStartGuideOpen && <ReachQuickStartModal />}
    </>
  );
};
