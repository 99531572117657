import { Box, useBreakpointValue } from "@chakra-ui/react";
import { ScrollPanel } from "../../Common/ScrollPanel";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { BlogPostDto } from "@headversity/contract";
import { ResilienceFeedItemBlock20 } from "./ResilienceFeedItemBlock20";

interface ResilienceFeedSliderProps {
  resilienceFeedPosts: BlogPostDto[];
  from: string;
  hideSubTitle?: boolean;
  isLiveSessions?: boolean;
  showPoints?: boolean;
}

export const ResilienceFeedSlider = ({
  resilienceFeedPosts,
  from,
  hideSubTitle,
  isLiveSessions,
  showPoints,
}: ResilienceFeedSliderProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <ScrollPanel
      scrollOnMobileOnly={false}
      spaceBetween={8}
      showChevronContainer={true}
      slidesPerGroup={isDesktop ? 3 : 1}
      slidesPerView={"auto"}
      isPaginationOnContainer={true}
    >
      {resilienceFeedPosts?.slice(0, 8).map((resilienceFeedPostItem) => {
        return ScrollPanelBlock(
          <Box
            key={resilienceFeedPostItem.id}
            width="100%"
            position="relative"
            // image aspectRatio="16/9"
            pb={isLiveSessions ? "calc(56.25% + 45px)" : "calc(56.25% + 59px)"}
          >
            <Box position="absolute" top="0" left="0" right="0" bottom="0">
              <ResilienceFeedItemBlock20
                post={resilienceFeedPostItem}
                isSmall={!isDesktop}
                isDesktop={isDesktop}
                from={from}
                hideSubTitle={hideSubTitle}
                width="100%"
                height="100%"
                showTitleAreaBackground={false}
                isLiveSession={isLiveSessions}
                showPoints={showPoints}
              />
            </Box>
          </Box>,
          "325px",
          resilienceFeedPostItem.id
        );
      })}
    </ScrollPanel>
  );
};
