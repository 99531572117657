export enum FeatureFlags {
  WELCOME_2023 = "WELCOME_2023",
  //SKILLS_2_0 = "SKILLS_2_0", // flag removed and code defaulted to SKILLS_2_0 version in late May 2023
  //CORE_SKILLS_ONLY = "CORE_SKILLS_ONLY", //flag removed in April 2024 [HV-4780]
  CUSTOM_FOOTER = "CUSTOM_FOOTER",
  REACH_ADMIN = "REACH_ADMIN",
  //AI_NANO_PRACTICE = "AI_NANO_PRACTICE", // flag removed in Sept 2024 [HV-5332]
  DISABLE_AI_NANO_PRACTICE = "DISABLE_AI_NANO_PRACTICE",
  AI_NANO_PRACTICE = "AI_NANO_PRACTICE",
  HIERARCHY_ADMIN = "HIERARCHY_ADMIN",
}
