import {
  CompletionState,
  LocalizationText,
  MicroLessonDto,
  MicroLessonUserInstanceDto,
  SkillDto,
} from "@headversity/contract";
import { useCallback, useState } from "react";
import { AxiosResponse } from "axios";
import { getLessons } from "../../Api/Solo/LessonApi";
import { getKey } from "../../Utils/Helpers";
import _ from "lodash";
import {
  getLessonLastViewedSlide,
  getMicroLessonInstance,
} from "../../Api/Solo/LessonCourseApi";
import { getLessonCompletionDateAndStatus } from "../../Utils/LessonsUtil";

export interface IMicroLessonProvider {
  lessons: MicroLessonDto[];
  lessonMap: { [key: string]: MicroLessonDto[] };
  lesson: MicroLessonDto | null;
  setGlobalLesson: (microLesson: MicroLessonDto | null) => void;
  isLessonPreviewOpen: boolean;
  setIsLessonPreviewOpen: (open: boolean) => void;
  lessonNotFound: boolean;
  setLessonNotFound: (open: boolean) => void;
  getLessonsFromServer: (skills: SkillDto[]) => void;
  getSuggestedLessons: (
    preferredSkillIds: number[],
    allSkillIds: number[],
    includeCustom?: boolean
  ) => MicroLessonDto[];
  getLessonLastViewedSlideFromServer: (
    lessonCourseUserInstanceId: number
  ) => Promise<AxiosResponse<LocalizationText>>;
  getOrCreateMicroLessonInstanceFromServer: (
    microLessonId: number
  ) => Promise<AxiosResponse<MicroLessonUserInstanceDto>>;
  lessonCompletedCount: number;
}

export const useMicroLesson = (): IMicroLessonProvider => {
  const [lessons, setLessons] = useState<MicroLessonDto[]>([]);
  const [lessonMap, setLessonMap] = useState<{
    [key: string]: MicroLessonDto[];
  }>({});
  const [globalLesson, setGlobalLesson] = useState<MicroLessonDto | null>(null);
  const [lessonNotFound, setLessonNotFound] = useState<boolean>(false);
  const [isLessonPreviewOpen, setIsLessonPreviewOpen] =
    useState<boolean>(false);
  const [lessonCompletedCount, setLessonCompletedCount] = useState<number>(0);

  const getLessonsFromServer = useCallback(async (skillsToUse: SkillDto[]) => {
    return getLessons(getKey()).then((response) => {
      // remove unavailable skills from lessons
      const lessons = response.data.microLessons.filter(
        (x: any) =>
          !x.mainSkillId || skillsToUse.some((y) => x.mainSkillId === y.id)
      );

      const lessonMap = _.groupBy(lessons, (item) =>
        item.skillCompetencies?.length > 0
          ? item.skillCompetencies[0].skillId
          : -1
      );

      setLessons(lessons);
      setLessonMap(lessonMap);
      setLessonCompletedCount(response.data.completedCount);
    });
  }, []);

  const getLessonLastViewedSlideFromServer = useCallback(
    (
      lessonCourseUserInstanceId: number
    ): Promise<AxiosResponse<LocalizationText>> => {
      return getLessonLastViewedSlide(getKey(), lessonCourseUserInstanceId);
    },
    []
  );

  const getOrCreateMicroLessonInstanceFromServer = useCallback(
    (
      microLessonId: number
    ): Promise<AxiosResponse<MicroLessonUserInstanceDto>> => {
      return getMicroLessonInstance(getKey(), microLessonId);
    },
    []
  );

  const getCompletionState = (lesson: MicroLessonDto) => {
    const { state } = getLessonCompletionDateAndStatus(lesson);
    return state;
  };

  /*
   * Breadth-first suggested lessons for the input sets of sorted skills
   */
  const getSuggestedLessons = (
    preferredSkillIds: number[],
    allSkillIds: number[],
    includeCustom?: boolean
  ): MicroLessonDto[] => {
    const newSuggestions: MicroLessonDto[] = [];

    // in-progress lessons in preferred skills
    for (const x of preferredSkillIds) {
      const skillLessons = lessonMap[x.toString()];

      if (!skillLessons) {
        continue;
      }

      // if there's an in-progress lesson, pick it
      const inProgressLesson = skillLessons.find(
        (x) => getCompletionState(x) === CompletionState.inProgress
      );

      if (inProgressLesson) {
        newSuggestions.push(inProgressLesson);
      }
    }

    // incomplete custom lessons that are not associated with a skill
    if (includeCustom) {
      lessons
        .filter((x) => x?.skillCompetencies?.length === 0)
        .forEach((lesson) => {
          if (
            lesson.custom &&
            getCompletionState(lesson) !== CompletionState.done
          ) {
            newSuggestions.push(lesson);
          }
        });
    }

    // add in all the rest:
    //  we'll take 1 lesson per skill (in order that we've sorted skills) until all lessons are exhausted
    //  first the not started, then the done (all in progress were already taken)
    const notDoneLessons: MicroLessonDto[] = [];
    const doneLessons: MicroLessonDto[] = [];

    for (const x of allSkillIds) {
      // get lessons in this skill
      const skillLessons = lessonMap[x.toString()];
      if (!skillLessons) {
        continue;
      }

      // add to the appropriate list by status
      for (const l of skillLessons) {
        const status = getCompletionState(l);

        if (
          status === CompletionState.notStarted ||
          status === CompletionState.inProgress
        ) {
          notDoneLessons.push(l);
        } else {
          doneLessons.push(l);
        }
      }
    }

    // preferred skills with nothing done
    for (const x of preferredSkillIds) {
      // something already done?
      if (
        doneLessons.find((y) =>
          y.skillCompetencies.some((y) => y.skillId === x)
        )
      ) {
        continue;
      }

      // in this skill
      const lesson = notDoneLessons.find((y) =>
        y.skillCompetencies.some((y) => y.skillId === x)
      );

      if (lesson) {
        newSuggestions.push(lesson);
      }
    }

    // preferred skills with something already done
    for (const x of preferredSkillIds) {
      // something already done?
      if (
        !doneLessons.find((y) =>
          y.skillCompetencies.some((y) => y.skillId === x)
        )
      ) {
        continue;
      }

      // in this skill
      const lesson = notDoneLessons.find((y) =>
        y.skillCompetencies.some((y) => y.skillId === x)
      );

      if (lesson) {
        newSuggestions.push(lesson);
      }
    }

    // then the rest so that all lessons are placed
    for (const lessonsToCheck of [notDoneLessons, doneLessons]) {
      if (lessonsToCheck.length === 0) continue;

      while (true) {
        for (const skillId of allSkillIds) {
          const lesson = lessonsToCheck.find(
            (x) =>
              // not already considered
              !newSuggestions.find((y) => y.id === x.id) &&
              // in this skill
              x.skillCompetencies.some((y) => y.skillId === skillId)
          );

          if (lesson) {
            newSuggestions.push(lesson);
          }
        }

        // stop once all lessons in this state group are accounted for
        if (
          !lessonsToCheck.some(
            (x) => !newSuggestions.find((y) => y.id === x.id)
          )
        ) {
          break;
        }
      }
    }

    return _.uniqBy(newSuggestions, "id");
  };

  return {
    lessons,
    lessonMap,
    lesson: globalLesson,
    setGlobalLesson,
    setIsLessonPreviewOpen,
    lessonNotFound,
    setLessonNotFound,
    getSuggestedLessons,
    getLessonsFromServer,
    getLessonLastViewedSlideFromServer,
    getOrCreateMicroLessonInstanceFromServer,
    isLessonPreviewOpen,
    lessonCompletedCount,
  };
};
