import { useContext, useEffect, useState } from "react";
import { HeaderSetter } from "../Common/HeaderSetter";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { BlockSection } from "../Common/BlockSection";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import BlockSectionTitle from "../Common/BlockSectionTitle";
import { Helmet } from "react-helmet";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import {
  BG_CERT_BASE,
  CERT_HOME_BACKGROUND,
} from "../../Styles/HeadversityStyle";
import { LessonsScrollPanel } from "../Solo/Shared/LessonsScrollPanel";
import { StickyBreadcrumb } from "../Common/StickyBreadcrumb";
import { ErrorPage } from "../Shared/Error/ErrorPage";
import { NanoPracticesScrollPanel } from "../Solo/Shared/NanoPracticesScrollPanel";
import { RecommendedRefreshersPanel } from "./Shared/RecommendedRefreshersPanel";
import {
  CertDto,
  MicroLessonDto,
  NanoPracticeDto,
  CertRelationDto,
  CompletionState,
  MicroLessonUserInstanceDto,
  NanoPracticeUserInstanceDto,
  CertQualificationDto,
} from "@headversity/contract";
import { useParams } from "react-router-dom";
import {
  CertQualificationData,
  QualificationProgressCard,
} from "./Shared/QualificationProgressCard";
import dayjs from "dayjs";
import IconWithToolTip from "../Common/IconWithToolTip";
import { FiInfo } from "react-icons/fi";
import { getCertQualificationData } from "../../Utils/SkillsUtil";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { showEarnPointsToast } from "../../Utils/EarnPointsUtil";
import { CertQualificationDoneModal } from "../Common/BadgesAndShields/CertQualificationDoneModal";
import { EVENTS, track } from "../../Utils/Analytics";
import { HvSpinner } from "../Common/HvSpinner";

interface ToolTipProps {
  tooltipText: string;
}

const ToolTip = (props: ToolTipProps) => {
  const { tooltipText } = props;
  return (
    <IconWithToolTip
      hasLeftPadding={true}
      text={tooltipText}
      placement={"bottom"}
      icon={FiInfo}
    />
  );
};

export const CertQualificationsPage = () => {
  const {
    lessons,
    lessonNotFound,
    getPageTitle,
    nanoPractices,
    certifications,
    nanoPracticeInstances,
    setQualificationPractices,
    updateLocalCertificationQualificationScores,
    certQualificationData,
    setUserStatsToServer,
    certQualifications,
    isUninterruptibleModalOpen,
    certQualificationDataForPoints,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { showToast } = useContext<IShellProvider>(ShellContext);
  const { slug } = useParams();
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [cert, setCert] = useState<CertDto>();
  const [moreLessons, setMoreLessons] = useState<MicroLessonDto[]>([]);
  const [suggestedLesson, setSuggestedLesson] = useState<MicroLessonDto>();
  const [moreNanoPractices, setMoreNanoPractices] = useState<NanoPracticeDto[]>(
    []
  );

  const [suggestedNanoPractices, setSuggestedNanoPractices] = useState<
    NanoPracticeDto[]
  >([]);

  const [certData, setCertData] = useState<CertQualificationData>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lastFetchedDate, setLastFetchedDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const localData = localStorage.getItem("cert_qualifications");
    const localCertQualifications: CertQualificationDto[] = localData
      ? JSON.parse(localData)
      : [];
    const localCertQualification = localCertQualifications.find(
      (localCertQualification) => {
        return parseInt(slug as string) === localCertQualification.certId;
      }
    );
    const certQualification = certQualifications.find((c) => {
      return c.certId === parseInt(slug as string);
    });
    setCertData(
      getCertQualificationData(localCertQualification, certQualification)
    );
    setLoading(false);
  }, [certQualifications, slug]);

  useEffect(() => {
    // check for day change on an interval, and refresh data when it changes
    const interval = setInterval(() => {
      setLastFetchedDate((prev) => {
        const now = dayjs().format("YYYY-MM-DD");

        if (prev && now !== prev) {
          setUserStatsToServer();
        }

        return now;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!slug || nanoPractices.length === 0 || lessons.length === 0) {
      return;
    }

    const certId = slug ? parseInt(slug) : 0;
    const certificate = certifications.find((cert) => cert.id === certId);
    setCert(certificate);

    if (!certificate) {
      return;
    }

    const startDate = certData?.startDate;

    // Sort micro-lessons and nano-practices by their cert relation position
    const sortByCertRelation = (
      a: MicroLessonDto | NanoPracticeDto,
      b: MicroLessonDto | NanoPracticeDto
    ) => {
      const certRelationA = a.certRelations?.find(
        (certRelation: CertRelationDto) => certRelation.certId === certId
      );
      const certRelationB = b.certRelations?.find(
        (certRelation: CertRelationDto) => certRelation.certId === certId
      );
      return (
        (certRelationA?.position ?? 9999) - (certRelationB?.position ?? 9999)
      );
    };

    // nano-practices are sorted by their completion date (if before the period start) ascending, with practices never done (before the period) first
    const sortPracticesByCompletionDate = (
      a: NanoPracticeDto,
      b: NanoPracticeDto,
      onlySortBeforeDate?: string
    ) => {
      const getPracticeInstanceDate = (
        instances: NanoPracticeUserInstanceDto[] | undefined,
        onlySortBeforeDate?: string
      ) => {
        if (instances !== undefined && instances.length > 0) {
          const instanceDate = dayjs(
            instances[instances.length - 1]?.createdAt.toString()
          );
          if (
            onlySortBeforeDate === undefined ||
            (onlySortBeforeDate && instanceDate.isBefore(onlySortBeforeDate))
          ) {
            return instanceDate.unix();
          }
        }
        return 0;
      };
      let practiceAInstanceDate = getPracticeInstanceDate(
        nanoPracticeInstances[a.id],
        onlySortBeforeDate
      );
      let practiceBInstanceDate = getPracticeInstanceDate(
        nanoPracticeInstances[b.id],
        onlySortBeforeDate
      );
      return practiceAInstanceDate - practiceBInstanceDate;
    };
    const sortPracticesByCompletionDateBeforePeriod = (
      a: NanoPracticeDto,
      b: NanoPracticeDto
    ) => {
      return sortPracticesByCompletionDate(a, b, startDate);
    };

    // lessons are sorted by their completion date ascending, with lessons never done (before the period) first
    const sortLessonsByCompletionDate = (
      a: MicroLessonDto,
      b: MicroLessonDto,
      onlySortBeforeDate?: string
    ) => {
      const getCompletedInstances = (lesson: MicroLessonDto) => {
        return lesson.microLessonUserInstances?.filter((i) => {
          return i.state === CompletionState.done;
        });
      };
      const lessonACompletedInstances = getCompletedInstances(a);
      const lessonBCompletedInstances = getCompletedInstances(b);
      const getLatestInstanceDate = (
        instances: MicroLessonUserInstanceDto[] | undefined,
        onlySortBeforeDate?: string
      ) => {
        if (instances !== undefined && instances.length > 0) {
          let date = dayjs(
            instances[instances.length - 1]?.updatedAt.toString()
          );
          if (
            onlySortBeforeDate === undefined ||
            (onlySortBeforeDate && date.isBefore(onlySortBeforeDate))
          ) {
            return date.unix();
          }
        }
        return 0;
      };
      const lessonAInstanceDate = getLatestInstanceDate(
        lessonACompletedInstances,
        onlySortBeforeDate
      );
      const lessonBInstanceDate = getLatestInstanceDate(
        lessonBCompletedInstances,
        onlySortBeforeDate
      );
      return lessonAInstanceDate - lessonBInstanceDate;
    };
    const sortLessonsByCompletionDateBeforePeriod = (
      a: MicroLessonDto,
      b: MicroLessonDto
    ) => {
      return sortLessonsByCompletionDate(a, b, startDate);
    };

    let lessonsToShow = lessons.filter((lesson) => {
      return lesson.certRelations?.some((cert) => cert.certId === certId);
    });
    lessonsToShow = lessonsToShow.sort(sortByCertRelation);
    const lessonsToShowSortedByIncompleteBeforePeriod = [...lessonsToShow]
      .sort(sortLessonsByCompletionDateBeforePeriod)
      .filter((lesson) => {
        return (
          // filter out lessons that have been completed before the startDate
          lesson.microLessonUserInstances?.filter((i) => {
            return (
              i.state === CompletionState.done &&
              dayjs(i.updatedAt.toString()).isBefore(startDate)
            );
          }).length === 0
        );
      });
    lessonsToShow = lessonsToShow.sort(sortLessonsByCompletionDate);

    // show the top completed lesson as suggested and the rest under the more section
    const getSuggestedLesson = () => {
      // if there is a lesson incomplete before the period, show that
      return lessonsToShowSortedByIncompleteBeforePeriod?.length > 0
        ? lessonsToShowSortedByIncompleteBeforePeriod[0]
        : undefined;
    };
    const suggestedLesson = getSuggestedLesson();
    const getMoreLessons = () => {
      const lessonsWithoutSuggested = lessonsToShow.filter(
        (l) => l.id !== suggestedLesson?.id
      );
      return lessonsWithoutSuggested?.length >= 6
        ? lessonsWithoutSuggested
        : lessonsToShow;
    };
    setSuggestedLesson(suggestedLesson);
    setMoreLessons(getMoreLessons());

    let nanoPracticesToShow = nanoPractices.filter((practice) => {
      return practice.certRelations?.some((cert) => cert.certId === certId);
    });

    // get the list of nano-practices for the suggested refreshers which are sorted by incomplete lessons before the period
    // (so that the list stays sorted in a static order throughout the period)
    const nanoPracticesSortedByIncompleteBeforePeriod = [
      ...nanoPracticesToShow,
    ].sort(sortPracticesByCompletionDateBeforePeriod);

    // sort the nano-practices by their completion date
    nanoPracticesToShow.sort(sortPracticesByCompletionDate);

    const getSuggestedNanoPractices = () => {
      // Show 2 practices if there are lessons to show, otherwise show 4
      let numberOfPracticesToShow = 2;
      if (lessonsToShowSortedByIncompleteBeforePeriod.length <= 0) {
        numberOfPracticesToShow = 4;
      }
      return nanoPracticesSortedByIncompleteBeforePeriod?.length >
        numberOfPracticesToShow - 1
        ? nanoPracticesSortedByIncompleteBeforePeriod.slice(
            0,
            numberOfPracticesToShow
          )
        : nanoPracticesSortedByIncompleteBeforePeriod;
    };
    const suggestedNanoPractices = getSuggestedNanoPractices();

    // show the top two nano-practices as suggested and the rest under the more section
    // (or if there are < 6 show them all in more again)
    setSuggestedNanoPractices(suggestedNanoPractices);
    const getMoreNanoPractices = () => {
      const practicesWithoutSuggested = nanoPracticesToShow.filter(
        (p) => !suggestedNanoPractices.some((p2) => p.id === p2.id)
      );
      return practicesWithoutSuggested?.length >= 6
        ? practicesWithoutSuggested
        : nanoPracticesToShow;
    };
    setMoreNanoPractices(getMoreNanoPractices());
    setQualificationPractices(nanoPracticesToShow);
  }, [
    lessons,
    nanoPractices,
    slug,
    certData,
    certifications,
    setQualificationPractices,
    nanoPracticeInstances,
    suggestedLesson,
  ]);

  useEffect(() => {
    // only show the toast for certs that have newly earned points since the user last visited
    // previousPointsEarned is what we previously earned in local storage or fallback 0
    const pointsDataForNewlyEarned = certQualificationDataForPoints.filter(
      (c) =>
        c.pointsEarned > c.previousPointsEarned &&
        c.pointsEarned < c.pointsNeeded
    );
    if (isUninterruptibleModalOpen || pointsDataForNewlyEarned.length === 0)
      return;

    showEarnPointsToast(pointsDataForNewlyEarned, showToast, certifications);

    updateLocalCertificationQualificationScores();
  }, [certQualificationDataForPoints, isUninterruptibleModalOpen]);

  const getRecommendedRefreshersPanel = () => {
    return (
      <RecommendedRefreshersPanel
        isDesktop={isDesktop}
        lesson={suggestedLesson}
        nanoPractices={suggestedNanoPractices}
        startDate={certData?.startDate}
        from={"Cert Qualifications - Recommended Refreshers"}
      />
    );
  };

  const getQualificationProgressCard = () => {
    return (
      <>
        {certData && (
          <Box boxShadow={"lg"} marginBottom={15}>
            <QualificationProgressCard
              title={cert?.name as string}
              certQualificationData={certData}
            />
          </Box>
        )}
      </>
    );
  };

  interface LessonBlockDescriptionBlockProps {
    points: number;
    lessonType: string;
    toolTipText: string;
  }

  const getLessonBlockDescriptionText = (
    props: LessonBlockDescriptionBlockProps
  ) => {
    const { points, lessonType, toolTipText } = props;
    return (
      <Text fontSize={isDesktop ? "14px" : "13px"} mb={4}>
        {HVLocalizeStrings.EARN_POINTS_FOR_HEADER_TEXT.replace(
          "{0}",
          points.toString()
        )}
        <Text fontWeight="semibold" as="span">
          {`${lessonType.toLowerCase()}`}
        </Text>
        <ToolTip tooltipText={toolTipText} />{" "}
        {`${HVLocalizeStrings.EARN_POINTS_COMPLETE}`}
      </Text>
    );
  };

  if (loading) {
    return (
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        height={"100%"}
      >
        <HvSpinner />
      </Flex>
    );
  }

  return (
    <>
      {!lessonNotFound && certData?.certId && cert ? (
        <>
          <Helmet>
            <title>
              {getPageTitle(
                `${HVLocalizeStrings.STAY_QUALIFIED} - ${HVLocalizeStrings.HEADVERSITY}`
              )}
            </title>
          </Helmet>
          <HeaderSetter
            title={HVLocalizeStrings.STAY_QUALIFIED}
            description={
              HVLocalizeStrings.COMPLETE_REFRESHERS_MAINTAIN_CERTIFICATION
            }
            backgroundImage={CERT_HOME_BACKGROUND}
            backgroundImageBase={BG_CERT_BASE}
          />

          <StickyBreadcrumb
            crumbs={[{ url: "/cert", text: HVLocalizeStrings.SOLO_MENU_HOME }]}
            current={cert?.name as string}
          />

          <FadeAfterDelay>
            {!isDesktop && (
              <BlockSection fadeIn={true} columns={1}>
                {getQualificationProgressCard()}
              </BlockSection>
            )}

            <BlockSection
              fadeIn={true}
              title={
                <BlockSectionTitle
                  title={HVLocalizeStrings.SUGGESTED_REFRESHERS}
                  description={
                    HVLocalizeStrings.COMPLETE_REFRESHERS_FROM_THE_LIST_BELOW
                  }
                />
              }
              columns={1}
            >
              {isDesktop ? (
                <Flex direction={"row"}>
                  {getRecommendedRefreshersPanel()}
                  {getQualificationProgressCard()}
                </Flex>
              ) : (
                <>{getRecommendedRefreshersPanel()}</>
              )}
            </BlockSection>

            {moreNanoPractices && moreNanoPractices.length > 0 && (
              <BlockSection
                fadeIn={true}
                title={
                  <BlockSectionTitle
                    title={HVLocalizeStrings.MORE_NANO_PRACTICES}
                    description={
                      <>
                        {getLessonBlockDescriptionText({
                          toolTipText:
                            HVLocalizeStrings.DAILY_THREE_NANO_PRACTICE_DESCRIPTION,
                          points: 2,
                          lessonType: HVLocalizeStrings.NANO_PRACTICES,
                        })}
                      </>
                    }
                  />
                }
                columns={1}
              >
                <NanoPracticesScrollPanel
                  isDesktop={isDesktop}
                  startDate={certData?.startDate}
                  practices={moreNanoPractices}
                  showDescription={false}
                  from={"Cert Qualifications - More"}
                />
              </BlockSection>
            )}

            {moreLessons && moreLessons.length > 0 && (
              <BlockSection
                fadeIn={true}
                title={
                  <BlockSectionTitle
                    title={HVLocalizeStrings.MORE_MICROS_LESSONS}
                    description={
                      <>
                        {getLessonBlockDescriptionText({
                          toolTipText:
                            HVLocalizeStrings.DAILY_THREE_MICRO_LESSON_DESCRIPTION,
                          points: 3,
                          lessonType: HVLocalizeStrings.MICRO_LESSONS,
                        })}
                      </>
                    }
                  />
                }
                columns={1}
              >
                <LessonsScrollPanel
                  isDesktop={isDesktop}
                  lessonsToShow={moreLessons}
                  isWide={true}
                  fullDescription={false}
                  from={"Cert Qualifications - More"}
                />
              </BlockSection>
            )}

            {cert?.name &&
              certQualificationData.length > 0 &&
              !isUninterruptibleModalOpen && (
                <CertQualificationDoneModal
                  isOpen={certQualificationData.length > 0}
                  onClose={() => {
                    updateLocalCertificationQualificationScores();
                    track(EVENTS.CertQualificationDoneModalShown);
                  }}
                  name={cert?.name as string}
                />
              )}
          </FadeAfterDelay>
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};
