import { Box, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import {
  MODAL_CLOSE_BUTTON,
  MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { Fragment, useContext, useEffect, useState } from "react";
import { PollIcon } from "./PollIcon";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import Rise360Player, {
  FRAME_ID,
  GO_BACK,
  GO_FORWARD,
} from "../../Shared/Rise360/Rise360Player";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import BackButton from "../../Common/BackButton";
import { postMessageToIframe } from "../../../Utils/LessonsUtil";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  LessonCourseSlideInputDto,
  PathTeamLessonDto,
  PollQuestionDto,
  PollQuestionUserInstanceDto,
  TeamLessonSlideProgressDto,
  CharterActivity,
} from "@headversity/contract";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import dayjs from "dayjs";
import { Rise360LessonViewModel } from "../../Shared/Rise360/Rise360LessonViewModel";
import { ViewFactory } from "../../Sport/Charter/Presenter/ViewFactory";
import { isTeamLesson } from "../../../Utils/TeamUtil";

interface PresentationPresentViewBaseProps {
  pathTeamLesson: PathTeamLessonDto | undefined;
  pollQuestion: PollQuestionDto | undefined;
  pollInstance?: PollQuestionUserInstanceDto;
  pathId?: number;
  pollContent: JSX.Element;
  isPreview?: boolean;
  isCatchup?: boolean;
  hasPollStarted: (pollId: number) => boolean;
  onCurrentPollChanged: (poll: PollQuestionDto) => void;
  showPoll: (pollId: number) => boolean;
  onClose: () => void;
}

interface PresentationPresentRise360ViewProps
  extends PresentationPresentViewBaseProps {
  onLessonComplete: () => void;
  onLessonCompleteForParticpants?: () => void;
  viewedPolls: { [key: string]: boolean };
}

export const PresentationPresentRise360View = (
  props: PresentationPresentRise360ViewProps
) => {
  const {
    pathId,
    pollInstance,
    hasPollStarted,
    pathTeamLesson,
    isPreview,
    isCatchup,
    pollContent,
    pollQuestion,
    onCurrentPollChanged,
    onClose,
    showPoll,
    onLessonComplete,
    onLessonCompleteForParticpants,
    viewedPolls,
  } = props;

  const {
    pollQuestions,
    setPollInstanceToServer,
    currentTeam,
    notifySlideProgressToServer,
    currentApp,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [rise360Lesson, setRise360Lesson] = useState<
    Rise360LessonViewModel | undefined
  >();

  // TODO in the Js Engine there should be a post message that tells you which index
  //   you are on. This should be implement on the Js engine side....
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [wasPollShown, setWasPollShown] = useState<boolean>(false);
  const [queryStringParams] = useState([
    { name: "mode", value: currentApp === AppType.SPORT ? "sport" : "team" },
  ]);
  const [activity, setActivity] = useState<CharterActivity | undefined>(
    undefined
  );

  useEffect(() => {
    // for previews and catch-up, a lesson course user instance id does not exist
    let lessonCourseUserInstanceId: number | undefined;
    if (
      pathTeamLesson?.teamLesson?.lessonCourse &&
      !isPreview &&
      !isCatchup &&
      pathTeamLesson?.teamLessonUserInstance?.lessonCourseUserInstanceId
    ) {
      lessonCourseUserInstanceId =
        pathTeamLesson?.teamLessonUserInstance?.lessonCourseUserInstanceId;
    }

    if (
      pathTeamLesson?.teamLesson?.lessonCourse &&
      (lessonCourseUserInstanceId !==
        rise360Lesson?.lessonCourseUserInstanceId ||
        ((isPreview || isCatchup) &&
          pathTeamLesson.teamLesson.lessonCourse.startingUrl !==
            rise360Lesson?.startingUrl))
    ) {
      setRise360Lesson({
        lessonCourseUserInstanceId: lessonCourseUserInstanceId,
        startingUrl: pathTeamLesson.teamLesson.lessonCourse.startingUrl,
      });
    }
  }, [pathTeamLesson?.teamLesson?.lessonCourse]);

  useEffect(() => {
    // notify as we transition from poll to no poll
    if (pollQuestion) {
      // wait for next false
      setWasPollShown(true);
    } else {
      if (wasPollShown) {
        setWasPollShown(false);

        // TODO: post message should really be at the Rise360Player layer
        //  ugly but can be done with a "trigger" state variable https://timmousk.com/blog/react-call-function-in-child-component/
        postMessageToIframe(FRAME_ID, GO_FORWARD);
      }
    }
  }, [pollQuestion]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        align={"center"}
        justify={"start"}
        h={"100%"}
        data-testid={HVTestId.PresentationPresentStep.rise360Presentation}
      >
        {!activity && !pollQuestion && (
          <PresentationViewToolBar
            onClose={() => {
              if (onClose) {
                onClose();
              }
            }}
          >
            {showBackButton && (
              <Flex flex={"1"} justify={"start"}>
                <BackButton
                  isStaticPosition={true}
                  onButtonClick={() => {
                    // TODO: post message should really be at the Rise360Player layer
                    //  ugly but can be done with a "trigger" state variable https://timmousk.com/blog/react-call-function-in-child-component/
                    postMessageToIframe(FRAME_ID, GO_BACK);
                  }}
                />
                {isPreview && (
                  <Flex flex={"1"} justify={"start"}>
                    <Text
                      color={MODAL_PRIMARY_TEXT_COLOR}
                      fontWeight={"bold"}
                      data-testid={HVTestId.PresentationPresentStep.preview}
                    >
                      {HVLocalizeStrings.TEAM_TEAM_LESSON_PREVIEW_TITLE}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
            {isPreview && !showBackButton && (
              <Flex flex={"1"} justify={"start"}>
                <Text
                  color={MODAL_PRIMARY_TEXT_COLOR}
                  fontWeight={"bold"}
                  data-testid={HVTestId.PresentationPresentStep.preview}
                >
                  {HVLocalizeStrings.TEAM_TEAM_LESSON_PREVIEW_TITLE}
                </Text>
              </Flex>
            )}
            {isTeamLesson(pathTeamLesson?.teamLesson) && (
              <Text color={MODAL_PRIMARY_TEXT_COLOR} fontSize={"13px"}>
                {Object.keys(viewedPolls).length}/{pollQuestions.length}{" "}
                {(pollQuestions.length === 1
                  ? HVLocalizeStrings.TEAM_POLL
                  : HVLocalizeStrings.TEAM_POLLS
                ).toLowerCase()}{" "}
                {HVLocalizeStrings.COMPLETE.toLowerCase()}
              </Text>
            )}
          </PresentationViewToolBar>
        )}
        {activity && pathTeamLesson ? (
          <ViewFactory
            activity={activity}
            setActivity={setActivity}
            pathTeamLesson={pathTeamLesson}
          />
        ) : (
          <></>
        )}

        {rise360Lesson && pollQuestions && pollQuestions.length > 0 && (
          <Rise360Player
            rise360Lesson={rise360Lesson}
            queryStringParams={queryStringParams}
            lessonTitle={pathTeamLesson?.teamLesson?.name_loc?.en}
            onLessonComplete={onLessonComplete}
            onLessonCompleteForParticpants={onLessonCompleteForParticpants}
            onShouldShowBackButton={(value) => {
              setShowBackButton(value);
            }}
            onSlideProgress={(
              lessonCourseSlideInputDto: LessonCourseSlideInputDto
            ) => {
              if (
                !isPreview &&
                !isCatchup &&
                pathTeamLesson?.teamLessonUserInstanceId
              ) {
                notifySlideProgressToServer(
                  pathTeamLesson?.teamLessonUserInstanceId as number,
                  {
                    index: lessonCourseSlideInputDto.index,
                  } as TeamLessonSlideProgressDto
                );
              }
            }}
            display={
              pollQuestion || activity?.startsWith("Activity:")
                ? "none"
                : "unset"
            }
            onReachedPollSlide={(index: any) => {
              if (index < 0 || index >= pollQuestions.length) {
                return;
              }

              console.debug(
                `poll index, and currentPolls ${dayjs().utc().toString()}`
              );
              console.debug(index);
              console.debug(JSON.stringify(pollQuestions));

              const poll = pollQuestions[index];

              track(
                isPreview
                  ? TEAM_EVENTS.PreviewPresentationPoll
                  : TEAM_EVENTS.StartPresentationPoll,
                {
                  HV_Poll_Id: pollQuestion?.id,
                  HV_Poll_InternalTitle: pollQuestion?.question.questionText,
                  HV_Team_Id: currentTeam?.id,
                  HV_Team_Name: currentTeam?.name,
                  HV_Path_Id: pathId,
                }
              );

              // NOTE: the only case where we don't show the poll is in catch-up mode, if the team hasn't done it
              // in Rise360, we're removing the potential to end up in this state, but leaving this logic here just in case
              if (!showPoll(poll.id)) {
                // TODO: post message should really be at the Rise360Player layer
                //  ugly but can be done with a "trigger" state variable https://timmousk.com/blog/react-call-function-in-child-component/
                postMessageToIframe(FRAME_ID, GO_FORWARD);
                return;
              }

              // previewing, catching up, or presenting and poll has already started?
              if (isPreview || hasPollStarted(poll.id)) {
                onCurrentPollChanged(poll);
              }
              if (!isPreview && !isCatchup) {
                setPollInstanceToServer(
                  poll.id,
                  pathTeamLesson?.teamLessonUserInstanceId as number
                ).then(() => {
                  onCurrentPollChanged(poll);
                });
              }
            }}
            onReachedActivitySlide={(activity: CharterActivity) => {
              setActivity(activity);
            }}
          />
        )}
        {!activity && pollQuestion && (
          <PollView
            currentPoll={pollQuestion}
            currentPollInstance={pollInstance}
            isPreview={isPreview}
            pollContent={pollContent}
          />
        )}
      </Flex>
    </>
  );
};

interface PollViewProps {
  currentPoll: PollQuestionDto;
  currentPollInstance?: PollQuestionUserInstanceDto;
  isPreview: boolean | undefined;
  pollContent: JSX.Element;
}

const PollView = (props: PollViewProps) => {
  const { currentPollInstance, isPreview, pollContent, currentPoll } = props;

  return (
    <Box position="relative" w="100%" h="100%">
      <Flex w="100%" h="100%" flexDir="column" align="center" pt="10" gap="5">
        <BlockSectionTitle title={HVLocalizeStrings.TEAM_POLL_TIME} />
        <Text
          fontWeight="semibold"
          textAlign="center"
          color={MODAL_PRIMARY_TEXT_COLOR}
          w="60%"
          data-testid={HVTestId.PresentationPresentStep.pollQuestion}
        >
          {currentPoll?.question?.questionText as string}
        </Text>
        {(currentPollInstance || isPreview) && pollContent}
      </Flex>
    </Box>
  );
};

interface TeamLessonViewToolBarProps {
  onClose: () => void;
  children: any;
}

const PresentationViewToolBar = (props: TeamLessonViewToolBarProps) => {
  const { onClose, children } = props;
  return (
    <Flex
      flexDirection={"row"}
      gap={"2"}
      justify={"end"}
      align={"center"}
      w={"100%"}
      py={"2"}
    >
      {children}
      <IconButton
        bg={"transparent"}
        aria-label={"close"}
        icon={
          <Icon
            as={AiOutlineCloseCircle}
            w={"32px"}
            h={"32px"}
            color={MODAL_CLOSE_BUTTON}
          />
        }
        onClick={() => {
          if (onClose) {
            onClose();
          }
        }}
        data-testid={HVTestId.PresentationPresentStep.closeButton}
      />
    </Flex>
  );
};

interface PollListsProps {
  currentPolls: PollQuestionDto[];
  showPoll: (pollId: number) => boolean;
  showBoostColor: (itemId: number) => boolean | undefined;
  pollClickCallback: (pollQuestionDto: PollQuestionDto) => void;
}

const PollLists = (props: PollListsProps) => {
  const { currentPolls, showPoll, showBoostColor, pollClickCallback } = props;
  return (
    <>
      {currentPolls &&
        currentPolls.map((item) => (
          <Fragment key={item.id}>
            {showPoll(item.id) && (
              <IconButton
                bg={"transparent"}
                aria-label={"polls"}
                icon={
                  <PollIcon
                    poll={item}
                    isTransparent={showBoostColor(item.id)}
                  />
                }
                onClick={() => {
                  if (pollClickCallback) {
                    pollClickCallback(item);
                  }
                }}
                data-testid={HVTestId.PresentationGoogleSlides.pollButtons}
              />
            )}
          </Fragment>
        ))}
    </>
  );
};

// Google slide viewer; will be retired.
interface TeamLessonPresentViewGoogleSlideProps
  extends PresentationPresentViewBaseProps {
  showBoostColor: (itemId: number) => boolean | undefined;
}

export const PresentationPresentGoogleSlideView = (
  props: TeamLessonPresentViewGoogleSlideProps
) => {
  const { pollQuestions, setPollInstanceToServer, currentTeam, company } =
    useContext<IGlobalProvider>(GlobalContext);

  const { safeAreaInsets } = useContext<IShellProvider>(ShellContext);

  const {
    hasPollStarted,
    showBoostColor,
    pathId,
    onClose,
    pollInstance,
    pathTeamLesson,
    isPreview,
    pollContent,
    pollQuestion,
    onCurrentPollChanged,
    showPoll,
  } = props;

  const { currentTeamLessonUserInstanceId } =
    useContext<IGlobalProvider>(GlobalContext);

  const [teamLessonUrl, setTeamLessonUrl] = useState<string | null>(null);

  useEffect(() => {
    if (pathTeamLesson?.teamLesson) {
      let url = pathTeamLesson?.teamLesson.googleSlideUrl;
      setTeamLessonUrl(url as string);
    }
  }, [pathTeamLesson, company]);

  return (
    <>
      <Flex
        flexDirection={"column"}
        align={"center"}
        justify={"start"}
        h={"100%"}
        data-testid={HVTestId.PresentationGoogleSlides.presentation}
        pb={`${safeAreaInsets.bottom}px`}
      >
        {!pollQuestion && (
          <PresentationViewToolBar onClose={onClose}>
            {pollQuestions && (
              <>
                {isPreview && (
                  <Flex flex={"1"} justify={"start"}>
                    <Text
                      color={MODAL_PRIMARY_TEXT_COLOR}
                      fontWeight={"bold"}
                      data-testid={HVTestId.PresentationPresentStep.preview}
                    >
                      {HVLocalizeStrings.TEAM_TEAM_LESSON_PREVIEW_TITLE}
                    </Text>
                  </Flex>
                )}
                <PollLists
                  currentPolls={pollQuestions}
                  showPoll={showPoll}
                  showBoostColor={showBoostColor}
                  pollClickCallback={(item: PollQuestionDto) => {
                    const event = isPreview
                      ? TEAM_EVENTS.PreviewPresentationPoll
                      : TEAM_EVENTS.StartPresentationPoll;
                    track(event, {
                      HV_Poll_Id: item.id,
                      HV_Poll_InternalTitle: item.question.questionText,
                      HV_Team_Id: currentTeam?.id,
                      HV_Team_Name: currentTeam?.name,
                      HV_Path_Id: pathId,
                    });
                    if (isPreview || hasPollStarted(item.id)) {
                      onCurrentPollChanged(item);
                      return;
                    }
                    if (!isPreview) {
                      setPollInstanceToServer(
                        item.id,
                        currentTeamLessonUserInstanceId as number
                      ).then(() => {
                        onCurrentPollChanged(item);
                      });
                    }
                  }}
                />
              </>
            )}
          </PresentationViewToolBar>
        )}
        {teamLessonUrl && pollQuestions && pollQuestions.length > 0 && (
          <iframe
            src={teamLessonUrl as string}
            style={{
              width: "100%",
              height: "100%",
              display: pollQuestion ? "none" : "unset",
            }}
          ></iframe>
        )}
        {pollQuestion && (
          <PollView
            currentPoll={pollQuestion}
            currentPollInstance={pollInstance}
            isPreview={isPreview}
            pollContent={pollContent}
          />
        )}
      </Flex>
    </>
  );
};
