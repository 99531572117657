import { useCallback, useEffect, useState } from "react";
import { getKey } from "../../Utils/Helpers";
import {
  deleteTeam,
  deleteTeamUser,
  getCertsCompletedByTeam,
  getTeams,
  inviteNewTeamUser,
  joinTeam,
  resendTeamUserActivationEmail,
  saveTeam,
  saveTeamUser,
} from "../../Api/Team/TeamApi";
import { getPaths } from "../../Api/Team/PathsApi";
import {
  getTeamLessonUserInstanceParticipants,
  notifySlideProgress,
  removeSelfForTeamLessonParticipation,
  setParticipateForTeamLessonUserInstance,
  setParticipateGroupingForTeamLessonUserInstance,
  setTeamLessonInProgressStep,
  setTeamLessonUserInstanceCancel,
  setTeamLessonUserInstanceEnd,
  setTeamLessonUserInstanceEndForParticipants,
  setTeamLessonUserInstanceForceEnd,
  setTeamLessonUserInstanceInitialize,
  setTeamLessonUserInstanceInitilizeScheduled,
  setTeamLessonUserInstanceInProgress,
  setTeamLessonUserInstanceSchedule,
  submitTeamLessonUserInstanceRating,
  updateTeamLessonUserInstanceSchedule,
} from "../../Api/Team/TeamLessonUserInstanceApi";
import { Channel } from "pusher-js";
import { IPollProvider, usePoll } from "./usePoll";
import { retryWithDelay } from "../../Api/Utils";
import { Dictionary } from "highcharts";
import {
  CharterActivity,
  CharterDataDto,
  InviteNewTeamUserDto,
  PathDto,
  PathTeamLessonDto,
  TeamDto,
  TeamLessonInstanceInputDto,
  TeamLessonSlideProgressDto,
  TeamLessonUserInstanceDto,
  TeamLessonUserInstanceGroupingDto,
  TeamLessonUserInstanceParticipantDto,
  TeamLessonUserInstanceStatus,
} from "@headversity/contract";
import { AxiosResponse } from "axios";
import { generateErrorFromBackend } from "../../Components/People/Shared/ErrorCodes";
import { CertsCompletedByTeamDto } from "@headversity/contract/Dto/CertsCompletedByTeamDto";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { ConfirmationButtons } from "../../Components/Common/ConfirmationDialog";
import {
  TEAM_LESSON_INITIALIZE_STEP,
  TEAM_LESSON_PROGRESS_STEP,
} from "../../Components/Team/Presentation/PresentationModal";
import { trackTeamLesson } from "../../Utils/TeamUtil";
import { TEAM_EVENTS } from "../../Utils/Analytics";

const HV_TEAM_SELECTION = "HV_TEAM_SELECTION";

export interface ITeamProvider extends IPollProvider {
  paths: Dictionary<PathDto[]>;
  setPaths: (paths: Dictionary<PathDto[]>) => void;
  teams: TeamDto[];
  currentTeam: TeamDto | undefined;
  currentParticipantList: TeamLessonUserInstanceParticipantDto[] | undefined;
  pusherTeamChannelNames: string[] | null;
  pusherTeamChannels: Channel[] | null;
  participantModalOpen: boolean;
  presenterModalOpen: boolean;
  previewModalOpen: boolean;
  currentTeamLessonUserInstanceId: number | undefined;
  presenterPathTeamLesson: PathTeamLessonDto | undefined;
  currentPathTeamLessonPreview: PathTeamLessonDto | null;
  teamDataLoading: boolean;
  teamInitializing: boolean;
  isCatchupDone: boolean;
  presenterUsername: string;
  participantPathTeamLesson: PathTeamLessonDto | undefined;
  teamLessonUrl: string | null;
  currentSlideIndex: number;
  certsCompletedByTeam: CertsCompletedByTeamDto | undefined;
  presentationStep: string | undefined;
  setPresentationStep: (presentationStep: string) => void;
  getTeamsFromServer: (teamCode?: string) => Promise<TeamDto[]>;
  getPathsFromServer: () => Promise<Dictionary<PathDto[]>>;
  getCertsCompletedByTeamFromServer: (
    teamId: number
  ) => Promise<CertsCompletedByTeamDto>;
  getTeamLessonUserInstanceParticipantsFromServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceParticipantDto[]>;
  setTeamLessonUserInstanceInitializeToServer: (
    pathTeamLessonId: number,
    teamId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceScheduleToServer: (
    pathTeamLessonId: number,
    teamId: number,
    teamLessonInstanceInput?: TeamLessonInstanceInputDto,
    teamLessonUserInstanceId?: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceInitializeScheduledToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceCancelToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceInProgressToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonInProgressStepToServer: (
    teamLessonUserInstanceId: number,
    step: string
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceEndToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceEndForParticipantsToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setTeamLessonUserInstanceForceEndToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceDto>;
  setParticipateForTeamLessonUserInstanceToServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceParticipantDto>;
  setParticipateGroupingForTeamLessonUserInstanceToServer: (
    teamLessonUserInstanceId: number,
    teamLessonUserInstanceGroupingDto: TeamLessonUserInstanceGroupingDto
  ) => Promise<void>;
  removeSelfForTeamLessonParticipationOnServer: (
    teamLessonUserInstanceId: number
  ) => Promise<TeamLessonUserInstanceParticipantDto>;
  setCurrentTeamLessonUserInstanceId: (
    currentTeamLessonUserInstanceId: number | undefined
  ) => void;
  setCurrentTeam: (team: TeamDto | undefined) => void;
  setPusherTeamChannels: (pusherTeamChannels: Channel[] | null) => void;
  setPusherTeamChannelNames: (pusherTeamChannelNames: string[] | null) => void;
  reloadPathsForAllTeamsFromServer: () => Promise<
    AxiosResponse<Dictionary<PathDto[]>>
  >;
  setParticipantModalOpen: (participantModalOpen: boolean) => void;
  setPresenterModalOpen: (presenterModalOpen: boolean) => void;
  setPreviewModalOpen: (previewModalOpen: boolean) => void;
  setCurrentPathTeamLessonPreview: (
    currentPathTeamLessonPreview: PathTeamLessonDto | null
  ) => void;
  setPresenterPathTeamLesson: (
    presenterPathTeamLesson: PathTeamLessonDto | undefined
  ) => void;
  saveTeamToServer: (teamName: string, teamId?: number) => Promise<TeamDto[]>;
  joinTeamToServer: (teamCode: string) => Promise<void>;
  saveTeamUserToServer: (email: string, teamId?: number) => Promise<void>;
  saveNewTeamUserToServer: (
    teamId: number,
    data: InviteNewTeamUserDto
  ) => Promise<void>;
  resendMemberActivationEmail: (
    teamId: number,
    userId: number
  ) => Promise<void>;
  deleteTeamUserFromServer: (teamId: number, userId: number) => Promise<void>;
  deleteTeamFromServer: (teamId: number) => Promise<void>;
  setTeamDataLoading: (teamDataLoading: boolean) => void;
  setTeamInitializing: (teamInitializing: boolean) => void;
  submitTeamLessonUserInstanceRatingToServer: (
    teamLessonUserInstanceId: number,
    motivatingRating: number,
    relevantRating: number
  ) => Promise<TeamLessonUserInstanceParticipantDto>;
  setIsCatchupDone: (isCatchupDone: boolean) => void;
  setPresenterUsername: (presenterUsername: string) => void;
  updateParticipantTeamLesson: (
    pathTeamLesson: PathTeamLessonDto | null
  ) => void;
  setTeamLessonUrl: (teamLessonUrl: string | null) => void;
  setCurrentSlideIndex: (currentSlideIndex: number) => void;
  resetTeamSession: () => void;
  notifySlideProgressToServer: (
    teamLessonUserInstanceId: number,
    teamLessonSlideProgressDto: TeamLessonSlideProgressDto
  ) => Promise<AxiosResponse<TeamLessonSlideProgressDto>>;
  isFirstTimeTeamInterstitialModalOpen: boolean;
  setIsFirstTimeTeamInterstitialModalOpen: (
    isFirstTimeTeamInterstitialModalOpen: boolean
  ) => void;
  setSelectedTeamLessonUrl: (selectedTeamLessonUrl: string) => void;
  selectedTeamLessonUrl: string;
  teamLessonFeedbackObtained: boolean;
  setTeamLessonFeedbackObtained: (teamLessonFeedbackObtained: boolean) => void;
  updateTeamLessonUserInstanceInProgressStep: (
    activity: CharterActivity | "",
    teamLessonUserInstanceId: number,
    paths: Dictionary<PathDto[]>
  ) => void;
  startPresentOrParticipate: (
    pathTeamLesson: PathTeamLessonDto,
    confirmationDialogRef: any,
    setCurrentStep: (currentStep: string) => void,
    closeModal: () => void,
    showCloseButton?: boolean
  ) => void;
}

export const useTeamGlobal = (): ITeamProvider => {
  const pollVariables = usePoll();

  const [paths, setPaths] = useState<Dictionary<PathDto[]>>({});
  const [currentTeam, setCurrentTeamInner] = useState<TeamDto | undefined>();
  const [teams, setTeams] = useState<TeamDto[]>([]);
  const [teamDataLoading, setTeamDataLoading] = useState<boolean>(false);
  const [isCatchupDone, setIsCatchupDone] = useState<boolean>(false);
  const [presentationStep, setPresentationStep] = useState<string>();

  const [currentTeamLessonUserInstanceId, setCurrentTeamLessonUserInstanceId] =
    useState<number | undefined>();

  const [currentParticipantList, setCurrentParticipantList] = useState<
    TeamLessonUserInstanceParticipantDto[] | undefined
  >(undefined);

  const [pusherTeamChannels, setPusherTeamChannels] = useState<
    Channel[] | null
  >(null);

  const [pusherTeamChannelNames, setPusherTeamChannelNames] = useState<
    string[] | null
  >(null);

  const [participantModalOpen, setParticipantModalOpen] = useState(false);

  const [presenterModalOpen, setPresenterModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [currentPathTeamLessonPreview, setCurrentPathTeamLessonPreview] =
    useState<PathTeamLessonDto | null>(null);
  const [presenterPathTeamLesson, setPresenterPathTeamLesson] = useState<
    PathTeamLessonDto | undefined
  >();
  const [teamInitializing, setTeamInitializing] = useState<boolean>(false);
  const [participantPathTeamLesson, setParticipantPathTeamLesson] = useState<
    PathTeamLessonDto | undefined
  >();
  const [presenterUsername, setPresenterUsername] = useState<string>("");
  const [teamLessonUrl, setTeamLessonUrl] = useState<string | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(-1);

  const [selectedTeamLessonUrl, setSelectedTeamLessonUrl] =
    useState<string>("");

  const [teamLessonFeedbackObtained, setTeamLessonFeedbackObtained] =
    useState<boolean>(false);

  const [
    isFirstTimeTeamInterstitialModalOpen,
    setIsFirstTimeTeamInterstitialModalOpen,
  ] = useState<boolean>(false);

  const [certsCompletedByTeam, setCertsCompletedByTeam] =
    useState<CertsCompletedByTeamDto>();

  const updateParticipantTeamLesson = (
    pathTeamLesson: PathTeamLessonDto | null
  ) => {
    setParticipantPathTeamLesson(pathTeamLesson || undefined);
    if (pathTeamLesson?.presenterUserFullName) {
      setPresenterUsername(pathTeamLesson?.presenterUserFullName);
    }
  };

  const setTeamLessonUserInstanceInitializeToServer = useCallback(
    async (pathTeamLessonId: number, teamId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceInitialize(
        pathTeamLessonId,
        teamId,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        setCurrentTeamLessonUserInstanceId(result.data.id);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceScheduleToServer = useCallback(
    async (
      pathTeamLessonId: number,
      teamId: number,
      teamLessonInstanceInput?: TeamLessonInstanceInputDto,
      teamLessonUserInstanceId?: number
    ) => {
      setTeamDataLoading(true);

      if (teamLessonUserInstanceId) {
        // update existing
        return updateTeamLessonUserInstanceSchedule(
          teamLessonUserInstanceId,
          teamLessonInstanceInput ?? {},
          getKey()
        ).then((result) => {
          setTeamDataLoading(false);
          getPathsFromServer();
          return Promise.resolve(result.data);
        });
      }
      // new schedule
      return setTeamLessonUserInstanceSchedule(
        pathTeamLessonId,
        teamId,
        teamLessonInstanceInput ?? {},
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        getPathsFromServer();
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceInitializeScheduledToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceInitilizeScheduled(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        setCurrentTeamLessonUserInstanceId(result.data.id);
        getPathsFromServer();
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceInProgressToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceInProgress(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonInProgressStepToServer = useCallback(
    async (teamLessonUserInstanceId: number, step: string) => {
      // no loading set on purpose.
      return setTeamLessonInProgressStep(
        teamLessonUserInstanceId,
        step,
        getKey()
      ).then((result) => {
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceEndToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceEnd(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        getTeamsFromServer();
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceEndForParticipantsToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceEndForParticipants(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        getTeamsFromServer();
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceForceEndToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceForceEnd(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        getTeamsFromServer();
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setTeamLessonUserInstanceCancelToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setTeamLessonUserInstanceCancel(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        getTeamsFromServer();
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const submitTeamLessonUserInstanceRatingToServer = useCallback(
    async (
      teamLessonUserInstanceId: number,
      motivatingRating: number,
      relevantRating: number
    ) => {
      setTeamDataLoading(true);
      return submitTeamLessonUserInstanceRating(
        teamLessonUserInstanceId,
        motivatingRating,
        relevantRating,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setCurrentTeam = useCallback((team: TeamDto | undefined) => {
    if (team) {
      localStorage.setItem("HV_TEAM_SELECTION", team.id.toString());
    }
    setCurrentTeamInner(team);
  }, []);

  const getTeamsFromServer = useCallback(async (teamCode?: string) => {
    // this will get call, only buyer will get anything from this.
    setTeamDataLoading(true);

    return getTeams(getKey()).then((result) => {
      setTeamDataLoading(false);
      setTeams(result.data);
      if (teamCode) {
        const teams = result?.data?.filter((item) => item.code === teamCode);
        if (teams.length > 0) {
          setCurrentTeam(teams[0]);
        }
      }
      return Promise.resolve(result.data);
    });
  }, []);

  const getCertsCompletedByTeamFromServer = useCallback(
    async (teamId: number) => {
      setTeamDataLoading(true);

      return getCertsCompletedByTeam(teamId, getKey())
        .then((result) => {
          setCertsCompletedByTeam(result.data);
          return Promise.resolve(result.data);
        })
        .finally(() => {
          setTeamDataLoading(false);
        });
    },
    []
  );

  const getPathsFromServer = useCallback(async () => {
    setTeamDataLoading(true);
    return getPaths(getKey()).then((result) => {
      setTeamDataLoading(false);
      setPaths(result.data);
      return Promise.resolve(result.data);
    });
  }, []);

  const reloadPathsForAllTeamsFromServer = useCallback(async () => {
    if (teams && teams.length > 0) {
      setTeamDataLoading(true);
      return retryWithDelay(
        () => {
          return getPaths(getKey()).then((result) => {
            setTeamDataLoading(false);
            setPaths(result.data);
            (window as any).globalPathData = result.data;
            Promise.resolve(result);
          });
        },
        20,
        1500,
        (errorMessage: string | null) => {
          return errorMessage === "Network Error";
        }
      );
    }
  }, [teams]);

  const setParticipateGroupingForTeamLessonUserInstanceToServer = useCallback(
    async (
      teamLessonUserInstanceId: number,
      teamLessonUserInstanceGroupingDto: TeamLessonUserInstanceGroupingDto
    ) => {
      setTeamDataLoading(true);
      return setParticipateGroupingForTeamLessonUserInstance(
        teamLessonUserInstanceId,
        teamLessonUserInstanceGroupingDto,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const getTeamLessonUserInstanceParticipantsFromServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return getTeamLessonUserInstanceParticipants(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        const participantList = result.data.sort((a, b) => {
          if (a.user && b.user) {
            return a.user.fullName.localeCompare(b.user.fullName);
          }
          return 0;
        });
        setCurrentParticipantList(participantList);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setParticipateForTeamLessonUserInstanceToServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return setParticipateForTeamLessonUserInstance(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        result.data.slideIndexAtJoinTime !== undefined &&
          setCurrentSlideIndex(result.data.slideIndexAtJoinTime);
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const removeSelfForTeamLessonParticipationOnServer = useCallback(
    async (teamLessonUserInstanceId: number) => {
      setTeamDataLoading(true);
      return removeSelfForTeamLessonParticipation(
        teamLessonUserInstanceId,
        getKey()
      ).then((result) => {
        setTeamDataLoading(false);
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const saveTeamToServer = useCallback(
    async (teamName: string, teamId: number = 0): Promise<TeamDto[]> => {
      setTeamDataLoading(true);
      return saveTeam(getKey(), {
        code: "",
        teamUsers: [],
        name: teamName,
        id: teamId,
        logo: "",
      }).then((response) => {
        setTeamDataLoading(false);
        return getTeamsFromServer();
      });
    },
    []
  );

  const saveTeamUserToServer = useCallback(
    async (email: string, teamId: number = 0): Promise<void> => {
      setTeamDataLoading(true);
      await saveTeamUser(getKey(), {
        teamId: teamId,
        email: email,
      }).finally(() => {
        setTeamDataLoading(false);
        return getTeamsFromServer();
      });
    },
    []
  );

  const saveNewTeamUserToServer = useCallback(
    async (teamId: number, data: InviteNewTeamUserDto): Promise<void> => {
      setTeamDataLoading(true);
      await inviteNewTeamUser(getKey(), teamId, data).finally(() => {
        setTeamDataLoading(false);
        return getTeamsFromServer();
      });
    },
    []
  );

  const resendMemberActivationEmail = useCallback(
    async (teamId: number, userId: number): Promise<void> => {
      await resendTeamUserActivationEmail(getKey(), teamId, userId);
    },
    []
  );
  const deleteTeamUserFromServer = useCallback(
    async (teamId: number, userId: number) => {
      setTeamDataLoading(true);
      return deleteTeamUser(teamId, userId, getKey()).then((result) => {
        setTeamDataLoading(false);
        if (result.data !== null && result.data !== "" && result.data >= 0) {
          const error = generateErrorFromBackend(result.data as number, null);
          return Promise.reject(error);
        } else {
          getTeamsFromServer();
          return Promise.resolve();
        }
      });
    },
    []
  );

  const deleteTeamFromServer = useCallback(async (teamId: number) => {
    setTeamDataLoading(true);
    return deleteTeam(teamId, getKey()).then(() => {
      getTeamsFromServer().then(() => {
        setTeamDataLoading(false);
        reloadPathsForAllTeamsFromServer();
      });
      return Promise.resolve();
    });
  }, []);

  const joinTeamToServer = useCallback(async (teamCode: string) => {
    setTeamDataLoading(true);
    return joinTeam(getKey(), teamCode).then((result) => {
      setTeamDataLoading(false);
      if (
        result?.data !== null &&
        typeof result?.data !== "object" &&
        result?.data >= 0
      ) {
        const error = generateErrorFromBackend(result.data as number, null);
        return Promise.reject(error);
      } else {
        getTeamsFromServer(teamCode);
        return Promise.resolve();
      }
    });
  }, []);

  const updateTeamLessonUserInstanceInProgressStep = useCallback(
    (
      step: CharterActivity | "",
      teamLessonUserInstanceId: number,
      paths: Dictionary<PathDto[]>
    ) => {
      if (paths) {
        const keys = Object.keys(paths);
        for (const key of keys) {
          for (const path of paths[key]) {
            for (const pathTeamLesson of path.pathTeamLessons) {
              if (
                pathTeamLesson.teamLessonUserInstanceId ===
                teamLessonUserInstanceId
              ) {
                pathTeamLesson.inProgressStep = step;
                const newPathTeamLesson = { ...pathTeamLesson };
                if (newPathTeamLesson.team) {
                  newPathTeamLesson.team = {
                    ...newPathTeamLesson.team,
                  } as TeamDto;
                }
                if (newPathTeamLesson.teamLessonUserInstance) {
                  newPathTeamLesson.teamLessonUserInstance = {
                    ...newPathTeamLesson.teamLessonUserInstance,
                  } as TeamLessonUserInstanceDto;
                }

                if (newPathTeamLesson.charterData) {
                  newPathTeamLesson.charterData = {
                    ...newPathTeamLesson.charterData,
                  } as CharterDataDto;
                }
                const index = path.pathTeamLessons.indexOf(pathTeamLesson);
                path.pathTeamLessons[index] = newPathTeamLesson;
                setPaths({ ...paths });
                return;
              }
            }
          }
        }
      }
    },
    []
  );

  const notifySlideProgressToServer = useCallback(
    async (
      teamLessonUserInstanceId: number,
      teamLessonSlideProgressDto: TeamLessonSlideProgressDto
    ) => {
      return notifySlideProgress(
        teamLessonUserInstanceId,
        teamLessonSlideProgressDto,
        getKey()
      );
    },
    []
  );

  const resetTeamSession = useCallback(() => {
    pollVariables.clearCurrentPollsAndSessions();
    setCurrentSlideIndex(-1);
  }, []);

  useEffect(() => {
    if (teams) {
      if (teams?.length > 0) {
        const teamIds: number[] = [];
        teams.forEach((item) => {
          teamIds.push(item.id);
        });
        getPathsFromServer();
        if (currentTeam === undefined) {
          const teamId = localStorage.getItem(HV_TEAM_SELECTION);
          const teamFromLocalstorage = teams.filter(
            (item) => item.id.toString() == teamId
          );
          setCurrentTeam(
            teamFromLocalstorage.length > 0 ? teamFromLocalstorage[0] : teams[0]
          );
        } else if (currentTeam && teamIds.indexOf(currentTeam.id) === -1) {
          setCurrentTeam(teams[0]);
        }
      } else {
        setCurrentTeam(undefined);
      }
    }
  }, [teams]);

  const startPresentOrParticipate = useCallback(
    (
      pathTeamLesson: PathTeamLessonDto,
      confirmationDialogRef: any,
      setCurrentStep: (currentStep: string) => void,
      closeModal: () => void,
      showCloseButton: boolean = false
    ) => {
      setCurrentTeamLessonUserInstanceId(
        pathTeamLesson.teamLessonUserInstanceId
      );
      confirmationDialogRef.current.confirmation(
        HVLocalizeStrings.TEAM_PRESENT_OR_PARTICIPATE,
        HVLocalizeStrings.TEAM_PRESENT_OR_PARTICIPATE_TEXT,
        ConfirmationButtons.Customs,
        (button: string) => {
          if (button === HVLocalizeStrings.TEAM_BUTTON_PRESENT) {
            setCurrentStep(
              pathTeamLesson.teamLessonInstanceStatus ===
                TeamLessonUserInstanceStatus.Initialized
                ? TEAM_LESSON_INITIALIZE_STEP
                : TEAM_LESSON_PROGRESS_STEP
            );
            trackTeamLesson(
              TEAM_EVENTS.StartPresentation,
              currentTeam,
              pathTeamLesson
            );
          } else if (button === HVLocalizeStrings.TEAM_BUTTON_PARTICIPATE) {
            setParticipateForTeamLessonUserInstanceToServer(
              pathTeamLesson.teamLessonUserInstanceId as number
            ).then(() => {
              setParticipantModalOpen(true);
              updateParticipantTeamLesson(pathTeamLesson);
              trackTeamLesson(
                TEAM_EVENTS.ParticipatePresentation,
                currentTeam,
                pathTeamLesson
              );
              closeModal();
            });
          }
        },
        [
          HVLocalizeStrings.TEAM_BUTTON_PARTICIPATE,
          HVLocalizeStrings.TEAM_BUTTON_PRESENT,
        ],
        showCloseButton
      );
    },
    [
      currentTeam,
      trackTeamLesson,
      updateParticipantTeamLesson,
      setParticipateForTeamLessonUserInstanceToServer,
      setCurrentTeamLessonUserInstanceId,
      setParticipantModalOpen,
    ]
  );

  return {
    getTeamsFromServer,
    getCertsCompletedByTeamFromServer,
    getPathsFromServer,
    paths,
    setPaths,
    teams,
    currentTeam,
    currentParticipantList,
    pusherTeamChannels,
    pusherTeamChannelNames,
    participantModalOpen,
    currentTeamLessonUserInstanceId,
    currentPathTeamLessonPreview,
    presentationStep,
    setPresentationStep,
    setParticipateGroupingForTeamLessonUserInstanceToServer,
    getTeamLessonUserInstanceParticipantsFromServer,
    setTeamLessonUserInstanceInitializeToServer,
    setTeamLessonUserInstanceScheduleToServer,
    setTeamLessonUserInstanceInitializeScheduledToServer,
    setTeamLessonUserInstanceCancelToServer,
    setTeamLessonUserInstanceInProgressToServer,
    setTeamLessonInProgressStepToServer,
    setTeamLessonUserInstanceEndToServer,
    setTeamLessonUserInstanceForceEndToServer,
    setTeamLessonUserInstanceEndForParticipantsToServer,
    setParticipateForTeamLessonUserInstanceToServer,
    removeSelfForTeamLessonParticipationOnServer,
    setCurrentTeam,
    setCurrentTeamLessonUserInstanceId,
    setPusherTeamChannels,
    setPusherTeamChannelNames,
    setParticipantModalOpen,
    setCurrentPathTeamLessonPreview,
    reloadPathsForAllTeamsFromServer,
    saveTeamToServer,
    saveTeamUserToServer,
    saveNewTeamUserToServer,
    resendMemberActivationEmail,
    deleteTeamUserFromServer,
    deleteTeamFromServer,
    joinTeamToServer,
    teamDataLoading,
    setTeamDataLoading,
    teamInitializing,
    setTeamInitializing,
    submitTeamLessonUserInstanceRatingToServer,
    presenterModalOpen,
    setPresenterModalOpen,
    previewModalOpen,
    setPreviewModalOpen,
    presenterPathTeamLesson,
    setPresenterPathTeamLesson,
    isCatchupDone,
    setIsCatchupDone,
    participantPathTeamLesson,
    presenterUsername,
    setPresenterUsername,
    updateParticipantTeamLesson,
    teamLessonUrl,
    setTeamLessonUrl,
    notifySlideProgressToServer,
    currentSlideIndex,
    setCurrentSlideIndex,
    isFirstTimeTeamInterstitialModalOpen,
    setIsFirstTimeTeamInterstitialModalOpen,
    selectedTeamLessonUrl,
    setSelectedTeamLessonUrl,
    teamLessonFeedbackObtained,
    setTeamLessonFeedbackObtained,
    resetTeamSession,
    updateTeamLessonUserInstanceInProgressStep,
    certsCompletedByTeam,
    startPresentOrParticipate,
    ...pollVariables,
  };
};
