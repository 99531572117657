import React, { memo, useContext, useState } from "react";
import { useBreakpointValue, Box, Text, Flex, HStack } from "@chakra-ui/react";
import {
  BLOCK_SECTION_PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR,
  CONTRAST_TEXT_COLOR,
  Equity_Orange,
  Equity_Orange_40,
  Mid_Blue,
  Mid_Blue_80,
  Equity_Purple,
  Equity_Purple_80,
} from "../../../Styles/HeadversityStyle";
import {
  Scheduler,
  MonthView,
  SchedulerItem,
  SchedulerSlot,
} from "@progress/kendo-react-scheduler";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";

import {
  FiMail,
  FiPrinter,
  FiSmartphone,
  FiMoreHorizontal,
} from "react-icons/fi";
import dayjs from "dayjs";
import styled from "styled-components";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { getAssetTypeIcon } from "../../../Utils/ReachUtils";
import { REACH_EVENTS, track } from "../../../Utils/Analytics";
import { PromotionalMaterialDto, ReachAssetType } from "@headversity/contract";
import { useNavigate } from "react-router-dom";

const HVSchedulerDiv = styled.div`
  .k-widget.k-toolbar.k-scheduler-footer,
  .k-button-group.k-scheduler-views,
  .k-spacer {
    display: none !important;
  }
  .k-widget.k-scheduler.k-floatwrap {
    height: auto !important;
    border-radius: 8px !important;
    overflow: hidden;
  }

  .k-widget.k-toolbar.k-scheduler-toolbar {
    justify-content: center !important;
    background-color: #f5f5f5 !important;
    padding-top: 14px !important;
    padding-bottom: 14px !important;
    border-bottom-color: #afafaf !important;
    border-bottom-width: 2px !important;
  }

  .k-button-group.k-scheduler-navigation .k-button {
    background-color: #ebebeb !important;
    border-color: #ebebeb !important;
  }

  .k-scheduler-cell.k-slot-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .k-scheduler-body .k-link.k-nav-day {
    margin-left: auto !important;
    padding-right: 8px;
  }

  .hv-opacity-date {
    .k-nav-day {
      opacity: 0.2;
    }
  }
`;

const ReachPromoteCalendarView = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { setPromoMaterialModalOpen, setSelectedPromoMaterial } =
    useContext<IGlobalProvider>(GlobalContext);

  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  return (
    <>
      <ReachScheduler
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        setReachPromoModalOpen={setPromoMaterialModalOpen}
        setReachPromo={setSelectedPromoMaterial}
      ></ReachScheduler>
      <Flex
        color={BLOCK_SECTION_PRIMARY_TEXT_COLOR}
        direction={isDesktop ? "row" : "column"}
        justify={"center"}
        gap={isDesktop ? 10 : 4}
        py={10}
        fontSize={isDesktop ? 16 : 14}
      >
        <HStack>
          <FiMail />
          <Text>{HVLocalizeStrings.REACH_EMAIL_TEMPLATE}</Text>
        </HStack>
        <HStack>
          <FiPrinter />
          <Text>{HVLocalizeStrings.REACH_POSTER}</Text>
        </HStack>
        <HStack>
          <FiSmartphone />
          <Text>{HVLocalizeStrings.REACH_SOCIAL_MEDIA_POST}</Text>
        </HStack>
        <HStack>
          <FiMoreHorizontal />
          <Text>{HVLocalizeStrings.OTHER}</Text>
        </HStack>
      </Flex>
    </>
  );
};

const ReachScheduler = memo(
  (props: any) => {
    const {
      currentDate,
      setCurrentDate,
      setReachPromoModalOpen,
      setReachPromo,
    } = props;
    const {
      promoMaterials,
      getPromoMaterialAssetsFromServer,
      selectedUserLanguage,
      selectedUserLocale,
      adminProductTypes,
    } = useContext<IGlobalProvider>(GlobalContext);

    const schedulerPromoMaterials = promoMaterials
      .filter((promoMaterial) => promoMaterial.startDate)
      .filter((pm) => {
        return pm.productTypes?.some((productType) =>
          adminProductTypes.includes(productType)
        );
      })
      .map((promoMaterial) => ({
        id: promoMaterial.id,
        title: promoMaterial.name,
        start: dayjs
          .utc(promoMaterial?.startDate?.toString())
          .local()
          .startOf("day")
          .toDate(),
        end: dayjs
          .utc(promoMaterial?.endDate?.toString())
          .local()
          .endOf("day")
          .toDate(),
        item: promoMaterial,
        assetType: promoMaterial.assetType,
      }));

    const dayHeaderCell = (props: any) => {
      const { date } = props;
      const shortWeekday = date
        .toLocaleDateString(selectedUserLocale, {
          weekday: "short",
        })
        .replace(/\.$/, "");
      return (
        // @ts-ignore
        <div date={date} className={"k-scheduler-cell k-heading-cell"}>
          <span className={"k-link k-nav-day"}>{shortWeekday}</span>
        </div>
      );
    };

    return (
      <HVSchedulerDiv>
        <LocalizationProvider language={selectedUserLocale}>
          <IntlProvider locale={selectedUserLanguage}>
            <Scheduler
              onDateChange={(e) => {
                setCurrentDate(e.value);
                track(REACH_EVENTS.CalendarMonthChange, {
                  HV_Reach_CalendarMonth: e.value.getMonth(),
                  HV_Reach_CalendarYear: e.value.getFullYear(),
                });
              }}
              item={(props) => {
                return (
                  <CustomItem
                    key={props.id}
                    setReachPromoModalOpen={setReachPromoModalOpen}
                    setReachPromo={setReachPromo}
                    getPromoMaterialAssetsFromServer={
                      getPromoMaterialAssetsFromServer
                    }
                    reachPromoMaterials={promoMaterials}
                    {...props}
                  />
                );
              }}
              data={schedulerPromoMaterials}
            >
              <MonthView
                itemsPerSlot={5}
                slot={(props: any) => {
                  const opacityClassName =
                    dayjs(currentDate).startOf("day").month() ===
                      dayjs(props.start).month() &&
                    dayjs(currentDate).startOf("day").year() ===
                      dayjs(props.start).year()
                      ? ""
                      : "hv-opacity-date";
                  return (
                    <SchedulerSlot
                      key={props.id}
                      className={opacityClassName}
                      {...props}
                    >
                      {props.children}
                    </SchedulerSlot>
                  );
                }}
                dateHeaderCell={dayHeaderCell}
              />
            </Scheduler>
          </IntlProvider>
        </LocalizationProvider>
      </HVSchedulerDiv>
    );
  },
  (prevProps, nextProps) => {
    return prevProps?.currentDate === nextProps?.currentDate;
  }
);

const CustomItem = (props: any) => {
  const {
    setReachPromo,
    getPromoMaterialAssetsFromServer,
    reachPromoMaterials,
  } = props;
  const navigate = useNavigate();

  const handleClickCalendarItem = async () => {
    await getPromoMaterialAssetsFromServer(props.dataItem.id);
    setReachPromo(
      reachPromoMaterials.find(
        (pm: PromotionalMaterialDto) => pm.id === props.dataItem.id
      )
    );
    navigate(window.location.pathname + `?promo=${props.dataItem.id}`, {
      replace: true,
    });
    track(REACH_EVENTS.CalendarOpenItem, {
      HV_Reach_PromoMaterialId: props.dataItem.id,
      HV_Reach_PromoMaterialTitle: props.dataItem.title,
    });
  };

  return (
    <SchedulerItem
      {...props}
      style={{
        ...props.style,
        backgroundColor: "transparent",
        borderRadius: 0,
        paddingLeft: 0,
        paddingRight: 0,
        overflow: "visible",
      }}
    >
      <Box onClick={() => handleClickCalendarItem()} height="100%">
        <Flex
          align={"center"}
          w={"100%"}
          bgColor={
            props.dataItem.assetType === ReachAssetType.SocialMedia
              ? Equity_Orange_40
              : props.dataItem.assetType === ReachAssetType.Email
              ? Equity_Purple_80
              : Mid_Blue_80
          }
          py={1}
          pl={2}
          borderLeft={"4px"}
          borderColor={
            props.dataItem.assetType === ReachAssetType.SocialMedia
              ? Equity_Orange
              : props.dataItem.assetType === ReachAssetType.Email
              ? Equity_Purple
              : Mid_Blue
          }
        >
          <Box
            fontSize={17}
            mr={2}
            color={
              props.dataItem.assetType === ReachAssetType.SocialMedia
                ? PRIMARY_TEXT_COLOR
                : CONTRAST_TEXT_COLOR
            }
          >
            {getAssetTypeIcon(props.dataItem.assetType) ?? <FiMoreHorizontal />}
          </Box>
          <Text
            fontSize={"12px"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            color={
              props.dataItem.assetType === ReachAssetType.SocialMedia
                ? PRIMARY_TEXT_COLOR
                : CONTRAST_TEXT_COLOR
            }
          >
            {props.title}
          </Text>
        </Flex>
      </Box>
    </SchedulerItem>
  );
};

export default ReachPromoteCalendarView;
