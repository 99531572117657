import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  CharterActivity,
  PollQuestionDto,
  ResponseState,
  TeamLessonUserInstanceParticipantDto,
} from "@headversity/contract";
import { Box, Flex, Text } from "@chakra-ui/react";
import {
  PollResponseList,
  PollResponseListItem,
} from "../../../Team/Polls/PollResponseList";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import { useGroupInformation } from "../Hooks/useGroupInformation";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { PollMultiFreeTextInput } from "../../../Shared/FreeTextInput/PollMultiFreeTextInput";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { PollMultiSelectInput } from "../../../Shared/SelectInput/PollMultiSelectInput";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { performHapticImpact } from "../../../../Api/Utils";
import { ParticipantTeamLessonStepProps } from "../../../Team/Participant/ParticipantModal";

interface ParticipantScenarioProps extends ParticipantTeamLessonStepProps {}
export const ParticipantWeStatementStepOne = (
  props: ParticipantScenarioProps
) => {
  const { pathTeamLesson } = props;
  const {
    pollQuestions,
    setPollInstanceResultToServer,
    updatePollUserResponsesToServer,
    currentTeamLessonUserInstanceId,
    currentParticipantList,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { isGroupLead } = useGroupInformation();

  const [loading, setLoading] = useState<boolean>(false);

  const [currentTextAnswer, setCurrentTextAnswer] = useState<
    string | undefined
  >(undefined);

  const [pollQuestion, setPollQuestion] = useState<PollQuestionDto | undefined>(
    undefined
  );

  const [textAnswersToSubmit, setTextAnswersToSubmit] = useState<string[]>([]);
  const [weStatementSelection, setWeStatementSelection] = useState<number[]>(
    []
  );
  const [weStatementCreated, setWeStatementCreated] = useState<boolean>(false);
  const [weStatementSelected, setWeStatementSelected] =
    useState<boolean>(false);

  const { groupPollQuestionResults, currentPollInstanceId } =
    usePollQuestionInformation({
      pollQuestion: pollQuestion,
      byGroup: true,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  const [numberOfGroups, setNumberOfGroups] = useState<number>(1);

  useEffect(() => {
    if (currentParticipantList) {
      const names = Array.from(
        new Set(
          currentParticipantList.map(
            (x: TeamLessonUserInstanceParticipantDto) => x.groupName!
          )
        )
      );
      setNumberOfGroups(names.length);
    }
  }, [currentParticipantList]);

  useEffect(() => {
    performHapticImpact();
  }, []);

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      setPollQuestion(pollQuestion);
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (groupPollQuestionResults && groupPollQuestionResults.length > 0) {
      setWeStatementCreated(true);
      if (
        groupPollQuestionResults.some((q) => q.state === ResponseState.active)
      ) {
        setWeStatementSelected(true);
      }
    }
  }, [groupPollQuestionResults]);

  useEffect(() => {
    if (loading && weStatementCreated) {
      setLoading(false);
    }
  }, [groupPollQuestionResults, weStatementCreated]);

  if (!currentPollInstanceId) {
    return <></>;
  }

  const disableIamDoneConditions = {
    1: (length: number) => length < 4,
    2: (length: number) => length < 4,
    3: (length: number) => length < 3,
    4: (length: number) => length < 3,
    default: (length: number) => length < 2,
  };

  const instructionConditions = {
    1: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_FOUR_TO_FIVE,
    2: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_FOUR_TO_FIVE,
    3: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_THREE_TO_FOUR,
    4: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_THREE_TO_FOUR,
    default: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_TWO_TO_THREE,
  };

  const instructionMinimumConditions = {
    1: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_AT_LEAST_FOUR,
    2: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_AT_LEAST_FOUR,
    3: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_AT_LEAST_THREE,
    4: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_AT_LEAST_THREE,
    default: HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_AT_LEAST_TWO,
  };

  const disableSelectionConditions = {
    1: (length: number) => length > 4,
    2: (length: number) => length > 4,
    3: (length: number) => length > 3,
    4: (length: number) => length > 3,
    default: (length: number) => length > 2,
  };

  const getDisableIamDoneMinimumCondition = () => {
    const condition =
      disableIamDoneConditions[
        numberOfGroups as keyof typeof disableIamDoneConditions
      ] || disableIamDoneConditions.default;
    return condition(textAnswersToSubmit.length);
  };

  const getDisableIamDoneCondition = () => {
    const condition =
      disableIamDoneConditions[
        numberOfGroups as keyof typeof disableIamDoneConditions
      ] || disableIamDoneConditions.default;
    return condition(weStatementSelection.length);
  };

  const getInstructionCondition = () => {
    return (
      instructionConditions[
        numberOfGroups as keyof typeof instructionConditions
      ] || instructionConditions.default
    );
  };

  const getInstructionMinimumCondition = () => {
    return (
      instructionMinimumConditions[
        numberOfGroups as keyof typeof instructionMinimumConditions
      ] || instructionMinimumConditions.default
    );
  };

  const getDisableSelectionCondition = () => {
    const condition =
      disableSelectionConditions[
        numberOfGroups as keyof typeof disableSelectionConditions
      ] || disableSelectionConditions.default;
    return condition(weStatementSelection.length);
  };

  let content;
  const waitingForPresenterMessage =
    pathTeamLesson!.inProgressStep === CharterActivity.WeStatementStepOneBreak
      ? HVSportLocalizeStrings.CHARTER_ITS_BREAK_TIME
      : HVSportLocalizeStrings.CHARTER_WAITING_FOR_PRESENTER_TO_CONTINUE;
  if (isGroupLead) {
    content = !weStatementCreated ? (
      <PollMultiFreeTextInput
        disableAddAnother={false}
        disableIamDone={getDisableIamDoneMinimumCondition()}
        questionText={HVSportLocalizeStrings.CHARTER_WE_STATEMENT_QUESTION}
        doneButtonMessage={HVLocalizeStrings.CONTINUE}
        instruction={getInstructionMinimumCondition()}
        currentTextAnswer={currentTextAnswer as any}
        setCurrentTextAnswer={setCurrentTextAnswer}
        textAnswersToSubmit={textAnswersToSubmit}
        setTextAnswersToSubmit={setTextAnswersToSubmit}
        loading={loading}
        iamDoneCallBack={async () => {
          if (currentPollInstanceId) {
            setLoading(true);
            setPollInstanceResultToServer(currentPollInstanceId, {
              questionAlternativeIds: [],
              feedback: "",
              questionUserResponseIds: [],
              freeTexts: textAnswersToSubmit,
            });
          }
        }}
        maxLength={105}
      />
    ) : (
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"center"}
        gap={"5"}
        my={"3"}
        w={"100%"}
      >
        {!weStatementSelected && pollQuestion ? (
          <PollMultiSelectInput
            disableSelection={getDisableSelectionCondition()}
            disableIamDone={getDisableIamDoneCondition()}
            doneButtonMessage={HVLocalizeStrings.WE_ARE_DONE}
            loading={loading}
            questionText={
              HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_PICK_YOUR_FAVOURITE
            }
            instruction={getInstructionCondition()}
            alternatives={groupPollQuestionResults}
            currentSelectedAnswers={weStatementSelection}
            submitPollResponse={() => {
              if (currentPollInstanceId) {
                setLoading(true);
                setWeStatementSelected(true);
                updatePollUserResponsesToServer(
                  currentPollInstanceId,
                  weStatementSelection.map((w) => {
                    return {
                      questionUserResponseId: w,
                      state: ResponseState.active,
                    };
                  })
                );
              }
            }}
            setCurrentSelectedAnswers={setWeStatementSelection}
          />
        ) : (
          <ParticipantWaitingScreen
            messageBold={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
            message={waitingForPresenterMessage}
          />
        )}
      </Flex>
    );
  } else {
    content = (
      <Flex
        flexDir={"column"}
        align={"start"}
        justify={"center"}
        gap={"5"}
        my={"3"}
        w={"100%"}
      >
        {weStatementCreated && !weStatementSelected ? (
          <>
            <Flex
              flexDir={"column"}
              align={"start"}
              justify={"start"}
              gap={"5"}
            >
              <BlockSectionTitle
                title={
                  HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_EDITOR_TITLE
                }
                primaryTextColor={Black}
                borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
              />
              <Text>
                {HVSportLocalizeStrings.CHARTER_WE_STATEMENT_QUESTION}
              </Text>
              <Text>
                {HVSportLocalizeStrings.CHARTER_WE_STATEMENT_ONE_GROUP_SAID}
              </Text>
            </Flex>
            <Box h={"auto"} w={"100%"}>
              <PollResponseList>
                {groupPollQuestionResults?.map((item) => (
                  <PollResponseListItem key={item.id}>
                    <Text color={Black} w={"100%"} fontSize={"16px"}>
                      {item.responseText}
                    </Text>
                  </PollResponseListItem>
                ))}
              </PollResponseList>
            </Box>
          </>
        ) : (
          <ParticipantWaitingScreen
            messageBold={HVSportLocalizeStrings.CHARTER_GROUP_ACTIVITY}
            message={
              groupPollQuestionResults.some(
                (qur) => qur.state === ResponseState.active
              )
                ? waitingForPresenterMessage
                : HVSportLocalizeStrings.CHARTER_WAITING_FOR_GROUP_LEADER
            }
          />
        )}
      </Flex>
    );
  }
  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={true} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {isGroupLead && !weStatementSelected ? (
        <Flex alignSelf={"start"}>
          <BlockSectionTitle
            title={`${HVLocalizeStrings.STEP} ${
              !weStatementCreated ? 1 : 2
            } / 2`}
            primaryTextColor={Black}
            borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          />
        </Flex>
      ) : (
        <></>
      )}
      {content}
    </ParticipantContainer>
  );
};
