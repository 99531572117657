import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { ReachMyOrganizationContext } from "../../../State/Reach/ReachMyOrganizationContext";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import {
  BG_REACH_BASE,
  HEADER_REACH_HOME_BACKGROUND,
} from "../../../Styles/HeadversityStyle";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { FadeAfterDelay } from "../../Common/FadeAfterDelay";
import { HeaderSetter } from "../../Common/HeaderSetter";
import { ReachManageMyOrganizationPanel } from "./ReachManageMyOrganizationPanel";
import { ReachQuickStartModal } from "./ReachQuickStartModal";
import { ReachQuickStartPanel } from "./ReachQuickStartPanel";
import MyOrganizationSectionPreview from "./MyOrganizationSectionPreview";
import { useNavigate } from "react-router-dom";
import { CompanyDto } from "../../../@headversity/contract";
import { ReachCommunicationStatus } from "../ReachCommunicationSettingsPage";

export const ReachMyOrganizationPage = () => {
  const { getPageTitle, soloEnabled, certsWithScorms } =
    useContext<IGlobalProvider>(GlobalContext);

  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);
  const orgContext = useContext(ReachMyOrganizationContext);
  const navigate = useNavigate();

  useEffect(() => {
    orgContext.fetchData();
  }, [orgContext.dataRefreshTimestamp]);

  return (
    <>
      <Helmet>
        <title>
          {getPageTitle(`${HVLocalizeStrings.REACH_MY_ORGANIZATION}`)}
        </title>
      </Helmet>
      <HeaderSetter
        title={HVLocalizeStrings.REACH_MY_ORGANIZATION}
        description={HVLocalizeStrings.REACH_ORGANIZATIONS_DESCRIPTION}
        backgroundImage={HEADER_REACH_HOME_BACKGROUND}
        backgroundImageBase={BG_REACH_BASE}
      ></HeaderSetter>
      <FadeAfterDelay>
        <Container maxW={isMainContainerXL ? "container.xl" : "container.lg"}>
          <Flex flexDirection="column">
            {orgContext.quickStart?.progress !== 100 && (
              <Box mb={6}>
                <ReachQuickStartPanel />
              </Box>
            )}
            {orgContext.company && (
              <BlockSectionTitle
                title={orgContext.company.name}
                description={""}
              ></BlockSectionTitle>
            )}
            <ReachManageMyOrganizationPanel />
            {soloEnabled && (
              <MyOrganizationSectionPreview
                title={HVLocalizeStrings.REACH_GOALS_TITLE}
                body={
                  <Flex>
                    <Text fontSize="sm" mr={2}>
                      {HVLocalizeStrings.REACH_ORGANIZATIONS_GOALS_CHOSEN}
                    </Text>
                    <Text fontSize="sm" ml={2}>
                      {orgContext.goalWinThemesCount}
                    </Text>
                  </Flex>
                }
                buttonText={HVLocalizeStrings.REACH_CHOOSE_GOALS}
                buttonClick={() => {
                  navigate("/reach/organizations/goals");
                }}
              />
            )}
            <MyOrganizationSectionPreview
              title={HVLocalizeStrings.REACH_USERS}
              body={
                <Flex>
                  <Text fontSize="sm" mr={2}>
                    {HVLocalizeStrings.REACH_USER_BASE}
                  </Text>
                  <Text fontSize="sm" ml={2}>
                    {orgContext.usersCount} {HVLocalizeStrings.REACH_USERS}
                  </Text>
                </Flex>
              }
              buttonText={HVLocalizeStrings.REACH_VIEW_ALL_USERS}
              buttonClick={() => {
                navigate("/reach/organizations/users");
              }}
            />
            <MyOrganizationSectionPreview
              title={HVLocalizeStrings.REACH_HELP_RESOURCES_TITLE}
              body={
                <Flex>
                  <Text fontSize="sm" mr={2}>
                    {HVLocalizeStrings.REACH_ORGANIZATIONS_HELP_RESOURCES_ADDED}
                  </Text>
                  <Text fontSize="sm" ml={2}>
                    {orgContext.helpResourcesCount}
                  </Text>
                </Flex>
              }
              buttonText={
                HVLocalizeStrings.REACH_ORGANIZATIONS_MANAGE_HELP_RESOURCES
              }
              buttonClick={() => {
                navigate("/reach/organizations/help-resources");
              }}
            />
            <MyOrganizationSectionPreview
              title={
                HVLocalizeStrings.REACH_ORGANIZATIONS_COMMUNICATION_SETTINGS
              }
              body={
                <ReachCommunicationStatus
                  hasBorder={false}
                  company={orgContext.company as CompanyDto}
                  p={0}
                />
              }
              buttonText={HVLocalizeStrings.REACH_MANAGE_SETTINGS}
              buttonClick={() => {
                navigate("/reach/organizations/communication-settings");
              }}
            />
            {certsWithScorms.length > 0 && (
              <MyOrganizationSectionPreview
                title={HVLocalizeStrings.REACH_ORGANIZATIONS_LMS_INTEGRATIONS}
                body={null as any}
                buttonText={HVLocalizeStrings.REACH_MANAGE_INTEGRATIONS}
                buttonClick={() => {
                  navigate("/reach/organizations/scorms");
                }}
              />
            )}
            {orgContext.isQuickStartGuideOpen && <ReachQuickStartModal />}
          </Flex>
        </Container>
      </FadeAfterDelay>
    </>
  );
};
