import { ParticipantTeamLessonStepProps } from "./ParticipantModal";
import { useContext, useEffect, useState } from "react";
import {
  AppType,
  GlobalContext,
  IGlobalProvider,
} from "../../../State/GlobalContext";
import { PollQuestion } from "../Polls/PollQuestion";
import { Text, Flex, Box } from "@chakra-ui/react";
import { PollResult } from "../Polls/PollResult";
import { MODAL_PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import dayjs from "dayjs";
import { PresentationLobbyView } from "../Shared/PresentationLobbyView";
import BlockSectionTitle from "../../Common/BlockSectionTitle";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  PollQuestionDto,
  QuestionType,
  QuestionUserResponseDto,
} from "@headversity/contract";
import Rise360Player from "../../Shared/Rise360/Rise360Player";
import { Rise360LessonViewModel } from "../../Shared/Rise360/Rise360LessonViewModel";
import { getLatestPollInstanceByPollQuestionId } from "../../../Utils/TeamUtil";
import { ViewFactory } from "../../Sport/Charter/Participant/ViewFactory";

interface ParticipantProgressStepProps extends ParticipantTeamLessonStepProps {}

export const ParticipantProgressStep = (
  props: ParticipantProgressStepProps
) => {
  const { pathTeamLesson } = props;

  const {
    pollQuestions,
    getPollsFromServer,
    selectedUserLanguage,
    pollInstances,
    currentTeamLessonUserInstanceId,
    self,
    currentSlideIndex,
    currentApp,
    pollInstanceResults,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [pollQuestion, setPollQuestion] = useState<PollQuestionDto | null>();

  const [rise360Lesson, setRise360Lesson] = useState<
    Rise360LessonViewModel | undefined
  >(undefined);

  const [queryStringParams] = useState([
    { name: "mode", value: currentApp === AppType.SPORT ? "sport" : "team" },
    { name: "type", value: "participant" },
  ]);

  useEffect(() => {
    if (!pathTeamLesson?.teamLessonId) {
      return;
    }

    getPollsFromServer(pathTeamLesson.teamLessonId);
  }, [pathTeamLesson]);

  useEffect(() => {
    if (pathTeamLesson && pollQuestions && pollInstances) {
      const polls = pollQuestions?.filter((item) => {
        const pollInstance = getLatestPollInstanceByPollQuestionId(
          item.id,
          pollInstances,
          currentTeamLessonUserInstanceId
        );
        return (
          pollInstance &&
          dayjs.utc(pollInstance.createdAt.toString()).unix() >=
            dayjs.utc(pathTeamLesson?.lastPresentedDate?.toString()).unix()
        );
      });

      if (pathTeamLesson.inProgressStep) {
        const instruction = pathTeamLesson?.inProgressStep?.split("_");
        if (instruction.length > 1) {
          const possiblePolls = polls.filter(
            (item) => item.id.toString() == instruction[1]
          );
          if (possiblePolls.length > 0) {
            setPollQuestion(possiblePolls[0]);
            return;
          }
        }
      }

      setPollQuestion(null);

      //setPollInstanceStarted(polls.length > 0);
      // if (polls.length !== currentPollsLength) {
      //   setCurrentPoll(polls[polls.length - 1]);
      //   setCurrentPollsLength(polls.length);
      //   setAlwaysShowLobbyScreen(false);
      // }

      //setPollsThatBeenStarted(polls);
    }
  }, [pathTeamLesson, pollQuestions, pollInstances]);

  useEffect(() => {
    if (!selectedUserLanguage || !pathTeamLesson) return;

    // TODO: consider using the presenter's language instead (to ensure slides match up)
    //  but, we may be able to guarantee that presentations always match, which would be better
    if (!pathTeamLesson?.teamLesson?.lessonCourse?.startingUrl) return;

    if (
      pathTeamLesson?.teamLesson?.lessonCourse?.startingUrl !==
      rise360Lesson?.startingUrl
    ) {
      setRise360Lesson({
        startingUrl: pathTeamLesson.teamLesson.lessonCourse.startingUrl,
      });
    }
  }, [selectedUserLanguage, pathTeamLesson]);

  const lessonContent =
    rise360Lesson && currentSlideIndex !== -1 ? (
      /* purposefully not wrapped in <LessonProvider> since partcipants are view-only */
      <Rise360Player
        rise360Lesson={rise360Lesson}
        queryStringParams={queryStringParams}
        lessonTitle={pathTeamLesson?.teamLesson?.name_loc?.en}
        display={pollQuestion ? "none" : "unset"}
        onShouldShowBackButton={() => {}}
        onLessonComplete={() => {}}
        currentSlideIndex={currentSlideIndex}
      />
    ) : (
      <PresentationLobbyView
        mode={"Participant"}
        teamLesson={pathTeamLesson?.teamLesson}
        mainTitle={HVLocalizeStrings.TEAM_TEAM_LESSON_IN_PROGRESS}
        subTitle={HVLocalizeStrings.TEAM_TEAM_LESSON_IN_PROGRESS_SUB_HEADING}
        additionalSection={<></>}
        hideLoading={true}
      />
    );

  let content = undefined;
  if (pathTeamLesson?.inProgressStep?.startsWith("Activity:")) {
    content = (
      <ViewFactory
        activity={pathTeamLesson?.inProgressStep as any}
        pathTeamLesson={pathTeamLesson}
      />
    );
  } else if (pollQuestion) {
    const pollInstance = getLatestPollInstanceByPollQuestionId(
      pollQuestion.id,
      pollInstances,
      currentTeamLessonUserInstanceId
    );
    if (
      pollInstance &&
      dayjs.utc(pollInstance.createdAt.toString()).unix() >=
        dayjs.utc(pathTeamLesson?.lastPresentedDate?.toString()).unix()
    ) {
      content = (
        <>
          <Flex flexDir={"column"} align={"center"} mb={"10"}>
            <BlockSectionTitle title={HVLocalizeStrings.TEAM_POLL_TIME} />
          </Flex>
          <>
            {pollQuestion?.question?.questionType === QuestionType.Headzone && (
              <Text
                textAlign="center"
                fontWeight={"semibold"}
                color={MODAL_PRIMARY_TEXT_COLOR}
                data-testid={HVTestId.ParticipantProgressStep.pollQuestion}
              >
                {pollQuestion.question.questionText as string}
              </Text>
            )}
            <PollQuestion
              pollQuestion={pollQuestion}
              pollInstanceId={pollInstance.id}
            />
          </>
        </>
      );

      if (
        pollInstance?.questionUserResponses?.some(
          (questionUserResponse: QuestionUserResponseDto) =>
            questionUserResponse.userId === self?.id
        ) ||
        pollInstanceResults?.[pollInstance.id]?.some(
          (questionUserResponse: QuestionUserResponseDto) =>
            questionUserResponse.userId === self?.id
        )
      ) {
        content = (
          <Flex
            flexDir={"column"}
            align={"center"}
            w={"100%"}
            h={"100%"}
            minH={"500px"}
            gap={"10"}
          >
            <BlockSectionTitle title={HVLocalizeStrings.TEAM_POLL_TIME} />
            <>
              {pollQuestion && (
                <Text
                  textAlign="center"
                  fontWeight={"semibold"}
                  color={MODAL_PRIMARY_TEXT_COLOR}
                >
                  {pollQuestion.question.questionText as string}
                </Text>
              )}
              <PollResult
                pollQuestion={pollQuestion}
                pollInstance={pollInstance}
                showWaitingOnVotes={false}
              />
            </>
          </Flex>
        );
      }
    }
  }

  return (
    <>
      {content}

      {/* leave the lesson content view loaded, so that iframe does not reload when going between polls */}
      <Box display={content ? "none" : "unset"}>{lessonContent}</Box>
    </>
  );
};
