import dayjs from "dayjs";
import { track } from "./Analytics";
import { Dictionary } from "highcharts";
import {
  LessonType,
  PathDto,
  PathTeamLessonDto,
  PollQuestionUserInstanceDto,
  QuestionUserResponseDto,
  SkillCompetencyDto,
  SkillDto,
  TeamDto,
  TeamLessonDto,
  TeamLessonUserInstanceStatus,
} from "@headversity/contract";

export const getPathTeamLessonByTeamId = (
  paths: Dictionary<PathDto[]>,
  currentTeam: TeamDto | undefined,
  pathTeamLessonId?: number,
  pathId?: number,
  teamLessonId?: number
): PathTeamLessonDto | undefined => {
  let pathTeamLessonResult = undefined;
  if (paths && currentTeam && paths[currentTeam.id.toString()]) {
    const teamPaths = paths[currentTeam.id.toString()];
    teamPaths.forEach((path: PathDto) => {
      path.pathTeamLessons?.forEach((pathTeamLesson: PathTeamLessonDto) => {
        if (
          pathTeamLesson.id == pathTeamLessonId ||
          (pathTeamLesson.pathId == pathId &&
            pathTeamLesson.teamLesson?.id == teamLessonId)
        ) {
          pathTeamLessonResult = pathTeamLesson;
        }
      });
    });
  }
  return pathTeamLessonResult;
};

export const getLastPresentedByTimeSpan = (
  pathTeamLesson: PathTeamLessonDto
) => {
  const lastPresentedDate = dayjs.utc(
    pathTeamLesson?.lastPresentedDate?.toString()
  );
  return dayjs.utc().diff(lastPresentedDate, "minutes");
};

export const hasPollBeenAnswered = (
  pollQuestionId: number,
  latestPollInstances: PollQuestionUserInstanceDto[],
  userId?: number,
  teamId?: number,
  pathTeamLesson?: PathTeamLessonDto,
  pollInstanceResults?: Dictionary<QuestionUserResponseDto[] | undefined>
) => {
  if (latestPollInstances) {
    const pollInstances = latestPollInstances?.filter(
      (item) =>
        item.pollQuestionId === pollQuestionId &&
        item.teamLessonUserInstance.teamId === teamId &&
        pathTeamLesson?.lastPresentedDate &&
        item.createdAt > pathTeamLesson?.lastPresentedDate
    );
    if (pollInstances.length > 0) {
      const pollInstance = pollInstances[0];
      const pollInstanceResult = pollInstanceResults?.[pollInstance.id];
      return (
        (pollInstance.questionUserResponses &&
          pollInstance.questionUserResponses?.length > 0 &&
          pollInstance.questionUserResponses?.filter(
            (questionUserResponse) => questionUserResponse.userId == userId
          ).length > 0) ||
        pollInstanceResult?.some(
          (questionUserResponse: QuestionUserResponseDto) =>
            questionUserResponse.userId === userId
        )
      );
    }
  }
  return false;
};

export const hasTeamDonePoll = (
  pollQuestionId: number | undefined,
  latestPollInstances: PollQuestionUserInstanceDto[],
  teamId?: number,
  pathTeamLesson?: PathTeamLessonDto
) => {
  return latestPollInstances.some(
    (pollInstance) =>
      pollInstance.pollQuestionId === pollQuestionId &&
      pollInstance.teamLessonUserInstance.teamId === teamId &&
      pathTeamLesson?.lastPresentedDate &&
      pollInstance.createdAt > pathTeamLesson?.lastPresentedDate
  );
};

export const isAnotherTeamLessonInProgressForTeam = (
  paths: Dictionary<PathDto[]>,
  currentTeam: TeamDto | undefined,
  currentPathTeamLessonId: number | undefined,
  pathId: number = 0,
  checkIfExpired: boolean = true
) => {
  let isTeamLessonInProgress = false;
  if (paths && currentTeam && paths[currentTeam.id.toString()]) {
    let teamPaths = paths[currentTeam.id.toString()];
    if (pathId > 0) {
      teamPaths = teamPaths.filter((path: PathDto) => path.id == pathId);
    }
    teamPaths.forEach((path: PathDto) => {
      path.pathTeamLessons?.forEach((pathTeamLesson: PathTeamLessonDto) => {
        if (
          pathTeamLesson.teamLessonInstanceStatus !== undefined &&
          pathTeamLesson.id !== currentPathTeamLessonId &&
          [
            TeamLessonUserInstanceStatus.Initialized,
            TeamLessonUserInstanceStatus.InProgress,
          ].indexOf(pathTeamLesson.teamLessonInstanceStatus) >= 0 &&
          pathTeamLesson.lastPresentedDate &&
          (!checkIfExpired ||
            !isPresentationExpired(
              getLastPresentedByTimeSpan(pathTeamLesson),
              pathTeamLesson?.teamLesson
            ))
        ) {
          isTeamLessonInProgress = true;
        }
      });
    });
  }
  return isTeamLessonInProgress;
};

export const getPathTeamLessonInProgress = (
  paths: Dictionary<PathDto[]>,
  team: TeamDto
): PathTeamLessonDto | null => {
  let pathTeamLessonInProgress: PathTeamLessonDto | null = null;
  if (team && paths) {
    paths[team?.id?.toString()]?.forEach((path: PathDto) => {
      path?.pathTeamLessons?.forEach((pathTeamLesson: PathTeamLessonDto) => {
        if (
          !pathTeamLessonInProgress &&
          pathTeamLesson.teamLessonInstanceStatus !== undefined &&
          [
            TeamLessonUserInstanceStatus.Initialized,
            TeamLessonUserInstanceStatus.InProgress,
          ].indexOf(pathTeamLesson.teamLessonInstanceStatus) >= 0 &&
          (pathTeamLesson?.lastPresentedDate === undefined ||
            !isPresentationExpired(
              getLastPresentedByTimeSpan(pathTeamLesson),
              pathTeamLesson?.teamLesson
            ))
        ) {
          pathTeamLessonInProgress = pathTeamLesson;
        }
      });
    });
  }
  return pathTeamLessonInProgress;
};

export const isTeamLesson = (teamlessonDto?: TeamLessonDto): boolean => {
  return (
    teamlessonDto !== undefined &&
    (teamlessonDto.lessonType === undefined ||
      teamlessonDto.lessonType === null ||
      teamlessonDto.lessonType === LessonType.Team)
  );
};

export const isPresentationExpired = (
  minutesPassed: number,
  teamlessonDto?: TeamLessonDto
): boolean => {
  if (teamlessonDto?.lessonType === LessonType.Charter) {
    return false;
  }

  const duration = teamlessonDto?.duration;

  // Calculate the maximum allowed duration for the presentation to be considered expired
  const maxAllowedDuration = Math.max(15, duration ?? 0);

  // It is expired if the minutes passed is greater than the maximum allowed duration
  return minutesPassed > maxAllowedDuration;
};

export const trackTeamLesson = (
  event: string,
  team: TeamDto | undefined,
  pathTeamLesson: PathTeamLessonDto | undefined,
  trackingOrigin?: string,
  additional?:
    | {
        HV_Team_Presentation_Session_Id: number | undefined;
      }
    | undefined
) => {
  track(event, {
    HV_Team_Id: team?.id,
    HV_Team_Name: team?.name,
    HV_Path_Id: pathTeamLesson?.pathId,
    HV_Presentation_Id: pathTeamLesson?.teamLessonId,
    HV_Presentation_Title: pathTeamLesson?.teamLesson?.name,
    ...additional,
    ...(trackingOrigin && { HV_Origin: trackingOrigin }),
  });
};

export const isTeamLessonDone = (
  pathTeamLesson: PathTeamLessonDto | undefined
): boolean => {
  return (
    pathTeamLesson?.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.Complete ||
    pathTeamLesson?.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.ForcedComplete
  );
};

export const isTeamLessonDoneOrCatchup = (
  pathTeamLesson: PathTeamLessonDto | undefined
): boolean => {
  return (
    pathTeamLesson?.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.Complete ||
    pathTeamLesson?.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.ForcedComplete ||
    pathTeamLesson?.teamLessonInstanceStatus ===
      TeamLessonUserInstanceStatus.Catchup
  );
};

export const getNextUpPathTeamLessons = (
  paths: Dictionary<PathDto[]>,
  currentTeam: TeamDto | undefined,
  skill?: SkillDto
): PathTeamLessonDto[] => {
  const pathTeamLessons = [] as PathTeamLessonDto[];

  // used to not add duplicates
  const pathTeamLessonIds = [] as number[];

  // get all up next teamLessons for the currentTeam (in global state) or the first team, if none
  let teamId = currentTeam?.id;
  if (!teamId) {
    const teamIds = Object.keys(paths);
    if (teamIds && teamIds.length > 0) {
      teamId = parseInt(teamIds[0], 10);
    }

    if (!teamId) {
      // no teamLessons to return when user does not have any team
      return [];
    }
  }

  const pathList = paths[teamId];
  if (!pathList) return [];

  pathList.forEach((path: PathDto) => {
    if (path.pathTeamLessons.length === 0) return;

    // check if started
    let isStarted = false;
    let isCompleted = false;

    // filter on skill
    const pathTeamLessonsFiltered: PathTeamLessonDto[] = !skill
      ? path.pathTeamLessons
      : path.pathTeamLessons.filter((x) =>
          x.teamLesson?.skillCompetencies?.some(
            (ps: SkillCompetencyDto) => ps.skillId == skill.id
          )
        );

    if (pathTeamLessonsFiltered.length === 0) return;

    pathTeamLessonsFiltered.forEach((item) => {
      if (isTeamLessonDone(item)) {
        isStarted = true;
      }
    });

    // get the "up next" teamLesson, or the first one if none
    let pathTeamLesson = pathTeamLessonsFiltered.find(
      (x) => x.teamLessonInstanceStatus === TeamLessonUserInstanceStatus.UpNext
    );

    if (!pathTeamLesson) {
      pathTeamLesson = pathTeamLessonsFiltered[0];
      isCompleted = true;
    }

    // add if not here already
    if (!pathTeamLessonIds.includes(pathTeamLesson.teamLessonId)) {
      pathTeamLessonIds.push(pathTeamLesson.teamLessonId);
      pathTeamLessons.push({
        ...pathTeamLesson,
        isStarted,
        isCompleted,
        pathId: path.id,
      });
    }
  });

  return pathTeamLessons;
};

export const getPathForTeamLesson = (
  teamLesson: TeamLessonDto,
  paths: Dictionary<PathDto[]>,
  currentTeam: TeamDto | undefined
) => {
  if (!teamLesson || !paths) return;

  // get the path for the current team (though this doesn't really matter for getting the path details we need)
  if (!(currentTeam && currentTeam.id in paths)) return;

  const teamPaths = paths[currentTeam?.id];
  // get the path for the input pathTeamLesson
  return teamPaths.find((p) =>
    p.pathTeamLessons?.some(
      (pathTeamLesson: PathTeamLessonDto) =>
        pathTeamLesson.teamLessonId === teamLesson.id
    )
  );
};

export const getPathTeamLessonForTeamLessonUserInstanceId = (
  teamLessonUserInstanceId: number,
  teamPathMappings: Dictionary<PathDto[]>
): PathTeamLessonDto | undefined => {
  if (!teamLessonUserInstanceId || !teamPathMappings) return undefined;

  // Flatten the structure to reduce the number of nested loops
  const pathTeamLessons = Object.values(teamPathMappings).flatMap((paths) =>
    paths.flatMap((path) => path.pathTeamLessons)
  );

  // Find the path team lesson if teamLessonUserInstanceId matches
  return pathTeamLessons.find(
    (pathTeamLesson) =>
      pathTeamLesson.teamLessonUserInstanceId === teamLessonUserInstanceId
  );
};

export const getTeamForTeamLessonUserInstanceId = (
  teamLessonUserInstanceId: number,
  teamPathMappings: Dictionary<PathDto[]>,
  allTeams: TeamDto[]
): TeamDto | undefined => {
  let pathTeamLessonTeamId: number | undefined = undefined;

  const foundPathTeamLesson = getPathTeamLessonForTeamLessonUserInstanceId(
    teamLessonUserInstanceId,
    teamPathMappings
  );
  if (foundPathTeamLesson) {
    pathTeamLessonTeamId = foundPathTeamLesson.teamLessonUserInstance?.teamId;
  }

  return allTeams.find((team: TeamDto) => team.id === pathTeamLessonTeamId);
};

export const getScrubbedTeamLessonDescription = (
  selectedUserLanguage: string,
  teamLesson?: TeamLessonDto
) => {
  // strip out font styles and background color, as we do in ResiliencePostModal
  if (!teamLesson) return "";

  const description = teamLesson?.description;

  const temp = document.createElement("div");
  temp.innerHTML = description as string;
  return temp.innerText;
};

const filterLessonCourse = (
  lessonCourses: any[],
  presentationId: number,
  language: string
) => {
  const filteredLessonCourses = lessonCourses.filter(
    (lc: any) =>
      lc?.presentationId === presentationId && lc?.language == language
  );
  if (filteredLessonCourses.length > 0) {
    return filteredLessonCourses[0];
  }
  return null;
};

export const setOpenWrapperForPollModals = (
  value: boolean,
  clear: () => void,
  setOpen: (open: boolean) => void
) => {
  if (!value) {
    clear();
  }
  setOpen(value);
};

export const getLatestPollInstanceByPollQuestionId = (
  pollQuestionId: number,
  pollInstances: PollQuestionUserInstanceDto[] | undefined,
  currentTeamLessonUserInstanceId: number | undefined
) => {
  if (!pollInstances) {
    return null;
  }
  const currentPollInstances = pollInstances.filter(
    (item: PollQuestionUserInstanceDto) =>
      item.pollQuestionId === pollQuestionId &&
      item.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
  );
  if (currentPollInstances.length > 0) {
    return currentPollInstances[0];
  }
  return null;
};

export const getLatestPollInstanceResultByPollQuestionId = (
  pollQuestionId: number,
  pollInstances: PollQuestionUserInstanceDto[] | undefined,
  currentTeamLessonUserInstanceId: number | undefined,
  pollInstanceResults: Dictionary<QuestionUserResponseDto[] | undefined>
) => {
  if (!pollInstances) {
    return null;
  }
  const currentPollInstances = pollInstances.filter(
    (item: PollQuestionUserInstanceDto) =>
      item.pollQuestionId === pollQuestionId &&
      item.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
  );
  if (
    currentPollInstances.length > 0 &&
    pollInstanceResults[currentPollInstances[0].id]
  ) {
    return pollInstanceResults[currentPollInstances[0].id];
  }

  if (
    currentPollInstances.length > 0 &&
    currentPollInstances[0].questionUserResponses
  ) {
    return currentPollInstances[0].questionUserResponses;
  }
  return null;
};
