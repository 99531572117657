import { useCallback, useState } from "react";
import { getKey } from "../../Utils/Helpers";
import {
  getCerts,
  getUserCertificate,
  saveCertQuestionAnswers,
  setCertInstance,
  setCertInstanceState,
  setCertSectionInstanceState,
} from "../../Api/Cert/CertApi";
import {
  CertDto,
  CertInstanceDto,
  CertSectionInstanceDto,
  LessonQuestionAnswerDto,
  CompletionState,
  LessonCourseDto,
  QuestionDto,
  UserCertificateDto,
} from "@headversity/contract";
import { AxiosResponse } from "axios";

export enum QuestionnaireType {
  Pre,
  Post,
  Exam,
}

export interface QuestionnaireDto {
  id: number;
  type: QuestionnaireType;
  preInstructions: string;
  postInstructions: string;
  questions: QuestionDto[];
}

export interface CertificationSectionDto {
  id: number;
  title: string;
  lessonCourse: LessonCourseDto;
  questionnaires: QuestionnaireDto[];
}

export interface CertificationDto {
  id: number;
  title: string;
  shortDescription: string;
  imageUrl: string;
  thumbnailImageUrl: string;
  doneState: CompletionState;
  sections: CertificationSectionDto[];
  questionnaires: QuestionnaireDto[];
}

export interface ICertProvider {
  certifications: CertDto[];
  setCertifications: (certs: CertDto[]) => void;
  getCertsFromServer: () => Promise<CertDto[]>;
  getUserCertificateFromServer: (guid: string) => Promise<UserCertificateDto>;
  setCertInstanceToServer: (certId: number) => Promise<CertDto[]>;
  setCertSectionInstanceStateToServer: (
    certSectionInstanceId: number,
    certSectionInstanceDto: CertSectionInstanceDto
  ) => Promise<CertDto[]>;
  setCertInstanceStateToServer: (
    certInstanceId: number,
    certInstanceDto: CertInstanceDto
  ) => Promise<CertDto[]>;
  saveCertSectionQuestionAnswersToServer: (
    certSectionId: number,
    lessonQuestionAnswerDto: LessonQuestionAnswerDto
  ) => Promise<AxiosResponse<void>>;
}

export const useCert = (): ICertProvider => {
  const [certifications, setCertifications] = useState<CertDto[]>([]);

  const getAndSetCerts: () => Promise<CertDto[]> = async () => {
    return getCerts(getKey()).then(async (result) => {
      setCertifications(result.data);
      return Promise.resolve(result.data);
    });
  };

  const getCertsFromServer = useCallback(async (): Promise<CertDto[]> => {
    return await getAndSetCerts();
  }, []);

  const getUserCertificateFromServer = useCallback(
    async (guid: string): Promise<UserCertificateDto> => {
      return getUserCertificate(guid).then((result) => {
        return Promise.resolve(result.data);
      });
    },
    []
  );

  const setCertInstanceToServer = useCallback(
    async (certId: number): Promise<CertDto[]> => {
      return setCertInstance(getKey(), certId).then(async () => {
        return await getAndSetCerts();
      });
    },
    []
  );

  const setCertSectionInstanceStateToServer = useCallback(
    async (
      certSectionInstanceId: number,
      certSectionInstanceDto: CertSectionInstanceDto
    ): Promise<CertDto[]> => {
      return setCertSectionInstanceState(
        getKey(),
        certSectionInstanceId,
        certSectionInstanceDto
      ).then(async () => {
        return await getAndSetCerts();
      });
    },
    []
  );

  const setCertInstanceStateToServer = useCallback(
    async (
      certInstanceId: number,
      certInstanceDto: CertInstanceDto
    ): Promise<CertDto[]> => {
      return setCertInstanceState(
        getKey(),
        certInstanceId,
        certInstanceDto
      ).then(async () => {
        return await getAndSetCerts();
      });
    },
    []
  );

  const saveCertSectionQuestionAnswersToServer = useCallback(
    (
      certSectionId: number,
      lessonQuestionAnswerDto: LessonQuestionAnswerDto
    ) => {
      return saveCertQuestionAnswers(
        getKey(),
        certSectionId,
        lessonQuestionAnswerDto
      );
    },
    []
  );

  return {
    certifications,
    setCertifications,
    getCertsFromServer,
    getUserCertificateFromServer,
    setCertInstanceToServer,
    setCertSectionInstanceStateToServer,
    setCertInstanceStateToServer,
    saveCertSectionQuestionAnswersToServer,
  };
};
