import { Box, Button, Text, Flex, useDisclosure } from "@chakra-ui/react";
import { TeamUserDto, TeamUserRole, UserRole } from "@headversity/contract";
import { isMobile } from "react-device-detect";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import {
  PRIMARY_TEXT_COLOR,
  DESTRUCTIVE_TEXT_COLOR,
  Border_Radius,
} from "../../Styles/HeadversityStyle";
import { Helmet } from "react-helmet";
import { HVTestId } from "../../Testing/dataTestIds";
import { ConfirmationButtons } from "../Common/ConfirmationDialog";
import { AddTeammateModal } from "../Team/TeamManagement/AddTeammateModal";
import { TeamList } from "../Team/TeamManagement/TeamList";
import { useContext, useEffect, useState } from "react";
import { IGlobalProvider, GlobalContext } from "../../State/GlobalContext";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { HvSpinner } from "../Common/HvSpinner";
import { OnboardingStep, SportOnboarding } from "./Onboarding/SportOnboarding";
import { SportTeamMemberProfile } from "./SportTeamMemberProfile";
import { BlockSection } from "../Common/BlockSection";
import { FadeAfterDelay } from "../Common/FadeAfterDelay";
import { useSportTeam } from "../../Hooks/Team/useSportTeam";
import { SportTeamInfo } from "./SportTeamInfo";
import { HVSportLocalizeStrings } from "../../Localization/HVSportLocalizeString";

export const SportTeamManagement = () => {
  const {
    deleteTeamFromServer,
    certsCompletedByTeam,
    getCertsCompletedByTeamFromServer,
    setCurrentTeam,
    currentTeam,
    teams,
    self,
  } = useContext<IGlobalProvider>(GlobalContext);

  const { isLeader } = useSportTeam();
  const [selectedTeamUser, setSelectedTeamUser] = useState<TeamUserDto | null>(
    null
  );

  const { confirmationDialogRef, showToast } =
    useContext<IShellProvider>(ShellContext);

  const {
    onClose: onCloseSelectOrAdd,
    onOpen: onOpenSelectOrAdd,
    isOpen: isOpenSelectOrAdd,
  } = useDisclosure();

  const {
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
    isOpen: isOpenEdit,
  } = useDisclosure();

  const {
    onClose: onCloseProfile,
    onOpen: onOpenProfile,
    isOpen: isOpenProfile,
  } = useDisclosure();

  const [teamMembers, setTeamMembers] = useState<TeamUserDto[]>([]);
  useEffect(() => {
    if (currentTeam) {
      getCertsCompletedByTeamFromServer(currentTeam.id);
    }
  }, [currentTeam?.id]);

  useEffect(() => {
    if (teams && teams.length > 0) {
      const currTeam = teams.find((t) => t.id === currentTeam?.id);
      setCurrentTeam(currTeam);
    }
  }, [teams]);

  useEffect(() => {
    if (currentTeam) {
      setTeamMembers(currentTeam.teamUsers);
    }
  }, [currentTeam]);

  const handleOnRowClick = (member: TeamUserDto) => {
    setSelectedTeamUser(member);
    onOpenProfile();
  };

  const handleDeleteTeam = () => {
    if (!currentTeam) {
      return;
    }
    (confirmationDialogRef.current as any).confirmation(
      HVLocalizeStrings.TEAM_DELETE_TEAM,
      `${HVLocalizeStrings.TEAM_CONFIRM_DELETE.replace(
        "{0}",
        currentTeam?.name
      )}`,
      ConfirmationButtons.YesNo,
      () => {
        deleteTeamFromServer(currentTeam?.id).then(() => {
          showToast(
            true,
            "team-delete-success",
            HVLocalizeStrings.TEAM_DELETE_SUCCESS_TITLE
          );
        });
      }
    );
  };

  return !isLeader ? (
    <></>
  ) : (
    <>
      <Helmet>
        <title>
          {`${HVSportLocalizeStrings.CHARTER_SPORT_TEAM_TITLE} - ${HVLocalizeStrings.HEADVERSITY}`}
        </title>
      </Helmet>

      <BlockSection fadeIn={true} title={""} columns={1}>
        <Box mt="10px">
          <SportTeamInfo />
        </Box>
      </BlockSection>

      {currentTeam ? (
        <FadeAfterDelay noEase={true}>
          <BlockSection fadeIn={true} title={""} columns={1}>
            <Box
              border="1px solid lightgray"
              borderRadius={Border_Radius}
              bg="white"
              boxShadow="md"
              p={4}
            >
              <Flex
                flexDir={"column"}
                gap={isMobile ? undefined : "1"}
                pb={"4"}
                fontSize={"14px"}
              >
                {/* Team stats */}

                <Flex gap="1">
                  <Text fontWeight="semibold">
                    {currentTeam.teamUsers
                      ? currentTeam.teamUsers.filter(
                          (x) => x.role !== TeamUserRole.Leader
                        ).length
                      : 0}
                  </Text>
                  <Text>members</Text>
                </Flex>
              </Flex>

              <Flex
                flexDir={"column"}
                gap={"8"}
                px={[0, 0, 0, 4]}
                w={"100%"}
                align={"center"}
              >
                <TeamList
                  teamMembers={teamMembers}
                  team={currentTeam}
                  onRowClick={handleOnRowClick}
                  contentAbovePager={
                    <Button
                      className="button-link"
                      color={PRIMARY_TEXT_COLOR}
                      onClick={() => {
                        onOpenSelectOrAdd();
                      }}
                      data-testid={HVTestId.ManagementAccordion.addMemberButton}
                    >
                      {HVLocalizeStrings.TEAM_ADD_MEMBER}
                    </Button>
                  }
                />

                <Flex flexDir={"column"} gap={"2"} mt={"2"}>
                  <Button
                    className="button-link"
                    color={PRIMARY_TEXT_COLOR}
                    fontSize="sm"
                    onClick={() => {
                      onOpenEdit();
                    }}
                    data-testid={HVTestId.ManagementAccordion.editTeamButton}
                  >
                    {HVLocalizeStrings.TEAM_EDIT_TEAM_NAME}
                  </Button>

                  {self && self?.role <= UserRole.HVAdmin && (
                    <Button
                      className="button-link"
                      color={DESTRUCTIVE_TEXT_COLOR}
                      fontSize="sm"
                      onClick={() => {
                        handleDeleteTeam();
                      }}
                      data-testid={
                        HVTestId.ManagementAccordion.deleteTeamButton
                      }
                    >
                      {HVLocalizeStrings.TEAM_DELETE_TEAM}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Box>
          </BlockSection>
        </FadeAfterDelay>
      ) : (
        <HvSpinner></HvSpinner>
      )}

      {currentTeam && isOpenSelectOrAdd && (
        <AddTeammateModal
          showNameInput={true}
          open={isOpenSelectOrAdd}
          close={onCloseSelectOrAdd}
          teamId={currentTeam.id}
          teamCode={currentTeam.code}
          teamName={currentTeam.name}
          showQRCode={false}
          memberList={teamMembers}
        />
      )}

      {isOpenEdit && (
        <SportOnboarding
          open={isOpenEdit}
          setOpen={onCloseEdit}
          initialStep={OnboardingStep.TeamEdit}
        />
      )}
      {isOpenProfile && selectedTeamUser && (
        <SportTeamMemberProfile
          setOpen={onCloseProfile}
          open={isOpenProfile}
          teamUser={selectedTeamUser}
        />
      )}
    </>
  );
};
