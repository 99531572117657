import { Box, Flex, Text } from "@chakra-ui/react";
import { Black } from "../../../Styles/HeadversityStyle";
import { AlternativeButton } from "../../Common/AlternativeButton";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import React from "react";

interface PollMultiSelectInputProps {
  questionText: string;
  instruction?: string;
  alternatives: { id: number; responseText: string }[];
  currentSelectedAnswers: number[];
  submitPollResponse: () => void;
  setCurrentSelectedAnswers: (value: number[]) => void;
  disableSelection: boolean;
  disableIamDone: boolean;
  loading: boolean;
  doneButtonMessage?: string;
  questionTextAlignment?: string;
  questionTextFontWeight?: string;
  questionTextColor?: string;
  submitButtonColorSet?: AnimatedButtonColorSet;
}
export const PollMultiSelectInput = (props: PollMultiSelectInputProps) => {
  const {
    questionText,
    instruction,
    alternatives,
    currentSelectedAnswers,
    submitPollResponse,
    setCurrentSelectedAnswers,
    disableSelection,
    disableIamDone,
    loading,
    doneButtonMessage,
    questionTextAlignment,
    questionTextFontWeight,
    questionTextColor,
    submitButtonColorSet,
  } = props;

  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      justify={"start"}
      gap={"5"}
      w={"100%"}
    >
      <Text
        color={questionTextColor ?? Black}
        alignSelf={questionTextAlignment ?? "flex-start"}
        fontWeight={questionTextFontWeight}
      >
        {questionText}
      </Text>
      {instruction && (
        <Text alignSelf={"flex-start"} color={Black} fontSize={"sm"}>
          {instruction}
        </Text>
      )}
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"start"}
        gap={"5"}
        my={"3"}
        w={"100%"}
      >
        {alternatives?.map((questionAlternative) => (
          <AlternativeButton
            key={questionAlternative.id}
            disabled={
              (disableSelection || loading) &&
              !currentSelectedAnswers.includes(questionAlternative.id)
            }
            questionId={questionAlternative.id}
            alternativeId={questionAlternative.id}
            selected={currentSelectedAnswers.includes(questionAlternative.id)}
            alternativeName={questionAlternative.responseText}
            setSelectedResponse={() => {
              if (currentSelectedAnswers.includes(questionAlternative.id)) {
                setCurrentSelectedAnswers(
                  currentSelectedAnswers.filter(
                    (item) => item !== questionAlternative.id
                  )
                );
              } else {
                setCurrentSelectedAnswers([
                  ...currentSelectedAnswers,
                  questionAlternative.id,
                ]);
              }
            }}
            mode="checker"
            dataTestId={`${HVTestId.PollQuestion.alternativeButton}${questionAlternative.id}`}
          />
        ))}
      </Flex>
      <Box mt="8px" mb="20px">
        <AnimatedButton
          disabled={disableIamDone || loading}
          colorSet={submitButtonColorSet ?? AnimatedButtonColorSet.Primary}
          w={150}
          text={doneButtonMessage ?? HVLocalizeStrings.DONE}
          onClick={submitPollResponse}
          dataTestId={HVTestId.PollQuestion.submitButton}
        />
      </Box>
    </Flex>
  );
};
