import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { UserDto } from "../../../../@headversity/contract";

export const useGroupInformation = () => {
  const { self, currentParticipantList, currentTeam } =
    useContext<IGlobalProvider>(GlobalContext);

  const [isGroupLead, setIsGroupLead] = useState<boolean | undefined>(false);

  const [currentGroup, setCurrentGroup] = useState<string | undefined>(
    undefined
  );

  const [currentGroupUserIds, setCurrentGroupUserIds] = useState<
    number[] | undefined
  >([]);

  const [currentGroupReporterId, setCurrentGroupReporterId] = useState<
    number | undefined
  >(undefined);

  const [currentGroupUsers, setCurrentGroupUsers] = useState<UserDto[]>([]);

  useEffect(() => {
    if (self && currentParticipantList && currentTeam) {
      const user = currentParticipantList.find((u) => u.userId === self.id);

      const userIds = currentParticipantList
        .filter((u) => u.groupName === user?.groupName)
        .map((u) => u.userId);
      setIsGroupLead(user?.isGroupLead);
      setCurrentGroup(user?.groupName);
      setCurrentGroupUserIds(userIds);

      const currentReporter = currentParticipantList.find(
        (u) => u.groupName === user?.groupName && u.isGroupLead
      );

      if (currentReporter) {
        setCurrentGroupReporterId(currentReporter.userId);
      }

      const users = currentParticipantList
        .filter((participant) => userIds.indexOf(participant.userId) > -1)
        .map((participant) => participant.user);

      setCurrentGroupUsers(users);
    }
  }, [currentParticipantList, self, currentTeam]);

  return {
    isGroupLead,
    currentGroupReporterId,
    currentGroupUserIds,
    currentGroup,
    currentGroupUsers,
  };
};
