import { Box, Text } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { DESTRUCTIVE_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { HvTextInput } from "../../Common/HvTextInput";
import { TeamLogoUploader } from "../../Team/Shared/TeamLogoUploader";
import { useSportTeam } from "../../../Hooks/Team/useSportTeam";
import { GlobalContext } from "../../../State/GlobalContext";
import { HvSpinner } from "../../Common/HvSpinner";
import { BottomActionBar } from "../../Common/BottomActionBar";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";

export interface SportTeamEditProps {
  onContinue: () => void;
  isOnboarding?: boolean;
}
export const SportTeamEdit = ({
  onContinue,
  isOnboarding,
}: SportTeamEditProps) => {
  const [isNameValid, setIsNameValid] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamLogoUrl, setTeamLogoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const { currentTeam } = useContext(GlobalContext);
  const [isDirty, setIsDirty] = useState(false);
  // Store initial values
  const [initialTeamName, setInitialTeamName] = useState("");
  const [initialTeamLogoUrl, setInitialTeamLogoUrl] = useState("");

  const manageTeam = useSportTeam();

  useEffect(() => {
    let initialName = "";
    if (currentTeam) {
      // detect if currentTeam.name is "Placeholder", do not show that
      if (currentTeam.name.toLowerCase() !== "placeholder") {
        initialName = currentTeam.name;
      }
      setTeamName(initialName);
      setTeamLogoUrl(currentTeam.logo ? currentTeam.logo : "");

      setInitialTeamName(initialName);
      setInitialTeamLogoUrl(currentTeam.logo ? currentTeam.logo : "");
    }
  }, [currentTeam]);

  useEffect(() => {
    setIsNameValid(teamName.length > 0);

    // Check if the current values differ from the initial values
    if (teamName !== initialTeamName || teamLogoUrl !== initialTeamLogoUrl) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [teamName, teamLogoUrl, initialTeamName, initialTeamLogoUrl]);

  const isContinueEnabled = teamName.length > 0 && !isUploading && !isLoading;

  const onSaveTeam = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await manageTeam.saveTeam(currentTeam!.id, teamName, teamLogoUrl);
      onContinue();
    } finally {
      setIsLoading(false);
    }
  };

  const canContinue = isOnboarding
    ? isContinueEnabled
    : isContinueEnabled && isDirty;
  return (
    <>
      <Box h="500px" px={4} color="white">
        {isLoading && <HvSpinner />}
        <Box>
          <Text py={2}>{HVSportLocalizeStrings.CHARTER_TEAM_EDIT_WHAT_IS_NAME}</Text>
          <HvTextInput
            value={teamName}
            onChange={(e: any) => {
              setTeamName(e.target.value);
            }}
          />
          {teamName.length > 0 && !isNameValid && (
            <Text fontSize={"xs"} color={DESTRUCTIVE_TEXT_COLOR}>
              {HVSportLocalizeStrings.CHARTER_TEAM_NAME_INVALID}
            </Text>
          )}
        </Box>
        <Box mt={4}>
          <Text py={2}>{HVSportLocalizeStrings.CHARTER_TEAM_ADD_TEAM_LOGO}</Text>
          <TeamLogoUploader
            previewImageMb={"2"}
            startUrl={teamLogoUrl}
            imageUrlChanged={(url: string) => {
              setTeamLogoUrl(url);
            }}
            handleIsUploading={(isUploading: boolean) => {
              setIsUploading(isUploading);
            }}
          />
          <Text fontSize="sm" mt="2">
            {HVSportLocalizeStrings.CHARTER_TEAM_ADD_TEAM_LOGO_INSTRUCTIONS}
          </Text>
        </Box>
      </Box>
      <BottomActionBar
        isNextButtonDisabled={() => !canContinue}
        nextButtonText={HVLocalizeStrings.CONTINUE}
        onNextButtonClick={() => {
          onSaveTeam();
        }}
      />
    </>
  );
};
