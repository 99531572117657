import React, { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
} from "../../../../Styles/HeadversityStyle";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { PollQuestionDto } from "@headversity/contract";
import { PollSingleFreeTextInput } from "../../../Shared/FreeTextInput/PollSingleFreeTextInput";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { performHapticImpact } from "../../../../Api/Utils";
import { Flex } from "@chakra-ui/react";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";

export const ParticipantTeamValues = () => {
  const {
    setPollInstanceResultToServer,
    currentTeamLessonUserInstanceId,
    pollQuestions,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [currentTextAnswer, setCurrentTextAnswer] = useState<
    string | undefined
  >(undefined);

  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

  const [currentPollQuestion, setCurrentPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const { currentUserPollQuestionResults, currentPollInstanceId } =
    usePollQuestionInformation({
      pollQuestion: currentPollQuestion,
      byGroup: false,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
    });

  useEffect(() => {
    performHapticImpact();
  }, []);

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_1_PQ"
      );
      if (pollQuestion) {
        setCurrentPollQuestion(pollQuestion);
      }
    }
  }, [pollQuestions]);

  useEffect(() => {
    if (currentUserPollQuestionResults.length > 0) {
      setDisableSubmit(true);
      setCurrentTextAnswer("");
    }
  }, [currentUserPollQuestionResults]);

  if (!currentPollInstanceId) {
    return <></>;
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={false} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {currentUserPollQuestionResults.length < 1 ? (
        <>
          {currentPollQuestion && (
            <>
              <Flex alignSelf={"start"}>
                <BlockSectionTitle
                  title={HVSportLocalizeStrings.CHARTER_TEAM_VALUES_TITLE}
                  primaryTextColor={Black}
                  borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
                />
              </Flex>
              <PollSingleFreeTextInput
                disableSubmit={disableSubmit}
                setDisableSubmit={setDisableSubmit}
                questionText={
                  HVSportLocalizeStrings.CHARTER_TEAM_VALUES_QUESTION
                }
                currentTextAnswer={currentTextAnswer as any}
                setCurrentTextAnswer={setCurrentTextAnswer}
                submitPollResponse={() => {
                  let pollInstanceId = -1;
                  console.log(currentUserPollQuestionResults.length);
                  console.log(currentPollInstanceId);
                  if (
                    currentUserPollQuestionResults.length < 1 &&
                    currentPollInstanceId
                  ) {
                    pollInstanceId = currentPollInstanceId;
                  } else {
                    return;
                  }
                  setDisableSubmit(true);
                  setPollInstanceResultToServer(pollInstanceId, {
                    questionAlternativeIds: [],
                    feedback: "",
                    questionUserResponseIds: [],
                    freeTexts: [currentTextAnswer as string],
                  });
                }}
              />
            </>
          )}{" "}
        </>
      ) : (
        <ParticipantWaitingScreen />
      )}
    </ParticipantContainer>
  );
};
