import {
  Flex,
  ResponsiveValue,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { HVSportLocalizeStrings } from "../../../Localization/HVSportLocalizeString";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { useGroupInformation } from "../../Sport/Charter/Hooks/useGroupInformation";

interface ParticipantContainerProps {
  children: any;
  leftContent?: any;
  rightContent?: any;
  mt?: string;
  position?: ResponsiveValue<
    "absolute" | "fixed" | "relative" | "static" | "sticky"
  >;
  p?: string;
}
export const ParticipantContainer = (props: ParticipantContainerProps) => {
  const { children, rightContent, leftContent, mt, position, p } = props;
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      justify={"flex-start"}
      w={"100%"}
      h={"100%"}
      position={position ?? "fixed"}
      overflow={"auto"}
      top={"0px"}
      left={"0px"}
      zIndex={"3"}
      gap={"5"}
      mt={mt}
    >
      {(leftContent || rightContent) && (
        <Flex
          w={"100%"}
          h={"60px"}
          minH={"60px"}
          bg={"url(https://cdn.headversity.com/app/sport/bg-people-wide.jpg)"}
          fontWeight="500"
          flexDir={"row"}
          visibility={"visible"}
        >
          {leftContent && (
            <Flex
              w={"50%"}
              flexDir={"column"}
              align={"start"}
              justify={"center"}
              pl={"5"}
            >
              {leftContent}
            </Flex>
          )}
          {rightContent && (
            <Flex
              w={"50%"}
              flexDir={"column"}
              align={"end"}
              justify={"center"}
              pr={"5"}
            >
              {rightContent}
            </Flex>
          )}
        </Flex>
      )}
      <Flex
        flexDir={"column"}
        align={"center"}
        justify={"flex-start"}
        w={isMobile ? "100%" : "850px"}
        h={"100%"}
        gap={"5"}
        p={p ?? "5"}
      >
        {children}
      </Flex>
    </Flex>
  );
};

interface ParticipantActivityLabelProps {
  groupActivity: boolean;
}
export const ParticipantActivityLabel = (
  props: ParticipantActivityLabelProps
) => {
  const { groupActivity } = props;
  return (
    <Flex
      flexDir={"column"}
      justify={"center"}
      align={"start"}
      lineHeight={"1.2"}
    >
      <Text color={"white"}>
        {groupActivity
          ? HVSportLocalizeStrings.CHARTER_GROUP
          : HVSportLocalizeStrings.CHARTER_INDIVIDUAL}
      </Text>
      <Text color={"white"}>{HVSportLocalizeStrings.CHARTER_ACTIVITY}</Text>
    </Flex>
  );
};

export const ParticipantGroupLabel = () => {
  const { self } = useContext<IGlobalProvider>(GlobalContext);
  const { currentGroup } = useGroupInformation();
  return (
    <Flex flexDir={"column"} justify={"center"} align={"end"}>
      <Text color={"white"}>{self?.fullName}</Text>
      <Text color={"white"} fontWeight={"bold"}>
        {currentGroup}
      </Text>
    </Flex>
  );
};
