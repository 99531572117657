import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { peekRedirectPath } from "../../../Utils/NavigationUtils";
import { setLocalStorageAuthTokens } from "../../../Utils/LoginUtil";

export const SetAccessTokenPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const accessToken = searchParams.get("dt");
  const refreshToken = searchParams.get("refreshToken");

  useEffect(() => {
    if (accessToken && refreshToken) {
      setLocalStorageAuthTokens({
        access_token: accessToken,
        refresh_token: refreshToken,
      }).then(() => {
        // check the JWT payload to see if this user is being impersonated by an admin
        const base64Url = accessToken.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        const jwtPayload = JSON.parse(window.atob(base64));

        if ("impersonatingUserId" in jwtPayload.data) {
          localStorage.setItem(
            "impersonatingUserId",
            jwtPayload.data.impersonatingUserId
          );
        }

        const path = peekRedirectPath();
        navigate(path, { replace: true });
      });
    } else {
      navigate("/login", { replace: true });
    }
  }, [accessToken, refreshToken]);

  return null;
};
