import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { ContentBlock20 } from "./ContentBlock20";
import { MdGroups } from "react-icons/md";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  getPathForTeamLesson,
  getScrubbedTeamLessonDescription,
} from "../../../Utils/TeamUtil";
import {
  CompletionState,
  LocalizationText,
  PathDto,
  TeamLessonDto,
} from "@headversity/contract";
import { SkillDto } from "@headversity/contract";
import { HVTestId } from "../../../Testing/dataTestIds";

interface TeamTrainingBlockProps {
  teamLesson: TeamLessonDto;
  width?: string;
  height?: string;
  isSmall?: boolean;
  showDescription?: boolean;
  skill?: SkillDto | undefined;
  from: string;
  showPath?: boolean;
  hideIcon?: boolean;
}

export const TeamTrainingBlock = ({
  teamLesson,
  width,
  height,
  isSmall,
  showDescription,
  skill,
  from,
  showPath,
  hideIcon,
}: TeamTrainingBlockProps) => {
  const navigate = useNavigate();

  const {
    selectedUserLanguage,
    setIsFirstTimeTeamInterstitialModalOpen,
    setSelectedTeamLessonUrl,
    paths,
    currentTeam,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [shortDescription, setShortDescription] = useState<
    string | LocalizationText
  >("");

  const [path, setPath] = useState<PathDto>();

  useEffect(() => {
    setShortDescription(teamLesson?.shortDescription);
  }, [teamLesson, selectedUserLanguage]);

  useEffect(() => {
    setPath(getPathForTeamLesson(teamLesson, paths, currentTeam));
  }, [paths, currentTeam]);

  return showPath && !path ? (
    <></>
  ) : (
    <ContentBlock20
      imageUrl={teamLesson?.imageUrl as string}
      description={
        showDescription && shortDescription
          ? shortDescription
          : showDescription
          ? getScrubbedTeamLessonDescription(selectedUserLanguage, teamLesson)
          : undefined
      }
      typeTitle={
        showPath
          ? (path?.name as string)
          : showDescription
          ? HVLocalizeStrings.TEAM_TRAINING
          : undefined
      }
      showTypeTitle={!hideIcon || showPath}
      iconType={!hideIcon ? MdGroups : undefined}
      width={width}
      height={height}
      dataTestId={`${HVTestId.TeamTrainingBlock.teamTrainingBlock}_${teamLesson?.id}`}
      onClick={() => {
        const url = `/team/paths?pathId=${path?.id}&teamLessonId=${teamLesson?.id}`;
        if (
          from !== "TEAM Home" &&
          localStorage.getItem("viewed-first-time-team-interstitial-modal") ===
            null
        ) {
          setSelectedTeamLessonUrl(url);
          setIsFirstTimeTeamInterstitialModalOpen(true);
        } else {
          let event = {
            HV_Presentation_Id: teamLesson?.id,
            HV_Presentation_Title: teamLesson?.name,
            HV_From: from,
          } as object;
          if (skill) {
            event = {
              ...event,
              HV_SkillId: skill.id,
              HV_SkillName: skill.slug,
            };
          }
          track(EVENTS.TeamTrainingOpened, event);

          navigate(url);
        }
      }}
      isSmall={isSmall}
      doneState={CompletionState.notStarted}
      borderTopRadius={"10px"}
    />
  );
};
