import {
  CompanyDto,
  InviteCodeDto,
  UnsubscribeEmailResultDto,
} from "@headversity/contract";
import { AxiosClient } from "../AxiosClient";
import { getConfig } from "../Utils";
import { AxiosResponse } from "axios";

export const getUserCompany = (
  token: string
): Promise<AxiosResponse<CompanyDto>> => {
  return AxiosClient.get("/api/self/company", getConfig(token));
};

export const getUserInviteCode = (
  token: string
): Promise<AxiosResponse<InviteCodeDto>> => {
  return AxiosClient.get("/api/self/inviteCode", getConfig(token));
};

export const updateCommunicationSettingsAllowedDomains = (
  token: string,
  isChecked: boolean
): Promise<AxiosResponse<void>> => {
  const route = `/api/currentCompany/communicationSettings${
    isChecked ? `?communicationSettings=checked` : ""
  }`;
  return AxiosClient.put(route, {}, getConfig(token));
};

export const enableEmailSubscriptions = (
  token: string
): Promise<AxiosResponse<UnsubscribeEmailResultDto>> => {
  return AxiosClient.post("/api/self/subscribeEmails", {}, getConfig(token));
};
