import React, { useContext, useEffect } from "react";
import {
  TEAM_LESSON_PROGRESS_STEP,
  PresentationStepProps,
} from "./PresentationModal";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../Common/AnimatedButton";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { Flex, useBreakpointValue } from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import { PresentationLobbyView } from "../Shared/PresentationLobbyView";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { ConfirmationButtons } from "../../Common/ConfirmationDialog";
import { TEAM_EVENTS, track } from "../../../Utils/Analytics";
import { trackTeamLesson } from "../../../Utils/TeamUtil";
import { PathTeamLessonDto } from "@headversity/contract";

interface PresentationLobbyStepProps extends PresentationStepProps {
  setOpen: (open: boolean) => void;
  pathTeamLesson?: PathTeamLessonDto;
}

export const PresentationLobbyStep = (props: PresentationLobbyStepProps) => {
  const { setCurrentStep, teamLesson, setOpen, pathTeamLesson } = props;
  const {
    setTeamLessonUserInstanceInProgressToServer,
    setTeamLessonUserInstanceCancelToServer,
    currentTeamLessonUserInstanceId,
    getTeamLessonUserInstanceParticipantsFromServer,
    currentTeam,
  } = useContext<IGlobalProvider>(GlobalContext);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);

  useEffect(() => {
    if (currentTeamLessonUserInstanceId) {
      getTeamLessonUserInstanceParticipantsFromServer(
        currentTeamLessonUserInstanceId
      );
    }
  }, [currentTeamLessonUserInstanceId]);

  return (
    <>
      <PresentationLobbyView
        mode={"Presenter"}
        teamLesson={teamLesson}
        additionalSection={
          <Flex
            mt={isMobile ? 2 : 6}
            align={"center"}
            gap={"3"}
            flexDir={["column", "column", "row"]}
          >
            <AnimatedButton
              text={HVLocalizeStrings.START_TEAM_LESSON}
              w={219}
              onClick={() => {
                if (currentTeamLessonUserInstanceId) {
                  setTeamLessonUserInstanceInProgressToServer(
                    currentTeamLessonUserInstanceId
                  ).then(() => {
                    trackTeamLesson(
                      TEAM_EVENTS.StartPresentation,
                      currentTeam,
                      pathTeamLesson
                    );
                    setCurrentStep(TEAM_LESSON_PROGRESS_STEP);
                  });
                }
              }}
            ></AnimatedButton>

            <AnimatedButton
              colorSet={AnimatedButtonColorSet.Third}
              text={HVLocalizeStrings.CANCEL}
              w={219}
              onClick={() => {
                confirmationDialogRef.current.confirmation(
                  HVLocalizeStrings.TEAM_TEAM_LESSON_CANCEL_TITLE,
                  HVLocalizeStrings.TEAM_TEAM_LESSON_CANCEL_DESCRIPTION,
                  ConfirmationButtons.YesNo,
                  () => {
                    track(TEAM_EVENTS.CancelPresentation, {
                      HV_Team_Presentation_Session_Id:
                        currentTeamLessonUserInstanceId,
                    });
                    if (currentTeamLessonUserInstanceId) {
                      setTeamLessonUserInstanceCancelToServer(
                        currentTeamLessonUserInstanceId
                      ).then(() => {
                        setOpen(false);
                      });
                    }
                  }
                );
              }}
            ></AnimatedButton>
          </Flex>
        }
      />
    </>
  );
};
