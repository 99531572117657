import { useContext, useEffect, useState } from "react";
import { PollQuestionDto } from "../../../../@headversity/contract";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";

interface useBestWorstInitQuestionProps {
  bestPollQuestionInternalTitle: string;
  worstPollQuestionInternalTitle: string;
}
export const useBestWorstInitQuestion = (
  props: useBestWorstInitQuestionProps
) => {
  const { pollQuestions } = useContext<IGlobalProvider>(GlobalContext);

  const { bestPollQuestionInternalTitle, worstPollQuestionInternalTitle } =
    props;

  const [bestPollQuestion, setBestPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  const [worstPollQuestion, setWorstPollQuestion] = useState<
    PollQuestionDto | undefined
  >(undefined);

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestionBest = pollQuestions.find(
        (pq) => pq.internalTitle === bestPollQuestionInternalTitle
      );
      const pollQuestionWorst = pollQuestions.find(
        (pq) => pq.internalTitle === worstPollQuestionInternalTitle
      );
      if (!pollQuestionBest || !pollQuestionWorst) {
        return;
      }
      setBestPollQuestion(pollQuestionBest);
      setWorstPollQuestion(pollQuestionWorst);
    }
  }, [pollQuestions]);

  return { bestPollQuestion, worstPollQuestion };
};
