import {
  Box,
  Center,
  Flex,
  Icon,
  Link,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  CharterActivity,
  PathDto,
  PathTeamLessonDto,
  TeamDto,
  TeamLessonInstanceInputDto,
  TeamLessonUserInstanceStatus,
} from "@headversity/contract";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { CiCalendar } from "react-icons/ci";
import { HVLocalizeStrings } from "../../Localization/HVLocalizeStrings";
import { HVSportLocalizeStrings } from "../../Localization/HVSportLocalizeString";
import { GlobalContext, IGlobalProvider } from "../../State/GlobalContext";
import { IShellProvider, ShellContext } from "../../State/ShellContext";
import { Border_Radius } from "../../Styles/HeadversityStyle";
import { TEAM_EVENTS } from "../../Utils/Analytics";
import { trackTeamLesson } from "../../Utils/TeamUtil";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../Common/AnimatedButton";
import { BottomActionBar } from "../Common/BottomActionBar";
import { LargeModal } from "../Common/LargeModal";
import { ErrorText } from "../People/Shared/ErrorText";
import { ActivationDateTimePicker } from "../Reach/SelfService/UserModal/ActivationDateTimePicker";
import {
  TEAM_LESSON_INITIALIZE_STEP,
  TEAM_LESSON_PROGRESS_STEP,
} from "../Team/Presentation/PresentationModal";
import { CharterDisplay } from "./Charter/CharterEditor/CharterDisplay";

interface CharterBoxProps {
  charterPath: PathDto | undefined;
  futureStartDate: DateTime | undefined;
  currentTeam: TeamDto | undefined;
  isLeader: boolean;
}

export const CharterBox = ({
  charterPath,
  futureStartDate,
  currentTeam,
  isLeader,
}: CharterBoxProps): JSX.Element => {
  const {
    setPresenterModalOpen,
    setPresenterPathTeamLesson,
    setPresentationStep,
    setTeamLessonUserInstanceInitializeScheduledToServer,
    setParticipateForTeamLessonUserInstanceToServer,
    setParticipantModalOpen,
    updateParticipantTeamLesson,
    setCurrentTeamLessonUserInstanceId,
    startPresentOrParticipate,
    getPollsFromServer,
    getPollInstancesFromServer,
    setTeamLessonInProgressStepToServer,
  } = useContext(GlobalContext);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [charterModalOpen, setCharterModalOpen] = useState(false);
  const { confirmationDialogRef } = useContext<IShellProvider>(ShellContext);
  const [presentButtonText, setPresentButtonText] = useState<string>();
  const [showPresentButton, setShowPresentButton] = useState<boolean>(false);
  const [showEditLink, setShowEditLink] = useState<boolean>(false);
  const [pollDataLoading, setPollDataLoading] = useState<boolean>(false);

  // can start when futureStartDate is less than 3 hours away
  const scheduledSessionCanStart =
    futureStartDate !== undefined && futureStartDate.diffNow("hours").hours < 3;

  let charterStatusText = "";
  if (futureStartDate) {
    if (scheduledSessionCanStart) {
      charterStatusText = isLeader
        ? ""
        : HVSportLocalizeStrings.CHARTER_ITS_TIME;
    } else {
      charterStatusText = HVSportLocalizeStrings.CHARTER_APPROACHING;
    }
  }
  if (
    charterPath?.pathTeamLessons?.[0]?.teamLessonInstanceStatus !== undefined &&
    charterPath?.pathTeamLessons?.[0]?.teamLessonInstanceStatus <
      TeamLessonUserInstanceStatus.Complete
  ) {
    charterStatusText = isLeader ? "" : HVSportLocalizeStrings.CHARTER_ITS_TIME; // it's time
  }

  const isComplete =
    charterPath?.pathTeamLessons[0]?.teamLessonInstanceStatus &&
    [
      TeamLessonUserInstanceStatus.Complete,
      TeamLessonUserInstanceStatus.CompletedForParticipants,
      TeamLessonUserInstanceStatus.ForcedComplete,
      TeamLessonUserInstanceStatus.Catchup,
    ].includes(charterPath.pathTeamLessons[0].teamLessonInstanceStatus);

  if (isComplete) {
    charterStatusText = "Your 2024-25 Team Charter is complete!";
  }

  const openPresenterModal = (pathTeamLesson: PathTeamLessonDto) => {
    setPresenterPathTeamLesson(pathTeamLesson);
    setPresentationStep(
      [
        TeamLessonUserInstanceStatus.Initialized,
        TeamLessonUserInstanceStatus.Scheduled,
      ].includes(
        pathTeamLesson.teamLessonInstanceStatus as TeamLessonUserInstanceStatus
      )
        ? TEAM_LESSON_INITIALIZE_STEP
        : TEAM_LESSON_PROGRESS_STEP
    );
    setPresenterModalOpen(true);
  };

  useEffect(() => {
    setShowPresentButton(
      scheduledSessionCanStart &&
        charterPath !== undefined &&
        charterPath.pathTeamLessons[0].teamLessonInstanceStatus !== undefined &&
        (isLeader ||
          ([
            TeamLessonUserInstanceStatus.Initialized,
            TeamLessonUserInstanceStatus.InProgress,
          ].includes(charterPath.pathTeamLessons[0].teamLessonInstanceStatus) &&
            charterPath.pathTeamLessons[0]?.inProgressStep !==
              CharterActivity.Paused))
    );
    setShowEditLink(
      charterPath !== undefined &&
        isLeader &&
        charterPath.pathTeamLessons[0].teamLessonInstanceStatus ===
          TeamLessonUserInstanceStatus.Scheduled
    );

    if (charterPath) {
      if (
        charterPath.pathTeamLessons[0]?.inProgressStep ===
        CharterActivity.Paused
      ) {
        setPresentButtonText(
          HVSportLocalizeStrings.CHARTER_RESUME_PRESENTATION
        );
      } else if (
        [
          TeamLessonUserInstanceStatus.Initialized,
          TeamLessonUserInstanceStatus.InProgress,
          TeamLessonUserInstanceStatus.CompletedForParticipants,
        ].includes(
          charterPath.pathTeamLessons[0]
            .teamLessonInstanceStatus as TeamLessonUserInstanceStatus
        )
      ) {
        setPresentButtonText(HVSportLocalizeStrings.CHARTER_JOIN_PRESENTATION);
      } else {
        setPresentButtonText(HVSportLocalizeStrings.CHARTER_START_PRESENTATION);
      }
    }
  }, [charterPath, isLeader]);

  return (
    <Box
      bg="white"
      border={"1px solid #ccc"}
      p={4}
      borderRadius={Border_Radius}
      boxShadow="md"
      h={{ base: undefined, lg: "225px" }}
    >
      <Text lineHeight={"1.3"} mb={2}>
        {charterStatusText}
      </Text>

      {currentTeam !== undefined &&
        charterPath !== undefined &&
        charterPath.pathTeamLessons[0].teamLessonInstanceStatus !==
          undefined && (
          <>
            {isComplete ? (
              <Flex flexDir={"column"} m="10px">
                <AnimatedButton
                  text={HVSportLocalizeStrings.CHARTER_VIEW_CHARTER}
                  onClick={() => {
                    trackTeamLesson(
                      TEAM_EVENTS.ViewCharter,
                      currentTeam,
                      charterPath.pathTeamLessons[0],
                      undefined,
                      {
                        HV_Team_Presentation_Session_Id:
                          charterPath.pathTeamLessons[0]
                            .teamLessonUserInstanceId,
                      }
                    );

                    setPollDataLoading(true);
                    Promise.all([
                      getPollsFromServer(
                        charterPath.pathTeamLessons[0].teamLessonId
                      ),
                      getPollInstancesFromServer(),
                    ]).finally(() => {
                      setPollDataLoading(false);
                    });

                    setCharterModalOpen(true);
                  }}
                  colorSet={AnimatedButtonColorSet.Third}
                  mt={4}
                />

                {/* {isLeader && (
                  <Link
                    textDecoration={"underline"}
                    href="#"
                    onClick={(e) => {
                      setScheduleModalOpen(true);
                      e.preventDefault();
                    }}
                    mt={3}
                    pl={"2px"}
                  >
                    {HVSportLocalizeStrings.CHARTER_SCHEDULE_ANOTHER}
                  </Link>
                )} */}
              </Flex>
            ) : (
              <Flex flexDir={"row"} mb="20px" gap="2">
                <Box
                  position="relative"
                  display="inline-block"
                  w="80px"
                  h="80px"
                  flexShrink={0} // Prevent the box from shrinking in a Flex container
                >
                  <Icon as={CiCalendar} w={"80px"} h={"80px"} />
                  {futureStartDate && (
                    <Text
                      position="absolute"
                      top="60%" // Adjust this if needed to fine-tune the vertical position
                      left="50%"
                      transform="translate(-50%, -50%)"
                      fontSize="16px" // Adjust font size as needed
                      fontWeight="bold"
                    >
                      {futureStartDate.toFormat("dd")}
                    </Text>
                  )}
                </Box>

                <Flex flexDir={"column"} mt="5px">
                  {[
                    TeamLessonUserInstanceStatus.Scheduled,
                    TeamLessonUserInstanceStatus.Initialized,
                    TeamLessonUserInstanceStatus.InProgress,
                  ].includes(
                    charterPath.pathTeamLessons[0].teamLessonInstanceStatus
                  ) &&
                    futureStartDate !== undefined && (
                      <>
                        <Text mt="10px">
                          {futureStartDate.toFormat(
                            HVSportLocalizeStrings.CHARTER_SCHEDULE_DATE
                          )}
                        </Text>

                        {showEditLink && (
                          <Link
                            w="fit-content"
                            textDecoration={"underline"}
                            href="#"
                            onClick={(e) => {
                              setScheduleModalOpen(true);
                              e.preventDefault();
                            }}
                          >
                            {HVLocalizeStrings.EDIT}
                          </Link>
                        )}

                        {showPresentButton && isLeader && (
                          <AnimatedButton
                            text={presentButtonText}
                            onClick={() => {
                              if (charterPath) {
                                trackTeamLesson(
                                  TEAM_EVENTS.InitializeScheduledCharter,
                                  currentTeam,
                                  charterPath.pathTeamLessons[0],
                                  undefined,
                                  {
                                    HV_Team_Presentation_Session_Id:
                                      charterPath.pathTeamLessons[0]
                                        .teamLessonUserInstanceId,
                                  }
                                );
                                if (!isLeader) {
                                  setParticipateForTeamLessonUserInstanceToServer(
                                    charterPath.pathTeamLessons[0]
                                      .teamLessonUserInstanceId as number
                                  ).then(() => {
                                    setCurrentTeamLessonUserInstanceId(
                                      charterPath.pathTeamLessons[0]
                                        .teamLessonUserInstanceId as number
                                    );
                                    setParticipantModalOpen(true);
                                    updateParticipantTeamLesson(
                                      charterPath.pathTeamLessons[0]
                                    );
                                  });
                                } else if (
                                  charterPath.pathTeamLessons[0]
                                    ?.inProgressStep === CharterActivity.Paused
                                ) {
                                  setTeamLessonInProgressStepToServer(
                                    charterPath.pathTeamLessons[0]
                                      .teamLessonUserInstanceId as number,
                                    ""
                                  ).then(() => {
                                    setCurrentTeamLessonUserInstanceId(
                                      charterPath.pathTeamLessons[0]
                                        .teamLessonUserInstanceId as number
                                    );
                                    openPresenterModal(
                                      charterPath.pathTeamLessons[0]
                                    );
                                  });
                                } else if (
                                  [
                                    TeamLessonUserInstanceStatus.Initialized,
                                    TeamLessonUserInstanceStatus.InProgress,
                                    TeamLessonUserInstanceStatus.CompletedForParticipants,
                                  ].includes(
                                    charterPath.pathTeamLessons[0]
                                      .teamLessonInstanceStatus as TeamLessonUserInstanceStatus
                                  )
                                ) {
                                  startPresentOrParticipate(
                                    charterPath.pathTeamLessons[0],
                                    confirmationDialogRef,
                                    (_currentStep: string) => {
                                      openPresenterModal(
                                        charterPath.pathTeamLessons[0]
                                      );
                                    },
                                    () => {
                                      setPresenterModalOpen(false);
                                    },
                                    true
                                  );
                                } else {
                                  setTeamLessonUserInstanceInitializeScheduledToServer(
                                    charterPath.pathTeamLessons[0]
                                      .teamLessonUserInstanceId as number
                                  ).then(() => {
                                    openPresenterModal(
                                      charterPath.pathTeamLessons[0]
                                    );
                                  });
                                }
                              }
                            }}
                            mt={4}
                          />
                        )}
                      </>
                    )}
                  {![
                    TeamLessonUserInstanceStatus.Scheduled,
                    TeamLessonUserInstanceStatus.Initialized,
                    TeamLessonUserInstanceStatus.InProgress,
                    TeamLessonUserInstanceStatus.Complete,
                    TeamLessonUserInstanceStatus.ForcedComplete,
                    TeamLessonUserInstanceStatus.Catchup,
                  ].includes(
                    charterPath.pathTeamLessons[0].teamLessonInstanceStatus
                  ) && (
                    <>
                      {isLeader ? (
                        <Link
                          textDecoration={"underline"}
                          href="#"
                          onClick={(e) => {
                            setScheduleModalOpen(true);
                            e.preventDefault();
                          }}
                          mt={"2px"}
                          pl={"2px"}
                        >
                          {HVSportLocalizeStrings.CHARTER_SCHEDULE}
                        </Link>
                      ) : (
                        <Text pl={"2px"} mt="5px">
                          {HVSportLocalizeStrings.CHARTER_COMING_SOON}
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
              </Flex>
            )}
            <ScheduleCharterModal
              open={scheduleModalOpen}
              setOpen={setScheduleModalOpen}
              pathTeamLessonId={charterPath.pathTeamLessons[0].id}
              team={currentTeam}
              charterPath={charterPath}
              defaultDate={
                futureStartDate && futureStartDate > DateTime.now()
                  ? futureStartDate.toJSDate()
                  : undefined
              }
            />
            <CharterDisplayModal
              open={charterModalOpen}
              setOpen={setCharterModalOpen}
              charterPath={charterPath}
              pollDataLoading={pollDataLoading}
            />
          </>
        )}
    </Box>
  );
};

interface ScheduleCharterModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  pathTeamLessonId: number;
  team: TeamDto;
  charterPath: PathDto;
  defaultDate: Date | undefined;
}
const ScheduleCharterModal = ({
  open,
  setOpen,
  pathTeamLessonId,
  team,
  charterPath,
  defaultDate,
}: ScheduleCharterModalProps): JSX.Element => {
  const [charterDateTime, setCharterDateTime] = useState<Date | undefined>(
    defaultDate
  );
  const { setTeamLessonUserInstanceScheduleToServer } =
    useContext<IGlobalProvider>(GlobalContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showScheduleError, setShowScheduleError] = useState<boolean>(false);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (charterPath.pathTeamLessons[0].futureStartDate !== undefined) {
      setCharterDateTime(
        new Date(charterPath.pathTeamLessons[0].futureStartDate as string)
      );
    }
  }, [charterPath]);

  const handleCloseModalClick = async () => {
    setOpen(false);
    setShowScheduleError(false);
    setCharterDateTime(defaultDate);
  };

  const handleCharterDateTimeChanged = (date?: Date) => {
    const now = new Date();
    const fiveMinutesAgo = new Date(now.getTime() - 5 * 60 * 1000);
    if (!date || date < fiveMinutesAgo) {
      setShowScheduleError(true);
    } else {
      setShowScheduleError(false);
    }
    setCharterDateTime(date);
  };

  const handleSaveSchedule = () => {
    if (!charterDateTime) {
      return;
    }
    setIsSaving(true);
    trackTeamLesson(
      TEAM_EVENTS.ScheduleCharterInstance,
      team,
      charterPath.pathTeamLessons[0]
    );
    const existingTeamLessonUserInstanceId =
      charterPath?.pathTeamLessons?.[0]?.teamLessonUserInstanceId;
    setTeamLessonUserInstanceScheduleToServer(
      pathTeamLessonId,
      team.id,
      {
        futureStartDate: DateTime.fromJSDate(charterDateTime),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      } as TeamLessonInstanceInputDto,
      existingTeamLessonUserInstanceId
    )
      .then(() => {
        setOpen(false);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

 return (
   <LargeModal
     open={open}
     setOpen={handleCloseModalClick}
     title={HVSportLocalizeStrings.CHARTER_SCHEDULE}
     extraPadHeader={true}
   >
     <Box mx={isDesktop ? "20px" : "10px"} mb={"80px"} lineHeight="1.3">
       {isDesktop ? (
         <>
           <Text mb={4}>
             {HVSportLocalizeStrings.CHARTER_SCHEDULE_MODAL_PARAGRAPH_1}
           </Text>
           <Text mb={4}>
             {HVSportLocalizeStrings.CHARTER_SCHEDULE_MODAL_PARAGRAPH_2}
           </Text>
         </>
       ) : (
         <Text mb={4}>
           {HVSportLocalizeStrings.CHARTER_SCHEDULE_MODAL_PARAGRAPH_SHORT}
         </Text>
       )}
       <ActivationDateTimePicker
         currentDate={charterDateTime}
         onChange={handleCharterDateTimeChanged}
         placeholder={""}
         minDate={new Date()}
       />
       {showScheduleError && (
         <ErrorText
           message={HVSportLocalizeStrings.CHARTER_SCHEDULE_INVALID_DATE}
           mt={1}
         />
       )}
       <BottomActionBar
         showOptions={false}
         nextButtonText={HVLocalizeStrings.SAVE}
         isNextButtonDisabled={() =>
           !charterDateTime || showScheduleError || isSaving
         }
         onNextButtonClick={handleSaveSchedule}
       />
     </Box>
   </LargeModal>
 );
};

interface CharterDisplayModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  charterPath: PathDto;
  pollDataLoading: boolean;
}

const CharterDisplayModal = ({
  open,
  setOpen,
  charterPath,
  pollDataLoading,
}: CharterDisplayModalProps): JSX.Element => {
  const { resetTeamSession } = useContext(GlobalContext);
  const handleCloseModalClick = async () => {
    resetTeamSession();
    setOpen(false);
  };

  return (
    <LargeModal
      open={open}
      setOpen={handleCloseModalClick}
      extraPadHeader={true}
    >
      <Flex justifyContent={"center"} mb={4}>
        {pollDataLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <CharterDisplay
            isEditor={false}
            teamLessonUserInstanceId={
              charterPath.pathTeamLessons?.[0]
                .teamLessonUserInstanceId as number
            }
          />
        )}
      </Flex>
    </LargeModal>
  );
};
