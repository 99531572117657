import { TeamDto, TeamUserRole } from "@headversity/contract";
import { saveTeam } from "../../Api/Team/TeamApi";
import { getKey } from "../../Utils/Helpers";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../State/GlobalContext";

export interface IUseSportTeammProvider {
  saveTeam: (teamId: number, name: string, logo: string) => void;
  isLeader: boolean;
}

export const useSportTeam = () => {
  const { currentTeam, self, getTeamsFromServer } = useContext(GlobalContext);

  const [isLeader, setIsLeader] = useState(false);
  useEffect(() => {
    if (currentTeam && self) {
      const isLeader =
        currentTeam.teamUsers.filter(
          (tu) => tu.userId === self.id && tu.role === TeamUserRole.Leader
        ).length > 0;
      setIsLeader(isLeader);
    }
  }, [currentTeam, self]);

  const editTeamHandler = useCallback(
    async (teamId: number, name: string, logo: string) => {
      const saveRes = await saveTeam(getKey(), {
        id: teamId,
        name: name,
        logo,
      } as unknown as TeamDto);

      await getTeamsFromServer(saveRes.data.code);
    },
    []
  );

  return {
    saveTeam: editTeamHandler,
    isLeader,
  };
};
