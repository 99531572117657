import { useContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  PollQuestionDto,
  QuestionUserResponseDto,
} from "../../../../@headversity/contract";
import {
  getLatestPollInstanceByPollQuestionId,
  getLatestPollInstanceResultByPollQuestionId,
} from "../../../../Utils/TeamUtil";

interface useCharterCountStatsProps {
  pollQuestion: PollQuestionDto | undefined;
  currentTeamLessonUserInstanceId: number | undefined;
  byGroup: boolean;
}
export const usePollQuestionInformation = (
  props: useCharterCountStatsProps
) => {
  const { self } = useContext<IGlobalProvider>(GlobalContext);
  const [count, setCount] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [pollQuestionResults, setPollQuestionResults] = useState<
    QuestionUserResponseDto[]
  >([]);

  const [groupPollQuestionResults, setGroupPollQuestionResults] = useState<
    QuestionUserResponseDto[]
  >([]);
  const [currentUserPollQuestionResults, setCurrentUserPollQuestionResults] =
    useState<QuestionUserResponseDto[]>([]);

  const [currentPollInstanceId, setCurrentPollInstanceId] = useState<
    number | undefined
  >(undefined);

  const { pollQuestion, byGroup, currentTeamLessonUserInstanceId } = props;

  const { currentParticipantList, pollInstanceResults, pollInstances } =
    useContext<IGlobalProvider>(GlobalContext);

  useEffect(() => {
    let uniqueGroup = [];
    if (currentParticipantList) {
      if (byGroup) {
        uniqueGroup = _.uniqBy(currentParticipantList, "groupName");
        setTotal(uniqueGroup.length);
      } else {
        setTotal(currentParticipantList.length);
      }
    }
  }, [currentParticipantList]);

  useEffect(() => {
    if (
      pollQuestion &&
      pollInstanceResults &&
      pollInstances &&
      self &&
      currentTeamLessonUserInstanceId
    ) {
      const pollInstance = getLatestPollInstanceByPollQuestionId(
        pollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId
      );

      if (!pollInstance) {
        return;
      }

      setCurrentPollInstanceId(pollInstance.id);
      let results = getLatestPollInstanceResultByPollQuestionId(
        pollQuestion.id,
        pollInstances,
        currentTeamLessonUserInstanceId,
        pollInstanceResults
      );

      if (!results) {
        results = pollInstance.questionUserResponses;
      }

      if (!results) {
        return;
      }

      setCurrentUserPollQuestionResults(
        results.filter((r) => r.userId === self?.id)
      );

      setCount(_.uniqBy(results, "userId").length);

      setPollQuestionResults(results);
      if (results.length >= total && total) {
        setDisableSubmit(false);
      }
    }
  }, [
    pollQuestion,
    pollInstanceResults,
    total,
    pollInstances,
    self,
    currentTeamLessonUserInstanceId,
  ]);

  useEffect(() => {
    if (currentParticipantList && pollQuestionResults && self && byGroup) {
      const groupName = currentParticipantList.find(
        (p) => p.userId === self.id
      )?.groupName;

      if (groupName) {
        const currentGroupUsers = currentParticipantList
          .filter((p) => p.groupName === groupName)
          .map((c) => c.userId);

        setGroupPollQuestionResults(
          pollQuestionResults.filter((r) =>
            currentGroupUsers.includes(r.userId)
          )
        );
      }
    }
  }, [
    self,
    currentParticipantList,
    currentTeamLessonUserInstanceId,
    byGroup,
    pollQuestionResults,
  ]);

  return {
    count,
    total,
    currentPollInstanceId,
    disableSubmit,
    setDisableSubmit,
    pollQuestionResults,
    currentUserPollQuestionResults,
    groupPollQuestionResults,
  };
};
