import {
  Box,
  Divider,
  Flex,
  Icon,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  Border_Radius,
} from "../../../../Styles/HeadversityStyle";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { IconType } from "react-icons";

interface PresentationContentProps {
  title?: string;
  description?: string | JSX.Element;
  listItems?: { icon: IconType | number; text: string }[];
  hr?: boolean;
  hrBorder?: string;
  children?: any;
  maxW?: string;
  p?: number;
  pt?: number;
  boxShadow?: string;
  w?: string;
  descriptionMb?: string;
  descriptionMt?: string;
  titleMl?: string;
  descriptionHeight?: string;
}

export const PresentationContent = (props: PresentationContentProps) => {
  const {
    title,
    description,
    listItems,
    hr = true,
    hrBorder,
    children,
    maxW,
    p,
    pt,
    boxShadow,
    w,
    descriptionMb,
    descriptionMt,
    titleMl,
    descriptionHeight,
  } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });
  let padding = isMobile ? 6 : 12;
  padding = p ?? padding;
  let width = isMobile ? "100%" : "900px";
  width = w ?? width;

  return (
    <Flex
      flexDirection={"column"}
      width={width}
      maxW={maxW ?? undefined}
      min-height={"591px"}
      backgroundColor={"white"}
      p={padding}
      pt={pt}
      fontSize={"13px"}
      borderRadius={Border_Radius}
      boxShadow={boxShadow ?? "lg"}
      position={"relative"}
      mb={"20px"}
      lineHeight="1.3"
    >
      {title && (
        <BlockSectionTitle
          title={"Team Charter - " + title}
          primaryTextColor={Black}
          borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          marginLeft={titleMl}
        />
      )}
      {description && typeof description === "string" ? (
        <Text
          fontSize={"16px"}
          mb={descriptionMb ?? "5"}
          mt={descriptionMt ?? "5"}
          ml={titleMl}
          dangerouslySetInnerHTML={{ __html: description }}
          minHeight={descriptionHeight}
        />
      ) : description ? (
        <Box
          fontSize={"16px"}
          mb={descriptionMb ?? "5"}
          mt={descriptionMt ?? "10"}
          ml={titleMl}
          minHeight={descriptionHeight}
        >
          {description}
        </Box>
      ) : null}
      {hr && (
        <Divider
          my={"25px"}
          border={`${hrBorder} !important`}
          borderTop={"0 !important"}
          opacity={"1"}
        />
      )}
      {listItems && (
        <Flex flexDir={"column"} gap={2}>
          {listItems?.map((listItem) => (
            <Flex
              alignItems={"center"}
              key={listItem.text}
              fontSize={isMobile ? "14px" : "15px"}
              gap={2}
            >
              {listItem.icon && typeof listItem.icon === "number" ? (
                <Flex
                  height={"25px"}
                  width={"25px"}
                  borderRadius={"50%"}
                  border={"2px solid"}
                  mr={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text fontWeight={"bold"}>{listItem.icon}</Text>
                </Flex>
              ) : (
                <Icon
                  as={listItem.icon as IconType}
                  w={"25px"}
                  h={"25px"}
                  color={"black"}
                  mr={1}
                />
              )}
              <Text>{listItem.text}</Text>
            </Flex>
          ))}
        </Flex>
      )}
      {children}
    </Flex>
  );
};
