import { Box, Button } from "@chakra-ui/react";
import { PresentationBlockContent } from "./PresentationBlockContent";
import { useContext } from "react";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { PRIMARY_TEXT_COLOR } from "../../../Styles/HeadversityStyle";
import { TEAM_EVENTS } from "../../../Utils/Analytics";
import { trackTeamLesson } from "../../../Utils/TeamUtil";
import { useNavigate } from "react-router-dom";
import { PathTeamLessonDto, TeamDto } from "@headversity/contract";

interface PresentationBlockProps {
  pathTeamLesson: PathTeamLessonDto;
  margin: any;
  customTitle?: string;
  team?: TeamDto;
}

export const PresentationBlock = (props: PresentationBlockProps) => {
  const { pathTeamLesson, margin, customTitle, team } = props;
  const { setPresenterPathTeamLesson, setPresenterModalOpen, setCurrentTeam } =
    useContext<IGlobalProvider>(GlobalContext);
  const navigate = useNavigate();

  return (
    <Box
      key={pathTeamLesson?.teamLesson.id}
      h={"290px"}
      w={"300px"}
      m={margin as any}
      position={"relative"}
      borderRadius={"10px"}
    >
      <Button
        className="button-block"
        onClick={() => {
          if (team) {
            setCurrentTeam(team);
          }
          trackTeamLesson(
            TEAM_EVENTS.ViewPresentationIntro,
            team,
            pathTeamLesson
          );
          navigate(
            `/team/paths?pathId=${pathTeamLesson?.pathId}&teamLessonId=${pathTeamLesson?.teamLesson.id}`
          );
          setPresenterPathTeamLesson(pathTeamLesson);
          setPresenterModalOpen(true);
        }}
      >
        <PresentationBlockContent
          image={pathTeamLesson.teamLesson?.imageUrl}
          imageHeight={"218px"}
          title={customTitle ?? pathTeamLesson.teamLesson.name}
          description={""}
          titleFontSize={"md"}
          descriptionFontSize={"sm"}
          contentHeight={"75px"}
          contentHeadingColor={PRIMARY_TEXT_COLOR}
          contentDescriptionColor={PRIMARY_TEXT_COLOR}
          contentBg={"white"}
          contentOpacity={"1"}
          additionalContent={<></>}
        />
      </Button>
    </Box>
  );
};
