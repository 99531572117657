import { useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  SlideFade,
  Icon,
  Fade,
  SkeletonText,
} from "@chakra-ui/react";
import { HVLocalizeStrings } from "../../../Localization/HVLocalizeStrings";
import {
  BADGE_MODAL_BACKGROUND,
  Border_Radius,
  SHIELD_MODAL_PRIMARY_TEXT_COLOR,
} from "../../../Styles/HeadversityStyle";
import { ResilienceSkill20Item } from "./ResilienceSkill20Item";
import { GlobalContext, IGlobalProvider } from "../../../State/GlobalContext";
import { LessonsScrollPanel } from "./LessonsScrollPanel";
import { NanoPracticesScrollPanel } from "./NanoPracticesScrollPanel";
import {
  getBadgeData,
  getSortedSkillsForUser,
  getUserSkillBySkillId,
} from "../../../Utils/SkillsUtil";
import { IShellProvider, ShellContext } from "../../../State/ShellContext";
import { TeamTrainingsScrollPanel } from "./TeamTrainingsScrollPanel";
import { NanoPracticeBlock } from "../NanoPractices/NanoPracticeBlock";
import { getNextUpPathTeamLessons } from "../../../Utils/TeamUtil";
import { FaRedo } from "react-icons/fa";
import { LessonBlock20 } from "../Lessons/LessonBlock20";
import { EVENTS, track } from "../../../Utils/Analytics";
import { HVTestId } from "../../../Testing/dataTestIds";
import {
  MicroLessonDto,
  SkillDto,
  NanoPracticeDto,
  PathTeamLessonDto,
  BlogPostDto,
  UserSkillV2Dto,
} from "@headversity/contract";
import {
  BadgeCardScrollPanel,
  BadgeData,
} from "../../Common/BadgesAndShields/BadgeCard";
import { DonutSize } from "./ResilienceSkill20Donut";
import { ScrollPanelBlock } from "../../Common/ScrollPanelBlock";
import { ScrollPanel } from "../../Common/ScrollPanel";

interface ResilienceSkill20PanelProps {
  skill?: SkillDto;
  activities?: NanoPracticeDto[] | MicroLessonDto[];
}

export const ResilienceSkill20Panel = ({
  skill,
  activities,
}: ResilienceSkill20PanelProps) => {
  const {
    setSelectedSkill,
    setGlobalHeadScanQuestionModalOpen,
    lessons,
    nanoPractices,
    paths,
    allResilienceFeedPosts,
    isUninterruptibleModalOpen,
    skills,
    userSkillStats,
    currentTeam,
    selectedGoal,
    clearGlobalActivities,
  } = useContext<IGlobalProvider>(GlobalContext);
  const { isMainContainerXL } = useContext<IShellProvider>(ShellContext);

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const [activitiesToShow, setActivitiesToShow] = useState<
    NanoPracticeDto[] | MicroLessonDto[]
  >([]);
  const [practicesToShow, setPracticesToShow] = useState<NanoPracticeDto[]>([]);
  const [lessonsToShow, setLessonsToShow] = useState<MicroLessonDto[]>([]);
  const [pathTeamLessons, setPathTeamLessons] = useState<PathTeamLessonDto[]>(
    []
  );
  const [blogPosts, setBlogPosts] = useState<BlogPostDto[]>([]);

  const [slideFadeIn, setSlideFadeIn] = useState(true);

  const refreshGUI = (forceRefresh?: boolean) => {
    // set skills, ordered by unlocked, goal, and relevance to the lowest scoring skill
    const skillsToUse = skill
      ? [skill]
      : getSortedSkillsForUser(skills, userSkillStats, selectedGoal);

    const skillIds = skillsToUse.map((x) => x.id);

    if (forceRefresh) {
      setSlideFadeIn(false);
    }

    setTimeout(() => {
      // override with input
      if (activities && activities.length > 0) {
        const p: NanoPracticeDto[] = [];
        const l: MicroLessonDto[] = [];

        for (const x of activities) {
          if ("lessonCourse" in x) {
            l.push(x as MicroLessonDto);
          } else {
            p.push(x as NanoPracticeDto);
          }
        }

        setPracticesToShow(p);
        setLessonsToShow(l);
        setActivitiesToShow(activities);
      }

      // set lessons
      if (skill && lessonsToShow.length === 0) {
        const sugLessons = lessons.filter((x) =>
          x.skillCompetencies.some((sc) => skillIds.includes(sc.skillId))
        );

        setLessonsToShow(sugLessons);
      } else if (!forceRefresh && lessonsToShow.length > 0) {
        // update state on existing
        const newLessons: MicroLessonDto[] = [];

        for (const l of lessonsToShow) {
          const lesson = lessons.find((x) => x.id === l.id);

          if (lesson) {
            newLessons.push(lesson);
          }
        }

        setLessonsToShow(newLessons);
      }

      if (skill) {
        // set TEAM teamLessons
        if (paths) {
          const pathTeamLessons = getNextUpPathTeamLessons(
            paths,
            currentTeam,
            skill
          );

          if (pathTeamLessons.length > 0) {
            setPathTeamLessons(pathTeamLessons);
          } else {
            setPathTeamLessons([]);
          }
        }

        // set blog posts
        if (forceRefresh || blogPosts.length === 0) {
          const postsToUse: BlogPostDto[] = [];
          for (const skillId of skillIds) {
            const posts = allResilienceFeedPosts.filter(
              (p) =>
                p.blogPostUserViews.length === 0 &&
                p.skillCompetencies.some((bc: any) => skillId === bc.skillId)
            );
            postsToUse.push(...posts);
          }
          setBlogPosts(postsToUse);
        }
      }

      // set nano-practices
      if (skill && practicesToShow.length === 0) {
        const sugPractices = nanoPractices.filter((x) =>
          x.skillCompetencies.some((sc) => skillIds.includes(sc.skillId))
        );

        setPracticesToShow(sugPractices);
      } else if (!forceRefresh && practicesToShow.length > 0) {
        // update state on existing
        for (let i = 0; i < practicesToShow.length; i++) {
          const practice = nanoPractices.find(
            (x) => x.id === practicesToShow[i].id
          );

          if (practice) {
            practicesToShow[i] = practice;
          }
        }

        setPracticesToShow([...practicesToShow]);
      }

      if (forceRefresh) {
        setSlideFadeIn(true);
      }
    }, 250);
  };

  useEffect(() => {
    if (isUninterruptibleModalOpen) return;

    if (
      lessons.length === 0 ||
      nanoPractices.length === 0 ||
      userSkillStats.length === 0 ||
      allResilienceFeedPosts.length === 0
    ) {
      return;
    }

    refreshGUI();
  }, [
    isUninterruptibleModalOpen,
    userSkillStats.length === 0,
    lessons,
    nanoPractices,
    allResilienceFeedPosts,
    paths,
  ]);

  useEffect(() => {
    refreshGUI(true);
  }, [activities]);

  const localData = localStorage.getItem("user_scores");
  const localUserSkills: UserSkillV2Dto[] = localData
    ? JSON.parse(localData)
    : [];
  const localUserSkill = localUserSkills.find(
    (localUserSkill) => skill?.id === localUserSkill.skillId
  );
  const skillStat = getUserSkillBySkillId(userSkillStats, skill?.id);
  const badgeData = [getBadgeData(skill, localUserSkill, skillStat)];

  const skillToDisplayUserSkill = getUserSkillBySkillId(
    userSkillStats,
    skill?.id
  );

  return (
    <>
      {practicesToShow.length === 0 &&
        lessonsToShow.length === 0 &&
        activitiesToShow.length === 0 && (
          <>
            <SkeletonText mt={4} noOfLines={8} spacing="6" mb={8} />
          </>
        )}
      {!skill && activitiesToShow.length > 0 && (
        <Box>
          <Box>
            <TrainingsPanel
              isDesktop={isDesktop}
              slideFadeIn={slideFadeIn}
              activities={activitiesToShow}
            />
          </Box>
        </Box>
      )}
      {skill && practicesToShow.length > 0 && (
        <>
          <Flex
            gap={"15px"}
            direction={{ base: "column", md: "row" }}
            mb="40px"
          >
            <Box h="100%">
              <ResilienceSkill20Item
                skill={skill}
                userSkill={skillToDisplayUserSkill}
                forceDonutAnimation={true}
                onClick={() => {
                  track(
                    !skillToDisplayUserSkill
                      ? EVENTS.UnlockSkillStarted
                      : EVENTS.RedoScoreStarted,
                    {
                      HV_SkillId: skill.id,
                      HV_SkillName: skill.slug,
                      HV_From: "Skill Page",
                    }
                  );

                  clearGlobalActivities();
                  setSelectedSkill(skill);
                  setGlobalHeadScanQuestionModalOpen(true);
                }}
                hideDescriptionInFooter={true}
                showDescription={true}
                fullDescription={true}
                donutSize={DonutSize.Large}
                showChart={true}
                additionalContent={
                  <Text alignSelf={"center"} fontSize={"13px"}>
                    <Icon as={FaRedo} mr="5px" />
                    {skillToDisplayUserSkill
                      ? HVLocalizeStrings.SKILL_CONTENT_PAGE_REDO_YOUR_RESILIENCE_SCORE
                      : HVLocalizeStrings.UNLOCK_SKILL}
                  </Text>
                }
              />
            </Box>
            <Box>
              <Shield
                badgeData={badgeData}
                isMainContainerXL={isMainContainerXL}
              />
            </Box>
          </Flex>

          <AllTrainingsPanel
            isDesktop={isDesktop}
            selectedSkill={skill}
            practicesToShow={practicesToShow}
            soloLessons={lessonsToShow}
            pathTeamLessons={pathTeamLessons}
          />
        </>
      )}
    </>
  );
};

interface TrainingsPanelProps {
  isDesktop: boolean | undefined;
  slideFadeIn: boolean;
  activities: NanoPracticeDto[] | MicroLessonDto[];
}

const TrainingsPanel = ({
  isDesktop,
  slideFadeIn,
  activities,
}: TrainingsPanelProps) => {
  const isSm = useBreakpointValue({ base: false, sm: true });
  const width = isSm ? "290px" : `${window.innerWidth - 40}px`;

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);

      setTimeout(() => {
        setShow(true);
      }, 250);
    }, 250);
  }, [slideFadeIn]);

  return (
    <Box h={"230px"}>
      {show && (
        <SlideFade in={slideFadeIn} className="no-ease">
          <ScrollPanel
            spaceBetween={8}
            showChevronContainer={true}
            slidesPerGroup={isDesktop ? 3 : 1}
            slidesPerView={"auto"}
            isPaginationOnContainer={true}
          >
            {activities.map((item: any) => {
              if ("lessonCourse" in item) {
                return ScrollPanelBlock(
                  <LessonBlock20
                    lesson={item}
                    from="Home"
                    showPoints={true}
                    width={width}
                    height={!isDesktop ? "200px" : undefined}
                  />,
                  width,
                  item?.id
                );
              } else {
                return ScrollPanelBlock(
                  <NanoPracticeBlock
                    practice={item}
                    from="Home"
                    showPoints={true}
                    width={width}
                    height={!isDesktop ? "200px" : undefined}
                  />,
                  width,
                  item?.id
                );
              }
            })}
          </ScrollPanel>
        </SlideFade>
      )}
    </Box>
  );
};

interface AllTrainingsPanelProps {
  isDesktop: boolean | undefined;
  selectedSkill: SkillDto;
  practicesToShow: any[];
  soloLessons: MicroLessonDto[];
  pathTeamLessons: PathTeamLessonDto[];
}

const AllTrainingsPanel = ({
  isDesktop,
  selectedSkill,
  practicesToShow,
  soloLessons,
  pathTeamLessons,
}: AllTrainingsPanelProps) => {
  return (
    <>
      <Box data-testid={HVTestId.ResilienceSkill20Panel.nanoBlockContainer}>
        <Text
          as="span"
          fontWeight={600}
          data-testid={HVTestId.ResilienceSkill20Panel.nanoBlockTitle}
        >
          {selectedSkill.name as string}
        </Text>{" "}
        {HVLocalizeStrings.NANO_PRACTICES.toLowerCase()}
        <Box mt={1}>
          <NanoPracticesScrollPanel
            isDesktop={isDesktop}
            practices={practicesToShow}
            showDescription={true}
            from={"Skill Page"}
            showPoints={true}
          />
        </Box>
      </Box>
      {soloLessons && soloLessons.length > 0 && (
        <Box
          mt={6}
          data-testid={HVTestId.ResilienceSkill20Panel.soloBlockContainer}
        >
          <Text
            as="span"
            fontWeight={600}
            data-testid={HVTestId.ResilienceSkill20Panel.soloBlocktitle}
          >
            {selectedSkill.name as string}
          </Text>{" "}
          <Text as="span">
            {HVLocalizeStrings.SOLO_MENU_LEARN.toLowerCase()}
          </Text>
          <Box mt={1}>
            <LessonsScrollPanel
              isDesktop={isDesktop}
              lessonsToShow={soloLessons}
              isWide={true}
              fullDescription={!isDesktop}
              from={"Skill Page"}
              showPoints={true}
            />
          </Box>
        </Box>
      )}

      {pathTeamLessons && pathTeamLessons.length > 0 && (
        <Box
          mt={6}
          data-testid={HVTestId.ResilienceSkill20Panel.teamBlockContainer}
        >
          <Text
            as="span"
            fontWeight={600}
            data-testid={HVTestId.ResilienceSkill20Panel.teamBlocktitle}
          >
            {selectedSkill.name as string}
          </Text>{" "}
          <Text as="span">
            TEAM {HVLocalizeStrings.TRAININGS.toLowerCase()}
          </Text>
          <Box mt={1}>
            <TeamTrainingsScrollPanel
              isDesktop={isDesktop}
              pathTeamLessons={pathTeamLessons}
              skill={selectedSkill}
              from={"Skill Page"}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

interface ShieldProps {
  badgeData?: BadgeData[];
  isMainContainerXL: any;
}
const Shield = (props: ShieldProps) => {
  const { badgeData, isMainContainerXL } = props;

  const isMobile = useBreakpointValue({ base: true, sm: false });

  const { skills, isUninterruptibleModalOpen, userSkillStats } =
    useContext<IGlobalProvider>(GlobalContext);

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (isUninterruptibleModalOpen) return;

    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, [isUninterruptibleModalOpen, skills, userSkillStats]);

  return (
    <>
      {badgeData !== undefined && badgeData?.length > 0 && (
        <>
          <Box
            border={`solid ${badgeData[0].shieldColor}77 1px`}
            borderRadius={Border_Radius}
            px={isMobile ? 5 : 8}
            pt={8}
            h={"270px"}
            w={isMainContainerXL ? 365 : ["100%", "100%", "100%", 353]}
            color={SHIELD_MODAL_PRIMARY_TEXT_COLOR}
            bg={BADGE_MODAL_BACKGROUND}
            bgColor={"white"}
            bgSize={"cover"}
          >
            <Fade in={show}>
              {show && (
                <BadgeCardScrollPanel
                  badgeData={badgeData}
                  isSkillPage={true}
                />
              )}
            </Fade>
          </Box>
        </>
      )}
    </>
  );
};
