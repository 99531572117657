import { Box, Flex, Icon, List, ListItem, Text } from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  PRIMARY_TEXT_COLOR,
} from "../../../../Styles/HeadversityStyle";
import { HVTestId } from "../../../../Testing/dataTestIds";
import {
  AnimatedButton,
  AnimatedButtonColorSet,
} from "../../../Common/AnimatedButton";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import React, { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  AmbassadorDto,
  CharterActivity,
  PollQuestionDto,
} from "@headversity/contract";
import _ from "lodash";
import { CharterTimer } from "../Shared/CharterTimer";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { PresentationContainer } from "../Shared/PresentationContainer";
import { PresentationContent } from "../Shared/PresentationContent";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import { BsPeopleFill } from "react-icons/bs";
import { IconType } from "react-icons";
import { GoDotFill } from "react-icons/go";

interface PresentationAmbassadorProps {
  setActivity: (activity: CharterActivity | undefined) => void;
}
export const PresentationAmbassador = (props: PresentationAmbassadorProps) => {
  const { setActivity } = props;
  const {
    pollQuestions,
    pollInstances,
    setPollInstanceToServer,
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
    currentParticipantList,
    currentTeam,
    saveCharterData,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [pollQuestion, setPollQuestion] = useState<PollQuestionDto | undefined>(
    undefined
  );
  const [instanceCreated, setInstanceCreated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [topThreeAmbassadors, setTopThreeAmbassadors] = useState<
    AmbassadorDto[]
  >([]);

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "AMBASSADOR_1_PQ"
      );
      setPollQuestion(pollQuestion);
    }
  }, [pollQuestions]);

  const { pollQuestionResults, total, count, disableSubmit, setDisableSubmit } =
    usePollQuestionInformation({
      pollQuestion: pollQuestion,
      currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
      byGroup: false,
    });

  useEffect(() => {
    if (
      pollQuestion &&
      currentTeamLessonUserInstanceId &&
      pollInstances &&
      !instanceCreated
    ) {
      setInstanceCreated(true);
      const currentPollInstance = pollInstances.find(
        (pi) =>
          pi.pollQuestionId === pollQuestion.id &&
          pi.teamLessonUserInstanceId === currentTeamLessonUserInstanceId
      );
      if (currentPollInstance) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.SelectingCharterChampions
        );
        return;
      }
      setPollInstanceToServer(
        pollQuestion.id,
        currentTeamLessonUserInstanceId
      ).then(() => {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.SelectingCharterChampions
        );
      });
    }
  }, [
    pollQuestion,
    currentTeamLessonUserInstanceId,
    pollInstances,
    instanceCreated,
  ]);

  useEffect(() => {
    if (currentParticipantList && pollQuestionResults) {
      const groupByResult = _.groupBy(pollQuestionResults, "responseText");
      let result: { id: number; votes: number }[] = [];

      Object.keys(groupByResult).forEach((key) => {
        result.push({ id: parseInt(key), votes: groupByResult[key].length });
      });

      result = _.sortBy(result, "votes");

      const topResults = result.reverse().slice(0, 3);

      setTopThreeAmbassadors(topResults);
    }
  }, [pollQuestionResults, currentParticipantList]);

  return (
    <PresentationContainer>
      {step === 0 ? (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_INDIVIDUAL_ACTIVITY}
          description={HVSportLocalizeStrings.CHARTER_AMBASSADOR_DESCRIPTION}
          listItems={[
            {
              icon: BsPeopleFill as IconType,
              text: HVSportLocalizeStrings.CHARTER_EVERYONE_WILL_PARTICIPATE,
            },
            {
              icon: 3,
              text: HVSportLocalizeStrings.CHARTER_BEST_WORST_ANSWER_INSTRUCTION,
            },
          ]}
          hrBorder={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
        >
          <CharterTimer
            pollQuestion={pollQuestion}
            setDisableSubmit={setDisableSubmit}
          />
        </PresentationContent>
      ) : (
        <PresentationContent
          title={HVSportLocalizeStrings.CHARTER_OUR_AMBASSADOR_TITLE}
          description={
            HVSportLocalizeStrings.CHARTER_AMBASSADOR_DESCRIPTION_RESULTS
          }
          hr={false}
        >
          <Flex flexDir={"row"} align={"start"} justify={"start"} w={"100%"}>
            <Box>
              <List>
                {topThreeAmbassadors.map((item) => {
                  const teamUser = currentTeam?.teamUsers?.find(
                    (tu) => tu.userId === item.id
                  );
                  return (
                    <ListItem key={item.id} fontSize={"16px"} pt={1}>
                      <Flex align={"center"} gap={"2"}>
                        <Icon as={GoDotFill} color={Black} fontSize={"13px"} />
                        <Text fontSize={"16px"}>
                          {teamUser?.user?.fullName ??
                            teamUser?.user?.email ??
                            "unknown"}
                        </Text>
                      </Flex>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Flex>
        </PresentationContent>
      )}
      {step === 0 && (
        <Flex
          flexDir={"column"}
          align={"center"}
          data-testid={HVTestId.PollResult.totalVotes}
        >
          <Text color={PRIMARY_TEXT_COLOR} fontWeight={"semibold"}>
            {HVLocalizeStrings.SPORT_RESPONSES}
          </Text>
          <Text
            color={PRIMARY_TEXT_COLOR}
            fontWeight={"semibold"}
            fontSize={"xl"}
          >
            {count} / {total}
          </Text>
        </Flex>
      )}

      <AnimatedButton
        disabled={disableSubmit || count < 1 || loading}
        w={150}
        colorSet={AnimatedButtonColorSet.Primary}
        text={HVLocalizeStrings.CONTINUE}
        onClick={async () => {
          if (currentTeamLessonUserInstanceId) {
            if (step === 0) {
              setStep(1);
            } else {
              setLoading(true);
              await saveCharterData(currentTeamLessonUserInstanceId, {
                ambassador: topThreeAmbassadors,
                fontColor: Black,
              });
              setTeamLessonInProgressStepToServer(
                currentTeamLessonUserInstanceId,
                ""
              );
              setActivity(undefined);
            }
          }
        }}
        dataTestId={HVTestId.PollQuestion.submitButton}
      />
    </PresentationContainer>
  );
};
