import { useContext, useEffect, useState } from "react";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import {
  CharterActivity,
  PollQuestionSessionInputDto,
  QuestionUserResponseDto,
} from "@headversity/contract";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import {
  Black,
  BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM,
  Border_Radius,
  Error_Red,
  sportAllSetGreen,
  White,
} from "../../../../Styles/HeadversityStyle";
import { HVLocalizeStrings } from "../../../../Localization/HVLocalizeStrings";
import { BestWorstResult } from "../Shared/BestWorstResult";
import { useGroupInformation } from "../Hooks/useGroupInformation";
import { useBestWorstInitQuestion } from "../Hooks/useBestWorstInitQuestion";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { ParticipantWaitingScreen } from "../Shared/ParticipantWaitingScreen";
import {
  ParticipantActivityLabel,
  ParticipantContainer,
  ParticipantGroupLabel,
} from "../../../Shared/Container/ParticipantContainer";
import { PollMultiSelectInput } from "../../../Shared/SelectInput/PollMultiSelectInput";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";
import BlockSectionTitle from "../../../Common/BlockSectionTitle";
import { performHapticImpact } from "../../../../Api/Utils";
import {
  PollResponseList,
  PollResponseListItem,
} from "../../../Team/Polls/PollResponseList";

export const ParticipantBestWorstStepTwo = () => {
  const {
    setPollInstanceResultToServer,
    participantPathTeamLesson,
    currentTeamLessonUserInstanceId,
  } = useContext<IGlobalProvider>(GlobalContext);

  let [currentSelectedAnswers, setCurrentSelectedAnswers] = useState<number[]>(
    []
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [finish, setFinish] = useState<boolean>(false);
  const [bestIsDone, setBestIsDone] = useState<boolean>(false);

  const { isGroupLead } = useGroupInformation();

  const { bestPollQuestion, worstPollQuestion } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_4_PQ",
    worstPollQuestionInternalTitle: "CH_5_PQ",
  });

  const {
    bestPollQuestion: parentBestPollQuestion,
    worstPollQuestion: parentWorstPollQuestion,
  } = useBestWorstInitQuestion({
    bestPollQuestionInternalTitle: "CH_2_PQ",
    worstPollQuestionInternalTitle: "CH_3_PQ",
  });

  const {
    pollQuestionResults: bestQuestionResults,
    currentPollInstanceId: bestPollInstanceId,
    currentUserPollQuestionResults: currentUserBestQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: bestPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    pollQuestionResults: worstQuestionResults,
    currentPollInstanceId: worstPollInstanceId,
    currentUserPollQuestionResults: currentUserWorstQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: worstPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    groupPollQuestionResults: parentCurrentUserBestQuestionResults,
    pollQuestionResults: parentAllUsersBestQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: parentBestPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const {
    groupPollQuestionResults: parentCurrentUserWorstQuestionResults,
    pollQuestionResults: parentAllUsersWorstQuestionResults,
  } = usePollQuestionInformation({
    pollQuestion: parentWorstPollQuestion,
    byGroup: true,
    currentTeamLessonUserInstanceId: currentTeamLessonUserInstanceId,
  });

  const [hasPerformedHapticImpact, setHasPerformedHapticImpact] =
    useState(false);

  useEffect(() => {
    if (currentUserBestQuestionResults.length > 0 && !bestIsDone) {
      setCurrentSelectedAnswers([]);
      setBestIsDone(true);
    }
  }, [currentUserBestQuestionResults]);

  useEffect(() => {
    if (bestIsDone && loading) {
      setLoading(false);
    }
  }, [currentUserBestQuestionResults, bestIsDone]);

  const submitPollResponse = () => {
    const pollSessionQuestionInputDto: PollQuestionSessionInputDto = {
      questionAlternativeIds: [],
      freeTexts: [],
      feedback: "",
      questionUserResponseIds: [],
    };

    pollSessionQuestionInputDto.questionUserResponseIds =
      currentSelectedAnswers;

    let pollInstanceId = -1;
    let allowSetFinish = false;

    if (currentUserBestQuestionResults.length < 1 && bestPollInstanceId) {
      pollInstanceId = bestPollInstanceId;
    } else if (
      currentUserWorstQuestionResults.length < 1 &&
      worstPollInstanceId
    ) {
      pollInstanceId = worstPollInstanceId;
    } else {
      return;
    }

    if (pollInstanceId) {
      setLoading(true);
      setPollInstanceResultToServer(
        pollInstanceId,
        pollSessionQuestionInputDto
      ).then(() => {
        if (allowSetFinish) {
          setFinish(true);
        }
      });
    }
  };

  if (!worstPollInstanceId && !bestPollInstanceId) {
    return <></>;
  }

  let content;
  if (
    participantPathTeamLesson?.inProgressStep ===
    CharterActivity.BestWorstStepTwoResults
  ) {
    content = (
      <BestWorstResult
        bestQuestionResults={bestQuestionResults}
        worstQuestionResults={worstQuestionResults}
        parentBestQuestionResults={parentAllUsersBestQuestionResults}
        parentWorstQuestionResults={parentAllUsersWorstQuestionResults}
      />
    );
  } else if (isGroupLead) {
    if (
      currentUserBestQuestionResults.length < 1 &&
      bestPollInstanceId &&
      bestPollQuestion &&
      parentCurrentUserBestQuestionResults &&
      !finish
    ) {
      if (!hasPerformedHapticImpact) {
        performHapticImpact();
        setHasPerformedHapticImpact(true);
      }
      content = (
        <PollMultiSelectInput
          questionText={HVSportLocalizeStrings.CHARTER_BEST_TWO_QUESTION}
          doneButtonMessage={HVLocalizeStrings.CONTINUE}
          instruction={
            HVSportLocalizeStrings.CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION
          }
          alternatives={parentCurrentUserBestQuestionResults}
          currentSelectedAnswers={currentSelectedAnswers}
          submitPollResponse={submitPollResponse}
          setCurrentSelectedAnswers={setCurrentSelectedAnswers}
          disableIamDone={currentSelectedAnswers.length < 1}
          disableSelection={currentSelectedAnswers.length > 2}
          loading={loading}
        />
      );
    } else if (
      currentUserWorstQuestionResults.length < 1 &&
      worstPollInstanceId &&
      worstPollQuestion &&
      parentCurrentUserWorstQuestionResults &&
      !finish
    ) {
      content = (
        <PollMultiSelectInput
          questionText={HVSportLocalizeStrings.CHARTER_WORST_TWO_QUESTION}
          doneButtonMessage={HVLocalizeStrings.WE_ARE_DONE}
          instruction={
            HVSportLocalizeStrings.CHARTER_BEST_WORST_TWO_GROUP_REPORTER_INSTRUCTION
          }
          alternatives={parentCurrentUserWorstQuestionResults}
          currentSelectedAnswers={currentSelectedAnswers}
          submitPollResponse={submitPollResponse}
          setCurrentSelectedAnswers={setCurrentSelectedAnswers}
          disableIamDone={currentSelectedAnswers.length < 1}
          disableSelection={currentSelectedAnswers.length > 2}
          loading={loading}
        />
      );
    } else {
      content =
        parentCurrentUserBestQuestionResults.length > 0 &&
        parentCurrentUserWorstQuestionResults.length > 0 ? (
          <ParticipantWaitingScreen
            message={HVLocalizeStrings.TEAM_POLL_WAITING_FOR_GROUP_RESULTS}
          />
        ) : (
          <ParticipantWaitingScreen
            message={HVLocalizeStrings.TEAM_POLL_REPORTER_DID_NOT_RESPOND}
            messageBold={""}
          />
        );
    }
  } else {
    content =
      parentCurrentUserBestQuestionResults.length > 0 &&
      parentCurrentUserWorstQuestionResults.length > 0 ? (
        <BestWorstVoteQuestion
          bestQuestionResults={parentCurrentUserBestQuestionResults}
          worstQuestionResults={parentCurrentUserWorstQuestionResults}
        />
      ) : (
        <ParticipantWaitingScreen
          message={HVLocalizeStrings.TEAM_POLL_REPORTER_DID_NOT_RESPOND}
          messageBold={""}
        />
      );
  }

  return (
    <ParticipantContainer
      leftContent={<ParticipantActivityLabel groupActivity={true} />}
      rightContent={<ParticipantGroupLabel />}
    >
      {isGroupLead && currentUserWorstQuestionResults.length < 1 ? (
        <Flex alignSelf={"start"}>
          <BlockSectionTitle
            title={`${HVLocalizeStrings.STEP} ${!bestIsDone ? 1 : 2} / 2`}
            primaryTextColor={Black}
            borderBottom={BLOCK_SECTION_SPORT_TITLE_BORDER_BOTTOM}
          />
        </Flex>
      ) : (
        <></>
      )}
      {content}
    </ParticipantContainer>
  );
};

interface BestWorstVoteQuestionProps {
  bestQuestionResults: QuestionUserResponseDto[];
  worstQuestionResults: QuestionUserResponseDto[];
}

const BestWorstVoteQuestion = (props: BestWorstVoteQuestionProps) => {
  const { bestQuestionResults, worstQuestionResults } = props;

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
  });

  const bestContent = (
    <PollResponseList noBorder p={"2"} w={"100%"}>
      {bestQuestionResults
        ?.sort(
          (a: QuestionUserResponseDto, b: QuestionUserResponseDto) =>
            b.id - a.id
        )
        .map((item: QuestionUserResponseDto) => {
          return (
            <PollResponseListItem key={item.id}>
              <Text color={Black} fontSize={"16px"}>
                {item.responseText}
              </Text>
            </PollResponseListItem>
          );
        })}
    </PollResponseList>
  );

  const worstContent = (
    <PollResponseList noBorder p={"2"} w={"100%"}>
      {worstQuestionResults
        ?.sort(
          (a: QuestionUserResponseDto, b: QuestionUserResponseDto) =>
            b.id - a.id
        )
        .map((item: QuestionUserResponseDto) => {
          return (
            <PollResponseListItem key={item.id}>
              <Text color={Black} fontSize={"16px"}>
                {item.responseText}
              </Text>
            </PollResponseListItem>
          );
        })}
    </PollResponseList>
  );

  return (
    <Flex
      flexDir={"column"}
      color={Black}
      maxW={"400px"}
      p={4}
      gap={12}
      m="auto" // Automatically centers horizontally
      w="100%" // Takes up the full width of the screen
      mt={"10px"}
    >
      <Flex
        gap={3}
        flexDir={"column"}
        backgroundColor={White}
        borderRadius={Border_Radius}
        boxShadow={"lg"}
        p={isMobile ? 6 : 12}
      >
        <Text fontSize={"md"} mb={8}>
          {HVLocalizeStrings.WHAT_ARE_SOME_OF_THE}{" "}
          <span style={{ color: sportAllSetGreen, fontWeight: 600 }}>
            {HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_BEST}
          </span>{" "}
          {HVLocalizeStrings.CHARACTERISTICS_THAT_MAKE_A_TEAM_SUCCESSFUL}
        </Text>
        <Text>{HVLocalizeStrings.YOUR_GROUP_SAID}</Text>
        {bestContent}
      </Flex>
      <Flex
        gap={3}
        flexDir={"column"}
        backgroundColor={White}
        borderRadius={Border_Radius}
        boxShadow={"lg"}
        p={isMobile ? 6 : 12}
      >
        <Text fontSize={"md"} mb={8}>
          {HVLocalizeStrings.WHAT_ARE_SOME_OF_THE}{" "}
          <span style={{ color: Error_Red, fontWeight: 600 }}>
            {HVLocalizeStrings.CHARTER_BEST_VOTE_TITLE_WORST}
          </span>{" "}
          {HVLocalizeStrings.CHARACTERISTICS_THAT_CAN_HARM_A_TEAM}
        </Text>
        <Text>{HVLocalizeStrings.YOUR_GROUP_SAID}</Text>
        {worstContent}
      </Flex>
    </Flex>
  );
};
