import { Box } from "@chakra-ui/react";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useContext, useEffect, useRef, useState } from "react";
import { pdfExport } from "../../../../Utils/PDFDownloadUtil";
import { CharterCustomizer } from "./CharterCustomizer";
import { PdfCharter } from "./PdfCharter";
import { CharterPanel } from "./CharterPanel";
import {
  CharterActivity,
  CharterDataDto,
  PollQuestionDto,
  ResponseState,
} from "@headversity/contract";
import {
  GlobalContext,
  IGlobalProvider,
} from "../../../../State/GlobalContext";
import { HvSpinner } from "../../../Common/HvSpinner";
import { usePollQuestionInformation } from "../Hooks/usePollQuestionInformation";
import { Black } from "../../../../Styles/HeadversityStyle";
import { debounce } from "lodash";
import { HVSportLocalizeStrings } from "../../../../Localization/HVSportLocalizeString";

interface CharterDisplayProps {
  isEditor: boolean;
  teamLessonUserInstanceId: number;
  loading?: boolean;
  setLoading?: (value: boolean) => void;
  willBroadcast?: boolean;
}

export const CharterDisplay = ({
  isEditor,
  teamLessonUserInstanceId,
  loading,
  setLoading,
  willBroadcast,
}: CharterDisplayProps) => {
  const pdfRef = useRef<PDFExport | null>(null);

  const {
    paths,
    saveCharterData,
    pollQuestions,
    currentTeamLessonUserInstanceId,
    setTeamLessonInProgressStepToServer,
  } = useContext<IGlobalProvider>(GlobalContext);

  const [displayData, setDisplayData] = useState<CharterDataDto | undefined>();
  const [isDisplayDataDirty, setIsDisplayDataDirty] = useState<boolean>(false);
  const [pollQuestion, setPollQuestion] = useState<PollQuestionDto | undefined>(
    undefined
  );

  useEffect(() => {
    if (!teamLessonUserInstanceId || !paths) return;

    const keys = Object.keys(paths);
    let charterData = null;
    let currentPathTeamLesson;
    for (const key of keys) {
      for (const path of paths[key]) {
        for (const pathTeamLesson of path.pathTeamLessons) {
          if (
            pathTeamLesson.teamLessonUserInstanceId === teamLessonUserInstanceId
          ) {
            currentPathTeamLesson = pathTeamLesson;
            charterData = pathTeamLesson.charterData;
            break;
          }
        }
      }
    }

    if (charterData) {
      if (
        willBroadcast &&
        currentTeamLessonUserInstanceId &&
        currentPathTeamLesson &&
        currentPathTeamLesson.inProgressStep !== CharterActivity.CharterCreation
      ) {
        setTeamLessonInProgressStepToServer(
          currentTeamLessonUserInstanceId,
          CharterActivity.CharterCreation
        );
      }
      setDisplayData(charterData);
    } else {
      if (setLoading) {
        setLoading(true);
      }

      if (isEditor) {
        saveCharterData(teamLessonUserInstanceId, {
          background: "",
          font: "",
          fontColor: Black,
          logoUrl: "",
          name: HVSportLocalizeStrings.CHARTER_DEFAULT_NAME,
        }).then(() => {
          if (setLoading) {
            setLoading(false);
          }
        });
      }
      setDisplayData({
        background: "",
        font: "",
        fontColor: Black,
        logoUrl: "",
        name: HVSportLocalizeStrings.CHARTER_DEFAULT_NAME,
      });
    }
  }, [teamLessonUserInstanceId, paths]);

  useEffect(() => {
    if (pollQuestions) {
      const pollQuestion = pollQuestions.find(
        (pq) => pq.internalTitle === "CH_6_PQ"
      );
      setPollQuestion(pollQuestion);
    }
  }, [pollQuestions]);

  const { pollQuestionResults } = usePollQuestionInformation({
    pollQuestion: pollQuestion,
    byGroup: false,
    currentTeamLessonUserInstanceId: teamLessonUserInstanceId,
  });

  const onCharterDisplayPropertyChanged = (data: CharterDataDto): void => {
    setIsDisplayDataDirty(true);
    setDisplayData({
      ...data,
    });
  };

  const onDownloadClicked = () => {
    pdfExport(pdfRef, {}, "charter.pdf");
  };

  const debouncedSaveCharterData = useRef(
    debounce(
      (displayData: CharterDataDto) =>
        saveCharterData(teamLessonUserInstanceId, {
          background: displayData?.background,
          font: displayData.font,
          fontColor: displayData.fontColor,
          logoUrl: displayData.logoUrl,
          name: displayData.name ? displayData.name : "",
        }).finally(() => {
          setIsDisplayDataDirty(false);
          if (setLoading) {
            setLoading(false);
          }
        }),
      2000
    )
  ).current;

  useEffect(() => {
    if (
      isDisplayDataDirty &&
      displayData &&
      teamLessonUserInstanceId !== undefined
    ) {
      if (setLoading && !loading) {
        setLoading(true);
      }
      debouncedSaveCharterData(displayData);
    }
  }, [displayData, isDisplayDataDirty, loading]);

  return (
    <>
      {displayData && pollQuestionResults && (
        <PdfCharter
          pdfRef={pdfRef}
          charterData={displayData}
          statements={pollQuestionResults.filter(
            (po) => po.state === ResponseState.active
          )}
        />
      )}

      {!teamLessonUserInstanceId ? (
        <HvSpinner />
      ) : (
        <Box>
          {displayData && pollQuestionResults && (
            <CharterPanel
              statements={pollQuestionResults.filter(
                (po) => po.state === ResponseState.active
              )}
              charterData={displayData}
            />
          )}
          {displayData && isEditor && (
            <CharterCustomizer
              displayData={displayData}
              onPropertyChanged={onCharterDisplayPropertyChanged}
              onDownload={onDownloadClicked}
            />
          )}
        </Box>
      )}
    </>
  );
};
